import React from "react";

import Modal from "../Common/ModalComponent";

import "./ClaimSelection.scss";

class ClaimSelection extends React.Component {
  constructor(props) {
    super(props);
    this.modal = new Modal();
    this.state = {
      ...props,
      errorCode: null,
      loadingClaim: false,
      claimValid: false
    };
    this.claimIdInput = React.createRef();
    this.props.initApp();
    window.addEventListener("keydown", this.onClaimSelection, false);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.onClaimSelection, false);
  }

  componentDidMount() {
    Modal.hide();
    this.setState({
      claimId: this.claimIdInput.current.value
    });
    document.getElementById("gh-claimId").focus();
  }

  onClaimSelection = e => {
    if (e.key === "Enter") {
      this.loadClaim();
    }
  };

  errorOnSelection = () => {
    Modal.hide();
    Modal.display({
      title: "Session expired",
      content: "Session expired. Please login again",
      event: "reload"
    });
  };

  checkClaimId() {
    const filledClaimId = this.claimIdInput.current;
    if (filledClaimId === null) {
      this.setState({
        errorMessage: this.props.Literals.errors.claimRefMustBeInteger,
        claimValid: false
      });
      return null;
    }
    const claimId = parseInt(filledClaimId.value);
    if (filledClaimId === null || isNaN(claimId) || claimId.length === 0 || claimId < 0) {
      this.setState({
        errorMessage: this.props.Literals.errors.claimRefMustBeInteger,
        claimValid: false
      });
      return null;
    } else {
      this.setState({ errorMessage: null, claimValid: true });
      return claimId;
    }
  }

  loadClaim() {
    this.setState({
      loadingClaim: true
    });
    const claimId = this.checkClaimId();
    if (claimId !== null) {
      window.sessionStorage.setItem("claimid", claimId);
      this.props.gotoStep("CLAIM_HANDLING");
    }
    this.setState({
      loadingClaim: false
    });
  }

  clearConfig = () => {
    if (window.history.pushState) {
      var newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
      window.history.pushState({ path: newurl }, "", newurl);
    }
    window.sessionStorage.clear("policyid");
    window.sessionStorage.clear("claimid");
    this.props.toggleToReadMode(true);
    window.Config.controller.abort();
    window.Config.clear();
  };

  render() {
    //console.log(this.props.Literals.text);
    if (!this.props.Literals.text) {
      this.errorOnSelection();
      return null;
    }
    return (
      <section className="ClaimSelection">
        <section className="App-element">
          <label htmlFor="gh-claimId">{this.props.Literals.text.claimReference}</label>
          <input
            ref={this.claimIdInput}
            type="text"
            id="gh-claimId"
            placeholder="100034900"
            onChange={e => this.checkClaimId(e.target)}
          />
          <button
            type="button"
            id="gh-load-claim"
            className={`Button-orange centered ${this.state.claimValid ? "" : "disabled"}`}
            onClick={e => this.loadClaim()}
          >
            {this.state.loadingClaim ? this.props.Literals.text.loadingClaim : this.props.Literals.text.loadClaim}
          </button>
          {this.state.errorMessage ? (
            <div className="errorMessage" id="Error">
              {this.state.errorMessage}
            </div>
          ) : null}
          <span
            className="ClaimHandling-link"
            onClick={e => {
              this.clearConfig();
              this.props.gotoStep("POLICY_SELECTION");
            }}
          >
            {this.props.Literals.aside.loadAPolicy}
          </span>
        </section>
      </section>
    );
  }
}

export default ClaimSelection;
