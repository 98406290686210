import React from 'react';

import Requests from '../../Utils/Requests';

import './DropdownMenu.scss';
import Modal from './ModalComponent';

class DropdownMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      open: false,
      currentChoice: 'empty',
      items: [],
      claimsActionsSet: false,
    };
  }

  componentDidMount() {
    const claimActions = window.Config.claim.claimActions || [];
    this.setState({
      items: claimActions,
    });
  }

  componentDidUpdate() {
    let claimActions = window.Config.claim.claimActions || [];
    const fixedClaimAction = window.Config.claim.currentStatus === 'closed' ? { id: 'reopen' } : { id: 'edit' };
    claimActions = [...claimActions, fixedClaimAction];
    if (claimActions.length + 1 > 0 && this.state.claimsActionsSet === false && window.Config.claim.currentStatus) {
      this.setState({
        claimsActionsSet: true,
        items: [...claimActions],
      });
    }
  }

  makeAction = (selectedAction) => {
    this.props.processAction(selectedAction);
    this.props.toggleToReadMode(false);
    this.setState({
      currentChoice: selectedAction,
    });
  };

  openMenu = (open) => {
    this.setState({
      open,
    });
  };

  reopenClaim = () => {
    Modal.display({
      title: this.props.Literals.ui.reopenClaim.title,
      content: `${this.props.Literals.ui.reopenClaim.message1} ${window.Config.claim.locator}${this.props.Literals.ui.reopenClaim.message2}`,
      cancel: this.props.Literals.ui.Cancel,
      button: this.props.Literals.ui.reopenClaim.reopen,
      callback: async (context) => {
        Modal.hide();
        Modal.display({
          title: 'Information',
          content: this.props.Literals.ui.reopenClaim.reopeninClaimLoading,
          noButton: true,
        });
        try {
          const url = `claims/${window.Config.claim.locator}`;
          const claimRequest = await Requests.fetch(url, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            signal: window.Config.signal,
            body: JSON.stringify({
              addSubClaims: [],
              fieldValues: {},
              addFieldGroups: [],
              removeFieldGroups: [],
              updateFieldGroups: [],
              updateSubClaims: [],
              status: 'open',
            }),
          });
          if (!claimRequest.ok || claimRequest.status >= 400) {
            const title = window.savedState.Literals.errors.claimNotFound;
            new window.Config.Error({
              status: claimRequest.status,
              title,
              message: window.savedState.Literals.errors.claim_not_found,
              event: 'reload',
            });
            throw title;
          }
          Modal.hide();
          Modal.display({
            title: 'Information',
            content: this.props.Literals.ui.reopenClaim.reopenSuccess,
            event: 'reload',
            button: this.props.Literals.ui.modal.ok,
          });
        } catch (e) {}
      },
    });
  };

  render() {
    return window.Config.claim.productName ? (
      <React.Fragment>
        {this.state.items.map((item, key) => {
          if (item.id === 'reopen' && window.Config.role !== 'Admin') return null;
          return (
            <button
              id={`gh-${item.id}`}
              className="DropdownMenu-orange"
              key={key}
              onClick={(e) => (item.id !== 'reopen' ? this.makeAction(item.id) : this.reopenClaim())}
            >
              {this.props.Literals[`action_${item.id}`]}
            </button>
          );
        })}
      </React.Fragment>
    ) : null;
  }
}

export default DropdownMenu;
