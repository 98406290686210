import React from 'react';

import Modal from '../Common/ModalComponent';

const maxFileSize = 31457280;

class AddFileComponent extends React.Component {
  constructor(props) {
    super(props);
    this.modal = new Modal();
    this.state = {
      ...props,
      files: [],
      canAddFile: false,
    };
  }

  componentDidMount() {
    const canAddFile = this.componentCanAddFile();
    this.setState({
      canAddFile,
    });
  }

  async addNewFileToCue(name) {
    const context = this.state[this.state.context];
    if (!window.Config.filesToUpload[name]) window.Config.filesToUpload[name] = [];
    const index = window.Config.filesToUpload[name].length;
    const domName = `gh-download-${name}`;
    const file = document.getElementById(domName);
    if (file.files.length > 0) {
      const getFile = async (item) => await FormatFile(item, name, index);
      const getFiles = async (files) => await Promise.all(files.map((file) => getFile(file)));
      const newFiles = await getFiles([...file.files]);
      if (newFiles.includes(false)) return false;
      const existingFiles = window.Config.filesToUpload[name];
      const files = [...existingFiles, ...newFiles];
      window.Config.filesToUpload[name] = files;
      this.props.udpateFiles(files);
      const labelSelector = `label[for='${domName}']`;
      const sibling = document.querySelector(labelSelector);
      const groupParent = sibling && sibling.parentElement;
      if (groupParent) {
        groupParent.classList.add('edit');
      }
      let filesToUpload = window.Config.filesToUpload[name].filter((f) => !f.toDelete);
      filesToUpload = filesToUpload.map((f) => f.filename);
      window.Config.fieldToUpdate({
        id: name,
        value: filesToUpload,
        initialized: true,
        type: context.parentId ? 'group' : 'media',
        subType: context.parentId ? 'media' : null,
        parentId: context.parentId ? context.parentId : null,
        claimActions: window.Config.claim.claimActions,
        originalName: context.originalName,
      });
      this.props.refresh();
    }
    file.value = null;
    return true;
  }

  componentDidUpdate() {
    const updatedProps = this.props.componentDidUpdate(this.props, this.state);
    if (updatedProps) {
      this.setState({
        ...updatedProps,
        canAddFile: this.componentCanAddFile(),
      });
    }
  }

  componentCanAddFile = () => {
    const context = this.state[this.state.context];
    const hasWriteAccess =
      context['x-rights'].findIndex((r) => r.role === window.Config.role && r.right === 'write') !== -1;
    const isFile = context['x-type'] === 'file';
    const hideField = this.props.isFieldShouldBeHidden(this.state);
    let canAddFile = false;
    if (hasWriteAccess && isFile && !hideField) {
      const isRepeatable = context['repeatable'] === true;
      if (isRepeatable) {
        canAddFile = true;
      } else {
        const hasRegisteredFile = !!window.Config.filesToUpload[context['x-id']];
        const nbFileRegistered = hasRegisteredFile && window.Config.filesToUpload[context['x-id']].length;
        canAddFile = !(nbFileRegistered > 0);
      }
    }
    return canAddFile;
  };

  render() {
    const context = this.state[this.state.context];
    return !this.state.canAddFile || this.state.reviewMode ? null : (
      <React.Fragment>
        <input
          type="file"
          id={`gh-download-${context['x-id']}`}
          className="Media-add-input not-visible"
          onChange={() => this.addNewFileToCue(context['x-id'])}
        />
        <label htmlFor={`gh-download-${context['x-id']}`} className={`Button Button-hollow Media-add-label`}>
          {this.props.Literals.text.addNewFile}
        </label>
      </React.Fragment>
    );
  }
}

async function FormatFile(file, domName, index) {
  const filename = file.name;
  const splitFilename = filename.split('.');
  const extention = `.${splitFilename.pop()}`;
  const currentFilename = splitFilename.join('.').replace(/[^\w]{1}/g, '_');
  const normalizedFilename = currentFilename.normalize('NFKD');
  const formatedFilename = `${normalizedFilename}_${index}${extention}`;
  try {
    if (Number(file.size) > maxFileSize) {
      Modal.display({
        title: 'File Error',
        content: 'The file size must be smaller than 30MB.',
      });
      return false;
    }
    const resource = await readFileAsync(file);
    const response = {
      filename: formatedFilename,
      name: formatedFilename,
      resource,
      creating: true,
      size: updateSize(file),
      extention,
      domName,
    };
    return response;
  } catch (e) {
    return e;
  }
}

function readFileAsync(file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;

    reader.readAsArrayBuffer(file);
  });
}

function updateSize(file) {
  let size = file.size + ' bytes';
  let output = size;
  // optional code for multiples approximation
  for (
    let aMultiples = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'], nMultiple = 0, nApprox = file.size / 1024;
    nApprox > 1;
    nApprox /= 1024, nMultiple++
  ) {
    output = `${nApprox.toFixed(2)}${aMultiples[nMultiple]}`;
  }
  // end of optional code
  return {
    fileNum: size,
    fileSize: output,
  };
}

export default AddFileComponent;
