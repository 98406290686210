/* eslint-disable no-unused-expressions */
import React from 'react';

class SelectComponent extends React.Component {
  constructor(props) {
    super();
    this.state = {
      ...props,
    };
  }

  onChange = (e) => {
    const { value } = e.target;
    this.props.updateAndCheckValue(value, e.target);
    this.props.toggleChangeHasBeenMade();
  };

  render() {
    const context = this.props[this.props.context];
    return (
      <React.Fragment>
        <label htmlFor={`gh-${context['x-id']}`}>{this.props.title}</label>
        <section className={`Select ${this.props.errorClassName} ${this.props.disabled}`}>
          <select
            name={context['x-id']}
            id={`gh-${context['x-id']}`}
            subtype={context.subtype ? context.subtype : null}
            className={context.className}
            value={this.props.value}
            required={context.required}
            data-form={this.props['data-form']}
            onChange={(e) => this.onChange(e, context)}
            disabled={this.props.disabled}
          >
            <option value="undefined" key="P-1">
              {this.props.Literals.selectDefaultOption.label}
            </option>
            {context['x-choices'].map((option) => {
              const translatedOption = this.props.Literals[
                `${context['x-id']}-${option['x-value'].replace(/\s/g, '')}`
              ];
              const tradValue = translatedOption ? translatedOption : option['x-value'];
              return (
                <option value={option['x-value']} key={option['x-value']}>
                  {tradValue}
                </option>
              );
            })}
          </select>
        </section>
      </React.Fragment>
    );
  }
}

export default SelectComponent;
