/* eslint-disable no-unused-expressions */
import React from 'react';
import moment from 'moment-timezone';
import DateTime from 'react-datetime';

import 'moment/locale/fr';
import 'moment/locale/es';

import 'react-datetime/css/react-datetime.css';
import './DateComponent.scss';

moment.tz.setDefault(moment.tz.guess());

class DateComponent extends React.Component {
  constructor() {
    super();
    this.ref = React.createRef();
  }

  onChange = (value) => {
    this.props.updateAndCheckValue(value, this.ref.current);
    this.props.toggleChangeHasBeenMade();
  };

  render() {
    const context = this.props[this.props.context];
    return (
      <React.Fragment>
        <label htmlFor={`gh-${context['x-id']}`}>{this.props.title}</label>
        <DateTime
          input={true}
          value={typeof this.props.value === 'undefined' ? null : new Date(this.props.value)}
          locale={[navigator.language, 'en']}
          dateFormat={'LL'}
          timeFormat={context.precision === 'second'}
          inputProps={{
            disabled: this.props.disabled,
            id: `gh-${this.props.context}`,
            ref: this.ref,
            className: 'Date',
          }}
          name={context['x-id']}
          onChange={this.onChange}
        />
      </React.Fragment>
    );
  }
}

export default DateComponent;
