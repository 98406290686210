import App from "./App";
import Steps from "../Components/Steps/index.js";
import moment from "moment-timezone";
import Common from "./Common";

import initialState from "../Utils/App";

moment.tz.setDefault(moment.tz.guess());

/**
 * gatherDataFromSessionStorage
 * @param none
 * @return either the initialState (if no sessionStorate.state is initialized) of the sessionStorage.state value
 */
const getCurrentStateFromSessionStorage = () => {
  let savedState = window.savedState;
  if (!savedState) {
    savedState =
      window.sessionStorage.getItem("state") !== null && window.sessionStorage.getItem("state") !== "undefined"
        ? JSON.parse(window.sessionStorage.getItem("state"))
        : null;
  }
  return savedState && savedState.data ? savedState : initialState;
};

const Navigation = {
  getPage: () => {
    const now = Math.round(Date.now()/1000);
    const socotraTokenTTL = window.localStorage.getItem("socotraTokenTTL");
    const state = getCurrentStateFromSessionStorage();

    // How to get the page value ?
    // 1 : check if there is a manually filled query string parameter p or...
    // 2 : check if there is an already filled parameter in sessionStorage currentStep or...
    // 3 : use the default one in (/Utils/Form/App.js).currentStep
    let step = state.currentStep;

    if (socotraTokenTTL === null || socotraTokenTTL <= 0 || socotraTokenTTL - now <= 0) {
      console.warn("redirect to Login");
      step = "LOGIN";
    }

    // if the query string parameter is fill BUT it's not linked with a real App.step, return a 404 error
    if (typeof Steps[step] === "undefined") {
      step = "ERROR404";
    }

    const { claimid } = Common.getURLParameters();
    if (claimid && step === "CLAIM_HANDLING" && window.sessionStorage.getItem("claimid") !== null) {
      window.sessionStorage.clear("claimid");
      window.history.pushState("", "", "/");
    }
    if (claimid && step !== "LOGIN" && window.sessionStorage.getItem("claimid") === null) {
      step = "CLAIM_HANDLING";
      window.sessionStorage.setItem("claimid", claimid);
    }
    return step;
  },

  gotoStep: ({ state, action }) => {
    const { currentStep } = action;
    const updatedState = {
      ...state,
      currentStep,
      ...action
    };
    if (action.way === "previous") {
      updatedState.navigationStuck = false;
    }
    return updatedState;
  }
};

export default Navigation;

const isThereSomeErrors = errors => Object.keys(errors).some(index => errors[index].length > 0);

function validateForm(state) {
  let errors = {};
  if (state.currentStep === "FILES") {
    errors = {
      [state.currentStep]: {
        ...checkFiles(state.data, App.filesDescriptors),
        ...errors[state.currentStep]
      }
    };
  } else {
    const formFields = [...document.querySelectorAll(`[data-form="Form-${state.currentStep}"]`)];
    formFields.forEach(domElement => {
      const name = `${domElement.tagName}_${domElement.type.replace("-", "")}`;
      const error = FieldController[name]("errors", domElement);
      if (error)
        errors = {
          ...errors,
          [state.currentStep]: { ...error, ...errors[state.currentStep] }
        };
    });
  }
  let existingErrors = (errors[state.currentStep] && isThereSomeErrors(errors[state.currentStep])) || false;
  if (existingErrors === true) {
    const firstLeben = Object.keys(errors[state.currentStep]).filter(key => {
      return errors[state.currentStep][key].length > 0;
    });
    const element = document.querySelector(`label[for="${firstLeben[firstLeben.length - 1]}"]`);
    if (element) {
      element.scrollIntoView(true);
    }
  }
  // If everything goes well
  return { existingErrors, errors };
}

function checkForErrors(element) {
  let status = [];
  if (element.getAttribute("size")) {
    const limit = parseInt(element.getAttribute("size"));
    if (element.value.length > limit) {
      status.push({ type: "size", value: element.value.length, limit });
    }
  }
  if (element.required && element.value.length === 0) {
    status.push({ type: "mandatory" });
  }
  if (element.getAttribute("pattern") && element.value.length > 0) {
    const regExp = new RegExp(element.getAttribute("pattern"));
    if (element.value.match(regExp) === null) {
      status.push({ type: "format" });
    }
  }
  //console.log('Check For Errors >', element.name, status);

  return {
    [element.name]: status
  };
}

const FieldController = {
  INPUT(status, element) {
    return status === "errors"
      ? checkForErrors(element)
      : {
          [element.name]: checkBooleanValue(element.value, element.type)
        };
  },
  INPUT_file(status, element) {
    const name = element.getAttribute("data-file");
    if (
      status === "errors" &&
      element.files.length === 0 &&
      App.filesDescriptors[name].required &&
      App.filesDescriptors[name].required === true
    ) {
      return {
        [name]: [{ type: "mandatory" }]
      };
    }
    if (status === "data" && element.files.length > 0) {
      const domFiles = [...document.querySelectorAll(`[data-file="${name}"]`)];
      const data = [];
      for (let i = 0; i < domFiles.length; i++) {
        data.push({
          documentId: domFiles[i].files[0].name,
          documentIndex: i
        });
      }
      return {
        [name]: data
      };
    }
  },
  INPUT_tel(status, element) {
    const { name, value, required, pattern } = element;
    //console.log('Téléphone', name, value, required, pattern);
    const regexp = new RegExp(pattern);
    let response = {
      [name]: []
    };
    if (pattern && !value.match(regexp) && status === "errors") {
      response[name].push({ type: "format" });
    }
    if (value.length === 0 && required && status === "errors") {
      response[name].push({ type: "mandatory" });
    }
    if (status === "data") {
      response[name] = checkBooleanValue(element.value, "tel");
    }
    return response;
  },
  INPUT_email(status, element) {
    const { name, value, required, pattern } = element;
    const regexp = new RegExp(pattern);
    let response = { [name]: [] };
    //console.log(name, pattern, value, value.match(regexp));
    if (pattern && !value.match(regexp) && status === "errors") {
      response = { [name]: [{ type: "format" }] };
    }
    if (value.length === 0 && required && status === "errors") {
      response = {
        [name]: [{ type: "mandatory" }]
      };
    }
    if (status === "data") {
      response = {
        [name]: checkBooleanValue(element.value, "email")
      };
    }
    //console.log('INPUT email result ', response);
    return response;
  },
  INPUT_text(status, element) {
    return FieldController.INPUT(status, element);
  },
  INPUT_time(status, element) {
    return FieldController.INPUT(status, element);
  },
  INPUT_date(status, element) {
    return FieldController.INPUT(status, element);
  },
  INPUT_radio(status, element) {
    const radioElement = document.querySelector(`[name="${element.name}"]:checked`);

    let response = [];
    if (radioElement === null && element.required) response = [{ type: "mandatory" }];
    if (status === "errors") {
      return { [element.name]: response };
    }
    return {
      [element.name]: radioElement ? checkBooleanValue(radioElement.value, "radio") : false
    };
  },
  INPUT_hidden(status, element) {
    return FieldController.INPUT(status, element);
  },
  TEXTAREA_textarea(status, element) {
    //console.log('Textarea > ', element);
    return FieldController.INPUT(status, element);
  },
  SELECT_selectone(status, element) {
    const value = element.options[element.selectedIndex].value;
    //console.log(element.name, '>', value);
    if (status === "errors") {
      if (value === "undefined" && element.required) {
        return {
          [element.name]: [{ type: "mandatory" }]
        };
      }
      return null;
    }
    return {
      [element.name]: checkBooleanValue(value, "selectone")
    };
  }
};

/**
 * gather all the form items from a specific Form, and return the list of errors messages to be display below each form items
 * Each fields inside this form should have a data-form attribute with the same formName
 * @param {String} formName Form Name
 * @return {Array} Return an array of errors in the form : { domField: <domElement>, message: <String> }
 */
function checkData(formName) {
  const formItems = document.querySelectorAll(`[data-form="${formName}"]`);
  let errors = {};
  for (let field of formItems) {
    errors = {
      ...errors,

      [field.name]: [...checkField(field)]
    };
  }
  return errors;
}

function checkField(field) {
  const fieldType = `${field.tagName}_${field.type.replace("-", "")}`;
  //console.log('Checking ', field.name, fieldType);
  return FieldController[fieldType]("errors", field);
}

function checkFiles(data, formFileDescriptors) {
  let errors = {};
  for (let name in formFileDescriptors) {
    const value = formFileDescriptors[name];
    const formItems = window.Config.filesToUpload[name] || [];
    if (value.required(data) && formItems.length === 0) {
      errors = {
        ...errors,
        [name]: [{ type: "mandatory" }]
      };
    }
  }
  return errors;
}

function gatherData(props) {
  const formName = `Form-${props.currentStep}`;
  const formItems = document.querySelectorAll(`[data-form="${formName}"]`);
  let data = {};
  for (let field of formItems) {
    const fieldType = `${field.tagName}_${field.type.replace("-", "")}`;
    const result = FieldController[fieldType]("data", field, props);
    data = {
      ...data,
      ...result
    };
  }
  return data;
}

function checkBooleanValue(value, type) {
  if (type === "radio" && (value === "false" || value === "true")) {
    if (value === "true") value = true;
    if (value === "false") value = false;
  }
  return value;
}

export { checkData, gatherData, FieldController, validateForm, checkForErrors };
