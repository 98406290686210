import EXIF from 'exif-js';

function getExif(img) {
  const result = new Promise(function(resolve, reject) {
    EXIF.getData(img, function() {
      //let data = EXIF.getAllTags(this);
      const ImageDescription = EXIF.getTag(this, 'ImageDescription');
      const GPSLatitude = EXIF.getTag(this, 'GPSLatitude'),
        GPSLatitudeRef = EXIF.getTag(this, 'GPSLatitudeRef'),
        GPSLongitude = EXIF.getTag(this, 'GPSLongitude'),
        GPSLongitudeRef = EXIF.getTag(this, 'GPSLongitudeRef');
      let DateTimeOriginal = EXIF.getTag(this, 'DateTimeOriginal');
      const Latitude = GPSLatitude
        ? `${GPSLatitude[0].numerator}°${GPSLatitude[1].numerator}'${GPSLatitude[2].numerator}"${GPSLatitudeRef}`
        : null;
      const Longitude = GPSLongitude
        ? `${GPSLongitude[0].numerator}°${GPSLongitude[1].numerator}'${GPSLongitude[2].numerator}"${GPSLongitudeRef}`
        : null;
      const ModifyDate = EXIF.getTag('ModifyDate');
      let _lat = GPSLatitude
        ? getDecimalCoordinates(
            GPSLatitude[0].numerator / GPSLatitude[0].denominator,
            GPSLatitude[1].numerator / GPSLatitude[1].denominator,
            (GPSLatitude[2].numerator / GPSLatitude[2].denominator).toFixed(2)
          )
        : null;
      _lat = GPSLatitudeRef === 'S' ? _lat * -1 : _lat;
      let _lng = GPSLongitude
        ? getDecimalCoordinates(
            GPSLongitude[0].numerator / GPSLongitude[0].denominator,
            GPSLongitude[1].numerator / GPSLongitude[1].denominator,
            (GPSLongitude[2].numerator / GPSLongitude[2].denominator).toFixed(2)
          )
        : null;
      _lng = GPSLongitudeRef === 'W' ? _lng * -1 : _lng;
      const tags = {};
      if (DateTimeOriginal) {
        tags.DateTimeOriginal = DateTimeOriginal;
      }
      if (ModifyDate) {
        tags.ModifyDate = ModifyDate;
      }
      if (Latitude) {
        tags.Latitude = Latitude;
        tags.Longitude = Longitude;
        tags.latitude = _lat;
        tags.longitude = _lng;
      }
      if (ImageDescription) {
        tags.ImageDescription = ImageDescription;
      }

      resolve(tags);
    });
  });
  return result;
}

function getDecimalCoordinates(degrees, minutes, seconds) {
  return degrees + minutes / 60 + seconds / 3600;
}

function createThumbnail(id, newWidth) {
  const canvas = document.getElementById(id);
  //console.log('Create Thumbnail', id, canvas);
  if (!canvas || canvas.tagName === 'IMG') {
    return false;
  }
  const context = canvas.getContext('2d');

  canvas.width = newWidth;
  canvas.height = (this.height * newWidth) / this.width;
  context.clearRect(0, 0, canvas.width, canvas.height);
  context.drawImage(this, 0, 0, this.width, this.height, 0, 0, canvas.width, canvas.height);

  return canvas;
}

export { getExif, getDecimalCoordinates, createThumbnail };
