import React from 'react';
import Requests from '../../Utils/Requests';

import Edit from './Edit.js';
import i18n from './i18n.js';

import './Admin.scss';

const Views = {
  Edit,
  i18n,
};

class Admin extends React.Component {
  constructor() {
    super();
    this.state = {
      view: undefined,
      title: undefined,
      productName: '',
      products: [],
      currentLocale: 'en',
      viewsLabel: {
        Edit: 'Edit the configuration',
        i18n: 'Edit the labels',
      },
    };
    this.selectLocale = this.selectLocale.bind(this);

    this.productSelect = React.createRef();
  }
  
  async componentDidMount() {
    let ttl = window.localStorage.getItem('socotraTokenTTL');
    let currentTime = Math.round(Date().now()/1000);
    let timeleft = ttl - currentTime;
    if (isNaN(timeleft) || timeleft <= 0) {
      return Requests.renewSession();
    }
    const view = window.sessionStorage.getItem('view') || 'Edit';
    const product = window.sessionStorage.getItem('productName');
    const currentLocale = window.sessionStorage.getItem('currentLocale');
    if (!!view) {
      this.selectView(view);
    }
    if (!!product) {
      this.selectProduct(product);
    }
    if (!!currentLocale) {
      this.selectLocale(currentLocale);
    }
    const prodRequest = await Requests.fetch(`products`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!prodRequest.ok || prodRequest.status >= 400) {
      new window.Config.Error({
        status: prodRequest.status,
      });
      const error = `Error ${prodRequest.status}`;
      throw error;
    }
    const products = await prodRequest.json();
    this.setState({ products });
  }

  selectProduct = (productName) => {
    this.setState({
      productName,
    });
    this.productSelect.current.value = productName;
    window.sessionStorage.setItem('productName', productName);
  };

  selectView = (view) => {
    if (['Edit', 'i18n'].includes(view)) {
      this.setState({
        view,
        title: view,
      });
    }
    window.sessionStorage.setItem('view', view);
  };

  selectLocale = (locale) => {
    this.setState({
      currentLocale: locale,
    });
    window.sessionStorage.setItem('currentLocale', locale);
  };

  render() {
    const View = Views[this.state.view];
    return (
      <section className="Admin">
        <nav className="Admin-menu">
          <label htmlFor="selectProduct">Product</label>
          <section className="Select">
            <select
              id="gh-selectProduct"
              className="link"
              onChange={(e) => this.selectProduct(e.target.value)}
              ref={this.productSelect}
              value={this.state.productName}
            >
              <option value={null}>Please choose a product</option>
              {this.state.products.map((p, k) => {
                return (
                  <option value={p} key={k}>
                    {p}
                  </option>
                );
              })}
            </select>
          </section>
          <hr />
          <ul>
            {this.state.productName !== null ? (
              <React.Fragment>
                <li>
                  <button
                    id="gh-selectView-edit"
                    className={`link ${this.state.view === 'Edit' ? 'active' : ''}`}
                    onClick={() => this.selectView('Edit')}
                  >
                    Configuration
                  </button>
                </li>
                <li>
                  <button
                    id="gh-selectView-i18n"
                    className={`link ${this.state.view === 'i18n' ? 'active' : ''}`}
                    onClick={() => this.selectView('i18n')}
                  >
                    Internationalization
                  </button>
                </li>
              </React.Fragment>
            ) : null}
          </ul>
        </nav>
        {this.state.productName && this.state.view ? (
          <fieldset>
            <legend>
              <h2>{this.state.viewsLabel[this.state.title]}</h2>
            </legend>
            <View {...this.state} selectLocale={this.selectLocale} />
          </fieldset>
        ) : null}
      </section>
    );
  }
}

export default Admin;
