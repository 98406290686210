/* eslint-disable no-useless-computed-key */
import React from 'react';
import Moment from 'moment';
import { GroupComponent } from '../Common';

import './ClaimHandling.scss';
import Common from '../../Utils/Common';
import Modal from '../Common/ModalComponent';

import DropdownMenu from '../Common/DropdownMenu';

import {
  isItAnUpdatedField,
  isFieldHasAProperValue,
  getValueFromField,
  getClaimActions,
} from '../../Utils/FieldsToUpdate';
import { loadClaimTranslation } from '../../i18n';

Moment.tz.setDefault('Europe/Bublin');

class ClaimHandling extends React.Component {
  constructor(props) {
    super();
    this.state = this.setuptState(props);
    window.Config.editView = false;
    window.Config.edit = false;
    window.Config.filesToUpload = [];
    this.save = this.save.bind(this);
  }

  setuptState = (props) => {
    return {
      ...props,
      nbItemToHide: 0,
      asideView: 'claim',
      forceView: false,
      sectionIndex: -1,
      errors: [],
      errorMessage: '',
      fields: [],
      reduced: true,
      save: false,
      policyFields: {},
      metaFields: {},
      allFieldsSet: false,
      currentStatusInformation: {},
      editable: false,
    };
  };

  componentWillUnmount() {}

  componentDidUpdate() {
    const updatedProps = this.props.componentDidUpdate(this.props, this.state);
    if (updatedProps) {
      this.setState({
        ...updatedProps,
      });
    }
    if (this.props.changeHasBeenMade !== this.state.changeHasBeenMade) {
      this.setState({
        changeHasBeenMade: this.props.changeHasBeenMade,
      });
    }
    if (this.props.fields.length !== this.state.fields.length) {
      this.setState({ ...this.props });
    }
    if (
      window.Config.metaFields &&
      Object.keys(window.Config.metaFields).length > 0 &&
      Object.keys(this.state.metaFields).length === 0
    ) {
      this.setState({ metaFields: window.Config.metaFields });
    }
    if (
      window.Config.policyFields &&
      Object.keys(window.Config.policyFields).length > 0 &&
      Object.keys(this.state.policyFields).length === 0
    ) {
      this.setState({ policyFields: window.Config.policyFields });
    }
    if (!this.state.allFieldsSet && Object.keys(window.Config.fieldsToUpdate).length > 0) {
      Modal.hide();
      this.setState({ allFieldsSet: true, policyFields: window.Config.policyFields });
    }
    this.checkForEdit();
    //window.setTimeout(this.checkForEmptyFields, 500);
    this.checkForEmptyFields();
  }

  async componentDidMount() {
    this.props.toggleToReadMode(true);
    Modal.hide();
    Modal.display({
      title: this.props.Literals.ui.Information,
      content: this.props.Literals.ui.LoadingClaimPleaseWait,
      noButton: true,
    });
    const claim = await this.props.loadClaim();
    const statusCode = parseInt(claim.status);
    if (statusCode >= 400) {
      Modal.hide();
      this.setState({
        loadingClaim: false,
        claimValid: true,
      });
      if (statusCode === 404) {
        const options = {
          status: 404,
          title: this.props.Literals.errors.claimNotFound,
          message: this.props.Literals.errors.unknownClaimRef,
          callback: () => {
            this.props.gotoStep('CLAIM_SELECTION');
          },
        };
        new window.Config.Error(options);
        return false;
      }
    }

    const language = navigator.language.split('-')[0];
    const globalLiterals = await loadClaimTranslation(language);
    this.props.loadLiterals(globalLiterals, language);
    //console.log(globalLiterals);

    if (window.Config.metaFields && Object.keys(window.Config.metaFields).length > 0) {
      this.setState({ metaFields: window.Config.metaFields });
    }
    if (window.Config.policyFields && Object.keys(window.Config.policyFields).length > 0) {
      this.setState({ policyFields: window.Config.policyFields });
    }
    this.reducePanel();
  }

  checkForEmptyFields = () => {
    const headings = [...document.querySelectorAll('h1[groupsection]')];
    headings.forEach((h) => {
      if (this.state.reviewAction === 'edit') {
        h.classList.remove('hidden');
      }
      const sectionIndex = h.getAttribute('groupsection');
      const children = [...document.querySelectorAll(`section[groupsection="${sectionIndex}"] > *`)];
      if (children.length === 0) {
        h.classList.add('hidden');
      } else {
        h.classList.remove('hidden');
      }
    });

    if (!this.state.allFieldsSet && Object.keys(window.Config.fieldsToUpdate).length > 0) {
      Modal.hide();
      this.setState({ allFieldsSet: true, policyFields: window.Config.policyFields });
      this.props.refresh();
    }
  };

  getDomElements = (element) => {
    return ['input', 'select', 'textarea', 'table', 'canvas'].flatMap((tag) => [...element.querySelectorAll(tag)]);
  };

  checkForEdit = () => {
    let editable = false;
    if (this.state.reviewAction !== 'edit') {
      const actionIndex = window.Config.claim.claimActions.findIndex((i) => i.id === this.state.reviewAction);
      if (actionIndex === -1) {
        editable = true;
      } else {
        const mandatoryFields = window.Config.claim.claimActions[actionIndex].mandatoryFields;
        let actionDefaultValue, edit, notEditedButHasDefaultValueOnMandatory, hasProperValue;
        editable = Object.values(mandatoryFields).every((field) => {
          const isAGroup =
            !window.Config.fieldsToUpdate[field.fieldName] && window.Config.claim.fieldValues[field.fieldName];
          if (isAGroup) {
            const groupIndex = window.Config.fields.findIndex((f) => f.name === field.fieldName);
            if (groupIndex !== -1) {
              edit = window.Config.fields[groupIndex].fields.some((s) => {
                const sFieldName = `${window.Config.fields[groupIndex].locator}-${s.name}`;
                const { mandatory } = getClaimActions(sFieldName);
                return mandatory.includes(this.state.reviewAction) || isItAnUpdatedField(sFieldName);
              });
            } else {
              edit = false;
            }
          } else {
            actionDefaultValue = getValueFromField(field.fieldName).actionDefaultValue;
            edit = isItAnUpdatedField(field.fieldName);
            notEditedButHasDefaultValueOnMandatory =
              !edit && actionDefaultValue && actionDefaultValue.action === this.state.reviewAction;
            hasProperValue = isFieldHasAProperValue(field.fieldName);
            // WORKS ONLY FOR ROOT VALUES
          }
          return edit || notEditedButHasDefaultValueOnMandatory || hasProperValue;
        });
      }
    } else {
      editable = Object.entries(window.Config.editableFields).some(([fieldName, editable]) => editable === true);
    }

    const fieldInError = Object.entries(window.Config.fieldsToUpdate).some(([key, field]) => !!field.inError);
    if (editable && fieldInError) {
      editable = false;
    }

    if (this.state.editable !== editable) {
      this.setState({ editable });
    }
  };

  toggleFields = (flag = 'hidden') => {
    if (!this.state.editable || this.state.save === true || this.state.warningErrors) {
      return false;
    }
    this.props.toggleToReviewMode(flag === 'review');
  };

  save = async () => {
    if ((this.state.reviewMode && !this.state.editable) || this.state.save === true) {
      return false;
    }

    Modal.display({
      title: this.props.Literals.ui.Information,
      content: this.props.Literals.ui.SavingClaimPleaseWait,
      noButton: true,
    });

    this.setState({ save: true, reduced: true });
    document.getElementById('gh-confirm').innerHTML = '';
    [...document.querySelectorAll('*.edit')].forEach((domItem) => {
      domItem.classList.remove('edit');
    });

    try {
      const response = await Common.processAXAClaim(
        this.state.claim.locator,
        this.state.claim.currentStatus,
        this.state
      );

      if (response.hasError === false) {
        const title = this.props.Literals.ui.SaveSuccess;
        const content = `${this.props.Literals.ui.Claim} ${window.Config.claim.locator} ${this.props.Literals.ui.hasBeenSaved}`;
        Modal.hide();
        Modal.display({
          title,
          content,
          button: this.props.Literals.ui.modal.close,
          event: 'reload',
        });
      } else {
        if (!window.localStorage.getItem('socotraAccessToken')) {
          Modal.hide();
          Modal.display({
            title: 'Session Error',
            content: `Your session has expired, please log in again.`,
            event: 'reload',
          });
          return false;
        }
        const errors = response.errors.map(
          (error) =>
            `<li>
            Field on error:<br />
            ${error.title} (${error.fieldName})<br />
            Error code: ${error.code}<br />
            Reason:<br />
            ${error.message}
            </li>`
        );
        Modal.hide();
        Modal.display({
          title: this.props.Literals.errors.ErrorOnSave,
          content: `
          <p>${this.props.Literals.errors.ErrorOnSave} ${window.Config.claim.locator}</p>
          <ul class="errorMessage">${errors.join('')}</ul>
          `,
          noButton: false,
          button: this.props.Literals.ui.modal.close,
          event: 'reload',
        });
      }
    } catch (e) {
      Modal.hide();
      Modal.display({
        title: this.props.Literals.errors.FatalErrorOnSave,
        content: `
          <p>${this.props.Literals.errors.ErrorOnSave} ${window.Config.claim.locator}</p>
          <ul class="errorMessage">${e.toString()}</ul>
          `,
        noButton: false,
        button: this.props.Literals.ui.modal.close,
        event: 'reload',
      });
      console.trace(e);
    }
  };

  reducePanel = () => {
    this.setState({ reduced: !this.state.reduced });
  };

  asideView = (view) => {
    this.setState({ asideView: view });
  };

  render() {
    return this.state.isClaimLoaded === true ? (
      <section className={`ClaimHandling`}>
        <button
          onClick={(e) => this.reducePanel(e)}
          title={`${this.state.reduced ? 'Display' : 'Hide'} panel`}
          className={`Button Button-hollow ClaimHandling-reducePanelButton ${this.state.save ? 'hidden' : ''}`}
        >
          <i className={`icon icon-arrow-${this.state.reduced ? 'left' : 'right'}`}></i>
        </button>
        <section
          className={`ClaimHandling-column ClaimHandling-column-left ${this.state.save ? 'blurry' : ''} ${
            this.state.reduced ? 'reduced' : ''
          }`}
        >
          {this.state.fields &&
            this.state.fields.map((field, key) => {
              const headings = this.state.Literals[`${field['x-id']}-heading`] || field['x-heading'];
              const elementShouldBeHidden =
                field['x-rights'] &&
                field['x-rights'].findIndex((i) => i.role === window.Config.role && i.right === 'hidden') !== -1;
              if (elementShouldBeHidden) {
                return null;
              }
              field.locator =
                this.state.claim.fieldValues[field['x-id']] && this.state.claim.fieldValues[field['x-id']][0];
              return (
                <React.Fragment key={key}>
                  {headings ? (
                    <h1 className="main-section-title App-element" groupsection={`section_${field['x-sectionIndex']}`}>
                      {headings}
                    </h1>
                  ) : null}
                  <section groupsection={`section_${field['x-sectionIndex']}`}>
                    <GroupComponent {...this.state} field={field} />
                  </section>
                </React.Fragment>
              );
            })}
        </section>

        <section className={`ClaimHandling-column ClaimHandling-column-right ${this.state.reduced ? 'reduced' : ''}`}>
          <fieldset className="ClaimHandling-aside">
            <legend className="tabs">
              <button
                onClick={() => this.asideView('claim')}
                className={`tab${this.state.asideView === 'claim' ? ' active' : ''}`}
              >
                {this.props.Literals.aside.claim}
              </button>
              <button
                onClick={() => this.asideView('policy')}
                className={`tab${this.state.asideView === 'policy' ? ' active' : ''}`}
              >
                {this.props.Literals.aside.policy}
              </button>
            </legend>
            {this.state.asideView === 'claim' ? (
              <ViewClaim
                fields={this.state.metaFields}
                viewname="claim"
                gotoStep={this.props.gotoStep}
                reviewMode={this.state.reviewMode}
                Literals={this.state.Literals}
              />
            ) : (
              <ViewPolicy
                fields={this.state.policyFields}
                claimId={this.state.metaFields.claimId}
                viewname="policy"
                Literals={this.state.Literals}
                gotoStep={this.props.gotoStep}
                allFieldsSet={this.state.allFieldsSet}
              />
            )}
          </fieldset>
          <fieldset className="ClaimHandling-aside">
            <legend>
              <h4 className="ClaimHandling-aside-legend">{this.state.Literals.ui.claimActions}</h4>
            </legend>
            <section className="Navigation">
              {window.Config.role !== 'Reader' ? (
                !this.state.readMode && window.Config.claim.productName ? (
                  <React.Fragment>
                    {!this.state.readMode ? (
                      <strong className="errorMessage small">{this.props.Literals.aside.mandatoryFields}</strong>
                    ) : null}
                    {!this.state.reviewMode ? (
                      <section className={`Navigation-CheckValues`}>
                        <button
                          type="button"
                          id="gh-back"
                          onClick={(e) => this.props.toggleToReadMode(true, true, this.state)}
                          className={`Navigation-ButtonLeft DropdownMenu-hollow DropdownMenu-review ${
                            this.state.save ? 'disabled' : ''
                          }`}
                        >
                          {this.props.Literals.ui.Cancel}
                        </button>
                        <button
                          id="gh-checkValues"
                          type="button"
                          onClick={(e) => this.toggleFields('review')}
                          className={`Button Button-orange DropdownMenu-review ${
                            this.state.editable === false || this.state.warningErrors === true ? 'disabled' : ''
                          }`}
                        >
                          {this.props.Literals.ui.ReviewChanges}
                        </button>
                      </section>
                    ) : (
                      <section className={`Navigation-Confirmation`}>
                        <h3 className="Navigation-Confirmation-message">
                          {this.state.save
                            ? this.state.Literals.ui.SavingClaimPleaseWait
                            : this.state.Literals.ui.reviewChanges}
                        </h3>
                        <button
                          type="button"
                          id="gh-cancel"
                          onClick={(e) => this.toggleFields('hidden')}
                          className={`Navigation-ButtonLeft DropdownMenu-hollow DropdownMenu-review ${
                            this.state.save ? 'disabled' : ''
                          }`}
                        >
                          {this.props.Literals.ui.Cancel}
                        </button>
                        <button
                          type="button"
                          id="gh-confirm"
                          onClick={this.save}
                          className={`Navigation-ButtonRight Button-orange DropdownMenu-review ${
                            this.state.save ? 'disabled' : ''
                          }`}
                        >
                          {this.state.save ? this.props.Literals.ui.Loading : this.props.Literals.ui.Confirm}
                        </button>
                      </section>
                    )}
                  </React.Fragment>
                ) : (
                  <DropdownMenu {...this.state} />
                )
              ) : null}
              {this.state.readMode ? (
                <section className={`Navigation-CheckValues`}>
                  <button
                    className="DropdownMenu-hollow"
                    onClick={() => {
                      this.props.toggleToReadMode(true);
                      window.Config.controller.abort();
                      window.Config.clear();
                      this.props.gotoStep('CLAIM_SELECTION');
                    }}
                    id="gh-go-back-to-claim-selection"
                  >
                    {this.props.Literals.aside.loadAnotherClaim}
                  </button>
                </section>
              ) : null}
            </section>
          </fieldset>
        </section>
      </section>
    ) : (
      <section className={`ClaimHandling`}>
        <section className="ClaimHandling-column ClaimHandling-column-left"></section>
      </section>
    );
  }
}

class ViewPolicy extends React.Component {
  constructor(props) {
    super();
    this.state = {
      ...props,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.fields !== state.fields) {
      return props;
    }
    return null;
  }

  copyPaste = (id) => {
    const t = document.createElement('textarea');
    document.querySelector('body').appendChild(t);
    t.value = id;
    t.select();
    document.execCommand('copy');
    Modal.display({
      title: this.props.Literals.ui.copied,
      content: this.props.Literals.ui.textCopied,
      button: this.props.Literals.ui.modal.close,
    });
    document.querySelector('body').removeChild(t);
  };

  loadClaim = (claimId) => {
    window.sessionStorage.setItem('claimid', claimId);
    window.location.reload();
  };

  render() {
    const lang = [navigator.language, 'en'];
    const {
      claims,
      firstName,
      lastName,
      partnerCustomerAccountId,
      email,
      phoneNumber,
      postMailFormatted,
      policyId,
      effectiveDateTime,
      cancellationDateTime,
    } = this.state.fields;

    return (
      <section className="App-List">
        {Object.keys(this.state.fields).length > 0 ? (
          <React.Fragment>
            <table cellSpacing="0" cellPadding="0" className="ClaimHandling-commonFieldsTable">
              <tbody>
                <tr>
                  <td id="gh-aside-policyId">{this.props.Literals.aside.policyId}</td>
                  <td>{policyId}</td>
                </tr>
                <tr>
                  <td id="gh-aside-effectiveDateTime">{this.props.Literals.aside.effectiveDateTime}</td>
                  <td>
                    {Moment(new Date(effectiveDateTime))
                      .locale(lang)
                      .format('LLL')
                      .toString()}
                  </td>
                </tr>
                {cancellationDateTime ? (
                  <tr>
                    <td id="gh-aside-cancellationDateTime">{this.props.Literals.aside.cancellationDateTime}</td>
                    <td>
                      {Moment(new Date(cancellationDateTime))
                        .locale(lang)
                        .format('LLL')
                        .toString()}
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </table>
            <h4>{this.state.Literals.aside.policyHolderInformation}</h4>
            <table cellSpacing="0" cellPadding="0" className="ClaimHandling-commonFieldsTable">
              <tbody>
                <tr>
                  <td id="gh-aside-firstName">{this.props.Literals.aside.firstName}</td>
                  <td>{firstName}</td>
                </tr>
                <tr>
                  <td id="gh-aside-lastName">{this.props.Literals.aside.lastName}</td>
                  <td>{lastName}</td>
                </tr>
                <tr>
                  <td id="gh-aside-email">{this.props.Literals.aside.email}</td>
                  <td>{email}</td>
                </tr>
                <tr>
                  <td id="gh-aside-postMailFormatted">{this.props.Literals.aside.postMailFormatted}</td>
                  <td>{postMailFormatted}</td>
                </tr>
                <tr>
                  <td id="gh-aside-phoneNumber">{this.props.Literals.aside.phoneNumber}</td>
                  <td>{phoneNumber}</td>
                </tr>
                {partnerCustomerAccountId ? (
                  <tr>
                    <td id="gh-aside-partnerCustomerAccountId">{this.props.Literals.aside.partnerCustomerAccountId}</td>
                    <td>
                      {partnerCustomerAccountId && partnerCustomerAccountId.substr(0, 20)}...
                      <button
                        id="gh-aside-copy"
                        onClick={() => this.copyPaste(partnerCustomerAccountId)}
                        className="DropdownMenu-copy"
                        title={this.state.Literals.aside.copyToClipboard}
                      >
                        <i className="DropdownMenu-icon icon icon-press-release"></i>
                        {this.props.Literals.aside.copy}
                      </button>
                    </td>
                  </tr>
                ) : (
                  <tr id="gh-aside-noPartnerId">
                    <td colSpan="2">No information available for the partner customer id</td>
                  </tr>
                )}
              </tbody>
            </table>
            {claims && claims.length > 0 ? (
              <React.Fragment>
                <h4>{this.state.Literals.aside.claimsHistory}</h4>
                <table cellSpacing="0" cellPadding="0" className="ClaimHandling-commonFieldsTable">
                  <tbody>
                    {claims && claims.length > 1 ? (
                      claims.map((claim) => {
                        if (claim.locator === this.props.claimId) return null;
                        return (
                          <tr>
                            <td id={`gh-aside-claim-${claim.locator}`}>
                              <span onClick={(e) => this.loadClaim(claim.locator)} className="ClaimHandling-link">
                                {claim.locator}
                              </span>
                            </td>
                            <td>{claim.fieldValues.subStatus}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td id="gh-aside-no-claim-from-policy">{this.state.Literals.aside.noOtherClaims}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </React.Fragment>
            ) : null}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <span>{this.state.Literals.aside.loadingPolicy}</span>
            <hr />
          </React.Fragment>
        )}
      </section>
    );
  }
}

class ViewClaim extends React.Component {
  constructor(props) {
    super();
    this.state = {
      ...props,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.fields !== state.fields) {
      return props;
    }
    return null;
  }

  render() {
    const {
      claimId,
      currentStatus,
      incidentTimestamp,
      notificationTimestamp,
      policyId,
      productName,
      updatedTimestamp,
    } = this.state.fields;
    const lang = [navigator.language, 'en'];
    if (this.props.Literals.ui.claims === undefined) {
      return false;
    }
    return this.state.reviewMode ? null : (
      <React.Fragment>
        <section className="App-List">
          <table cellSpacing="0" cellPadding="0" className="ClaimHandling-commonFieldsTable">
            <tbody>
              {this.state.fields ? (
                <React.Fragment>
                  <tr>
                    <td id="gh-aside-claimId">{this.props.Literals.ui.claims.claimId}</td>
                    <td>{claimId}</td>
                  </tr>
                  <tr>
                    <td id="gh-aside-policyId">{this.props.Literals.ui.claims.policyId}</td>
                    <td id="gh-aside-policy-button">
                      <span
                        className="ClaimHandling-link"
                        onClick={(e) => {
                          window.sessionStorage.setItem('policyid', policyId);
                          this.props.gotoStep('POLICY_SELECTION');
                        }}
                      >
                        {policyId}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td id="gh-aside-productName">{this.props.Literals.ui.claims.productName}</td>
                    <td>{productName}</td>
                  </tr>
                  <tr>
                    <td id="gh-aside-currentStatus">{this.props.Literals.ui.claims.currentStatus}</td>
                    <td>{currentStatus}</td>
                  </tr>
                  <tr>
                    <td id="gh-aside-incidentTimestamp">{this.props.Literals.ui.claims.incidentTimestamp}</td>
                    <td>
                      {Moment(new Date(incidentTimestamp))
                        .locale(lang)
                        .format('LLL')
                        .toString()}
                    </td>
                  </tr>
                  <tr>
                    <td id="gh-aside-notificationTimestamp">{this.props.Literals.ui.claims.notificationTimestamp}</td>
                    <td>
                      {Moment(new Date(notificationTimestamp))
                        .locale(lang)
                        .format('LLL')
                        .toString()}
                    </td>
                  </tr>
                  <tr>
                    <td id="gh-aside-updatedTimestamp">{this.props.Literals.ui.claims.updatedTimestamp}</td>
                    <td>
                      {Moment(new Date(updatedTimestamp))
                        .locale(lang)
                        .format('LLL')
                        .toString()}
                    </td>
                  </tr>
                </React.Fragment>
              ) : (
                <span>{this.props.Literals.ui.loading}</span>
              )}
            </tbody>
          </table>
        </section>
      </React.Fragment>
    );
  }
}

export default ClaimHandling;
