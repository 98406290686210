import moment from "moment-timezone";
import React from "react";
import { batch, connect } from "react-redux";
import Steps from "../Steps";
import Common from "../../Utils/Common";
import Requests from "../../Utils/Requests";
import Navigation, { checkForErrors } from "../../Utils/Navigation";
import XConfig from "../../XConfig";

import "./App.scss";
import {
  getValueFromField,
  isItAnUpdatedField,
  getClaimActionForField,
  getClaimActions,
  getPropertyFromFieldsToUpdate
} from "../../Utils/FieldsToUpdate";

moment.tz.setDefault(moment.tz.guess());

/**
 * App class
 * used to managed all the form stuff
 */
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      renewingSession: false,
      timer: null,
      interval: null
    };
    this.props.initApp();
    window.addEventListener("blur", () => {
      window.sessionStorage.setItem("state", JSON.stringify(window.savedState));
    });
    window.addEventListener("beforeunload", e => {
      e.preventDefault();
      window.sessionStorage.setItem("state", JSON.stringify(window.savedState));
    });
  }

  /**
   *
   */
  render() {
    const TagName = Steps[this.props.currentStep].component;
    return (
      <React.Fragment>
        <TagName {...this.props} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state,
    Literals: state.Literals,
    currentLang: state.Literals.currentLang,
    currentPageTitle: getCurrentPageTitle(state.currentStep)
  };
};

function getCurrentPageTitle(index) {
  return `step${index}`;
}

const mapDispatchToProps = dispatch => ({
  initApp: event => {
    let currentStep = Navigation.getPage();
    dispatch({
      type: "initApp",
      currentStep
    });
  },
  reloadApp: () => {
    dispatch({ type: "reloadApp" });
  },
  // If true, readMode = true
  toggleToReadMode: (flag, reload = false, state = {}) => {
    dispatch({
      type: "toggleToReadMode",
      flag
    });

    if (flag === true) {
      batch(() => {
        dispatch({
          type: "reviewAction",
          id: "edit",
          currentAction: undefined
        });
        dispatch({
          type: "toggleToReviewMode",
          flag: false
        });
        dispatch({
          type: "changeHasBeenMade",
          flag: new Date().getTime()
        });
      });
    }
    const fieldsToCheck = window.Config.fieldsToUpdate || {};
    const errors = Object.values(fieldsToCheck).find(el => el.inError !== false);
    if ((reload === true && state.changeHasBeenMade) || (reload === true && errors)) {
      window.location.reload();
    }
  },
  toggleToReviewMode: flag => {
    dispatch({
      type: "toggleToReviewMode",
      flag
    });
  },
  toggleChangeHasBeenMade: () => {
    dispatch({
      type: "changeHasBeenMade",
      flag: new Date().getTime()
    });
  },
  toggleWarning: flag => {
    dispatch({
      type: "warningErrors",
      flag
    });
  },
  getMyParentName: parentId => {
    let parentFieldIndex = window.Config.fields.findIndex(f => f.locator === parentId);
    if (parentFieldIndex === -1) {
      parentFieldIndex = window.Config.fields.findIndex(f => f.name === parentId); // new group item
      if (parentFieldIndex === -1) {
        const error = `There is no parent field with name or locator ${parentId}`;
        throw error;
      }
    }
    const parent = window.Config.fields[parentFieldIndex];
    return parent.name;
  },
  getDefaultValueFromState: state => {
    const context = state[state.context];
    let defaultValueFromAction = false,
      edit = false;
    let { informative, mandatory, optional, defaultValue } = getClaimActionForField(state.context);
    let { value } = getValueFromField(state.context);

    let parentId = getPropertyFromFieldsToUpdate(context["x-id"], "parentId");
    if (parentId) {
      const rights = getClaimActions(context["x-id"]);
      const values = getValueFromField(context["x-id"]);
      informative = rights.informative;
      mandatory = rights.mandatory;
      optional = rights.optional;
      defaultValue = rights.defaultValue;
      value = values.value;
    }

    if (defaultValue) {
      const defaultValueIndex = defaultValue.findIndex(
        f => f.fieldName === state.context && state.reviewAction === f.action
      );
      if (defaultValueIndex !== -1 && state.reviewAction !== "edit") {
        defaultValueFromAction = true;
        if (mandatory.includes(state.reviewAction)) {
          value = defaultValue[defaultValueIndex].defaultValue;
          window.Config.fieldToUpdate({
            id: state.context,
            value
          });
        }
        edit = true;
      }

      if (
        defaultValueIndex === -1 &&
        !!value &&
        state.reviewAction !== "edit" &&
        mandatory.includes(state.reviewAction)
      ) {
        edit = true;
      }
    }

    // Use to protect issue with react
    if (value instanceof Array && value.length <= 1) {
      value = value[0];
    }

    if (isItAnUpdatedField(context["x-id"])) {
      edit = true;
    }

    if (!mandatory) {
      mandatory = [];
    }
    if (!optional) {
      optional = [];
    }
    if (!informative) {
      informative = [];
    }

    return { value, informative, mandatory, optional, defaultValueFromAction, edit };
  },
  processAction: action => {
    const currentActionIndex =
      (window.Config.claim.claimActions &&
        window.Config.claim.claimActions.findIndex(claimAction => claimAction.id === action)) ||
      0;
    const currentAction = window.Config.claim.claimActions[currentActionIndex];
    dispatch({
      type: "reviewAction",
      id: action,
      currentAction
    });
  },
  componentDidUpdate(thisProps, thisState) {
    if (thisProps.reviewAction !== thisState.reviewAction) {
      let props = {
        ...thisProps
      };
      props.reviewAction = thisProps.reviewAction;
      return props;
    }

    if (
      JSON.stringify(thisProps.Literals) !== JSON.stringify(thisState.Literals) ||
      thisProps.refresh !== thisState.refresh ||
      thisProps.readMode !== thisState.readMode ||
      thisProps.reviewMode !== thisState.reviewMode ||
      thisProps.warningErrors !== thisState.warningErrors
    ) {
      return thisProps;
    }
    return false;
  },
  timerDidUpdate(thisProps, thisState) {
    if (thisProps.interval !== thisState.interval) {
      return thisProps;
    }
    return false;
  },
  isFieldIsConditionalyVisible(fieldName) {
    isFieldIsConditionalyVisible(fieldName);
  },
  isFieldShouldBeHidden: state => {
    const context = state[state.context];
    if (context["x-type"] === "geolocation") {
      return !state.readMode;
    }
    const field = window.Config.fieldsToUpdate[context["x-id"]];
    const { readMode, reviewMode } = state;
    if (context.fields && reviewMode) {
      // group when edit
      const visibleChildren = context.fields.some(f => {
        const sFieldName = context.locator ? `${context.locator}-${f.name}` : f.name;
        const { mandatory } = getClaimActions(sFieldName);
        return mandatory.includes(state.reviewAction) || isItAnUpdatedField(sFieldName);
      });
      return !visibleChildren;
    }
    if (field) {
      const visible = isFieldIsConditionalyVisible(context["x-id"]);
      const isHidden = context["x-rights"].findIndex(r => r.role === window.Config.role && r.right === "hidden") !== -1;
      const fieldHasBeenUpdated = isItAnUpdatedField(context["x-id"]);
      let { value, informative, mandatory, optional } = state.getDefaultValueFromState(state);

      if (isHidden || !visible || (readMode === true && (typeof value === "undefined" || value.length === 0))) {
        return true;
      }

      if (readMode === false && reviewMode === false) {
        if (state.reviewAction === "edit") {
          return false;
        }
        if (informative.includes(state.reviewAction)) {
          return false;
        }
        if (optional.includes(state.reviewAction)) {
          return false;
        }
        if (mandatory.includes(state.reviewAction)) {
          return false;
        }
        return true;
      }
      if (reviewMode === true && fieldHasBeenUpdated === false) {
        if (mandatory.includes(state.reviewAction)) {
          return false;
        }
        return true;
      }
    } else {
      const context = state[state.context];
      if (context.fields) {
        const allowedRight = getRightsForTheCurrentRole(context["x-rights"], state.context);
        if (allowedRight === "hidden") {
          return true;
        }
      }
      if ((readMode || reviewMode) && context.parentId) {
        return true;
      }
    }
    return false;
  },
  getRightsForTheCurrentRole: context => {
    return getRightsForTheCurrentRole(context["x-rights"], context.name);
  },
  isFieldShouldBeHiddenFromAction: state => {
    if (!window.Config.claim.claimActions) {
      console.error("No claim Action found");
      return false;
    }
    if (state.reviewAction === "edit") {
      return false;
    }
    const context = state[state.context];
    let { mandatory, informative, optional } = getClaimActions(context["x-id"]);
    return [mandatory, informative, optional].every(v => !v.includes(state.reviewAction));
  },
  udpateFiles: files => {
    dispatch({
      type: "updateFiles",
      files
    });
  },
  refresh: () => {
    dispatch({
      type: "refresh"
    });
  },
  gotoStep: (currentStep, way) => {
    dispatch({ type: "gotoStep", currentStep, way });
  },
  nextStep: event => {
    dispatch({ type: "nextStep", event });
  },
  elementUpdated: (domElement, context) => {
    const appelement = domElement.closest(".App-element");
    if (appelement) {
      appelement.classList.add("edit");
    }
    const groupElement = domElement.closest(".Group");
    if (groupElement) {
      groupElement.classList.add("edit");
    }
    // SHOULD CHECK IF THERE IS AN ERROR
  },
  tagWarning: (domElement, context, state = false) => {
    if (domElement) {
      state ? domElement.classList.add("errorWarning") : domElement.classList.remove("errorWarning");
    }

    const warnings = [...document.querySelectorAll(".errorWarning")].length > 0;
    dispatch({
      type: "warningErrors",
      flag: warnings
    });
  },
  handleUserAcceptance: domElement => {
    dispatch({
      type: domElement.matches(":checked") ? "unStuckNavigation" : "stuckNavigation"
    });
  },
  handleStuckNavigation: () => {
    dispatch({
      type: "stuckNavigation"
    });
  },
  handleUnStuckNavigation: () => {
    dispatch({
      type: "unStuckNavigation"
    });
  },
  // Add already loaded file as base64 string
  addExistingFileToCue: async (imageData, name, fileName, mediaItem) => {
    if (!window.Config.filesToUpload[name]) window.Config.filesToUpload[name] = [];
    const index = window.Config.filesToUpload[name].length;
    dispatch({ type: "loadFile", status: true });
    let dataUrl = undefined;
    if (imageData.thumbnail) {
      dataUrl = `data:${imageData.mimeType};base64,${imageData.thumbnail}`;
    }
    const getFile = async file => await FormatFile(file, name, index, mediaItem, imageData);
    const file = dataURLtoFile(dataUrl, fileName, imageData.mimeType);
    const loadedFile = await getFile(file);
    if (imageData.exifData) {
      loadedFile.exif = imageData.exifData;
    }

    window.Config.filesToUpload[name].push(loadedFile);
    dispatch({ type: "loadFile", status: false });
    dispatch({
      type: "displayThumbnail",
      name,
      filesToUpload: {
        [name]: window.Config.filesToUpload[name]
      }
    });
    return true;
  },
  removeFileFromCue: (fieldName, fileIndex) => {
    dispatch({
      type: "removeFile",
      fieldName,
      fileIndex
    });
  },
  cancelDeleteFile: (event, fieldName, filename, domIndex) => {
    dispatch({
      type: "cancelDeleteFile",
      fieldName,
      filename,
      domIndex
    });
  },
  handleUpload: name => {
    dispatch({
      type: "gotoStep",
      currentStep: "UPLOAD",
      name
    });
  },
  sendError: errorName => {
    dispatch({ type: "sendError", errorName });
  },
  removeError: () => {
    dispatch({ type: "removeError" });
  },
  checkForErrors: event => {
    dispatch({
      type: "sendErrors",
      errors: checkForErrors(event.target)
    });
  },
  sendErrors: (errors, state) => {
    dispatch({
      type: "sendErrors",
      errors: {
        ...state.errors,
        ...errors
      }
    });
  },
  refreshCache: () => {
    window.Config.clear();
    window.savedState = null;
    window.sessionStorage.clear();
    window.localStorage.clear();
    window.location.reload();
  },
  loadLiterals: (literals, currentLang) => {
    dispatch({
      type: "loadLiterals",
      literals,
      currentLang
    });
  },
  loadClaim: async () => {
    const claimid = window.sessionStorage.getItem("claimid");
    let fields = [],
      ttl,
      timeleft,
      currentTime;
    if (claimid === null) {
      window.Config.clear();
      window.sessionStorage.clear();
      window.localStorage.clear();
      window.location.reload();
    }
    const configRequest = {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    };
    const claimRequest = await Requests.fetch(`claims/${claimid}`, configRequest);
    if (!claimRequest.ok || claimRequest.status >= 400) {
      const title = window.savedState.Literals.errors.claimNotFound;
      new window.Config.Error({
        status: claimRequest.status,
        title,
        message: window.savedState.Literals.errors.claim_not_found,
        callback: () => {
          dispatch({
            type: "Error",
            loadingClaim: false,
            claimValid: false,
            currentStep: "CLAIM_SELECTION"
          });
        }
      });
      throw title;
    }
    const claim = await claimRequest.json();
    window.Config.claim = claim;
    ttl = parseInt(window.localStorage.getItem("socotraTokenTTL"));
    currentTime = Math.round(Date.now()/1000);
    timeleft = ttl - currentTime;
    const xConfig = new XConfig();
    fields = await xConfig.init(claim.productName);
    //console.log('Fields', fields);
    fields = Common.setConditionalFields(claim, fields);
    const processedSocotraClaim = Common.processSocotraClaim(claim, fields);
    if (typeof processedSocotraClaim.message !== "undefined") {
      dispatch({
        type: "Error",
        errorMessage: processedSocotraClaim.message,
        loadingClaim: false,
        claimValid: false,
        currentStep: "CLAIM_SELECTION"
      });
    } else {
      dispatch({
        type: "ClaimLoaded",
        claim,
        processedSocotraClaim,
        fields,
        ttl: moment(ttl).format("DD/MM/YYYY HH:mm"),
        timeleft: Math.round(timeleft / 60)
      });
    }
    return claim;
  },
  claimLoaded: claim => {
    dispatch({ type: "ClaimLoaded", claim });
  },
  notCovered: claim => {
    dispatch({ type: "notCovered", claim });
  },
  networkError: error => {
    dispatch({ type: "networkError", error });
  },
  checkContentSize: event => {
    const elementContent = event.target.value;
    const size = parseInt(event.target.getAttribute("size"));
    if (elementContent.length > size) {
      dispatch({
        type: "sendErrors",
        errors: {
          [event.target.getAttribute("name")]: {
            type: "tooMuchCaraters",
            value: elementContent.length
          }
        }
      });
      dispatch({
        type: "stuckNavigation"
      });
    }
  },
  forceFieldUpdate: fieldName => {
    dispatch({ type: "forceFieldToUpdate", fieldName });
  },
  isComponentDisabled: state => {
    if (state.reviewMode) return true;
    const context = state[state.context];
    let { informative, defaultValueFromAction } = state.getDefaultValueFromState(state);

    let parentId = getPropertyFromFieldsToUpdate(context["x-id"], "parentId");
    if (parentId) {
      const rights = getClaimActions(context["x-id"]);
      informative = rights.informative;
    }

    if (state.reviewAction !== "edit") {
      if (defaultValueFromAction) return true;
      if (informative.includes(state.reviewAction)) {
        return true;
      }
      return false;
    } else {
      return state.reviewMode ? "disabled" : context["x-disabled"] || "";
    }
  },
  triggerCondition(id, toDispatch = false) {
    const conditionalFields = window.Config.triggerFields[id];
    if (typeof conditionalFields === "undefined") {
      return false;
    }
    const len = conditionalFields.length;
    let index = 0;
    for (; index < len; index++) {
      const fieldName = conditionalFields[index];
      const fIndex = window.Config.conditionalFields.findIndex(f => f.name === fieldName);
      const field = window.Config.conditionalFields[fIndex];
      const visible = Object.entries(field.condition).every(([conditionalFieldName, allowedValues]) => {
        let { value } = getValueFromField(conditionalFieldName);
        value = value instanceof Array ? value[0] : value;
        return allowedValues.includes(value);
      });
      const { value } = getValueFromField(field.name);
      if (value !== []) {
        window.Config.fieldToUpdate({ id: field.name, visible, value: visible === false ? [] : value });
      }
    }
  }
});

function readFileAsync(file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;

    reader.readAsArrayBuffer(file);
  });
}

function dataURLtoFile(dataurl = null, filename, mimeType) {
  if (dataurl === null) {
    return new File([], filename, { type: mimeType });
  }
  let arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

async function FormatFile(file, domName, index, mediaItem, imageData) {
  const splitFileName = file.name.split(".");
  const extention = `.${splitFileName.pop()}`;
  //const filename = `${encodeURIComponent(splitFileName[0])}_${index}${extention}`;
  try {
    const resource = await readFileAsync(file);
    const response = {
      filename: `${encodeURIComponent(splitFileName[0])}_${index}${extention}`,
      name: encodeURIComponent(splitFileName[0]),
      resource,
      creating: false,
      extention,
      domName,
      mediaItem,
      ...imageData
    };
    return response;
  } catch (e) {
    return e;
  }
}

const getRightsForTheCurrentRole = (rights, contextName = null) => {
  if (!rights) {
    const error = `No rights given for ${contextName}`;
    new window.Config.Error({
      message: error
    });
    throw error;
  }
  const currentRole = window.Config.role;
  const roleIndex = rights.findIndex(i => i.role === currentRole);
  if (roleIndex === -1) {
    const error = `No rule found for the current user`;
    new window.Config.Error({
      message: error
    });
    throw error;
  }
  return rights[roleIndex].right;
};

const isFieldIsConditionalyVisible = fieldName => {
  const globalConditions = window.Config.conditionalFields;
  const fieldIndex = globalConditions.findIndex(f => f.name === fieldName);
  if (fieldIndex !== -1) {
    const conditions = globalConditions[fieldIndex].condition;
    const visible = Object.entries(conditions).every(([conditionalFieldName, allowedValues]) => {
      let { value } = getValueFromField(conditionalFieldName);
      value = value instanceof Array ? value[0] : value;
      const isValueIsAllowed = allowedValues.includes(value);
      return isValueIsAllowed;
    });

    const { value } = getValueFromField(fieldName);
    const options = { id: fieldName, visible, value: visible === false ? [] : value };
    window.Config.fieldToUpdate(options);
    return visible;
  }
  return true;
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
