import React from 'react';

import './ModalComponent.scss';

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
    };
  }

  static event = 'hide';
  static displayButton = true;
  static noButton = false;
  static cancel = false;
  static callback = null;

  static display(options) {
    const modal = document.getElementById('gh-Modal');
    if (!modal) {
      return false;
    }

    document.querySelector('.ClaimHandling') && document.querySelector('.ClaimHandling').classList.add('blurry');
    document.getElementById('gh-Modal-button').classList.remove('hidden');
    document.getElementById('gh-Modal-cancel').classList.add('hidden');

    if (options.cancel) {
      Modal.cancel = options.cancel;
    }

    if (options.event) {
      document.getElementById('gh-Modal-button').addEventListener('click', Modal[options.event], false);
    }

    Modal.callback = Modal.hide;
    if (options.callback) {
      Modal.callback = options.callback;
    }
    document.getElementById('gh-Modal-button').addEventListener('click', Modal.callback, false);

    if (options.cancel) {
      document.getElementById('gh-Modal-cancel') &&
        document.getElementById('gh-Modal-cancel').classList.remove('hidden');
      Modal.cancel = options.cancel;
    }

    if (options.noButton) {
      document.getElementById('gh-Modal-button').classList.add('hidden');
      Modal.noButton = true;
    }

    modal.classList.remove('hidden');
    if (options) {
      document.getElementById('gh-Modal-title').innerHTML = options.title || 'Information';
      document.getElementById('gh-Modal-content').innerHTML = options.content || 'Information';
      document.getElementById('gh-Modal-button').innerHTML = options.button || 'OK';
      document.getElementById('gh-Modal-cancel').innerHTML = options.cancel || 'Cancel';
    }
  }

  static reload() {
    window.location.reload();
    //Modal.hide();
  }

  static hide() {
    const modal = document.getElementById('gh-Modal');
    if (!modal) {
      return false;
    }
    modal.classList.add('hidden');
    document.getElementById('gh-Modal-button').removeEventListener('click', Modal.callback, false);
    Modal.callback = null;
    Modal.cancel = false;
    Modal.noButton = false;
    Modal.displayButton = true;
    Modal.event = 'hide';
    document.querySelector('.ClaimHandling') && document.querySelector('.ClaimHandling').classList.remove('blurry');
    document.getElementById('gh-Modal-button').classList.remove('hidden');
  }

  render() {
    return (
      <section className="Modal hidden" id="gh-Modal">
        <section className="Modal-overlay"></section>
        <section className="Modal-box">
          <h2 id="gh-Modal-title" data-testid="Modal-title">
            {this.state.title}
          </h2>
          <p id="gh-Modal-content" className="Modal-content" data-testid="Modal-content"></p>
          {Modal.noButton === false ? (
            <section className="Navigation">
              <button id="gh-Modal-button" data-testid="Modal-button" className="DropdownMenu-orange"></button>
              <button
                id="gh-Modal-cancel"
                className="DropdownMenu-hollow hidden"
                onClick={(e) => Modal.hide()}
              ></button>
            </section>
          ) : null}
        </section>
      </section>
    );
  }
}

export default Modal;
