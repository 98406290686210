import fieldToUpdate from "./Utils/FieldsToUpdate";
import Config from "./Config/Env";
import Modal from "./Components/Common/ModalComponent";
import initialState from "./Utils/App";

class Error {
  constructor(options) {
    const errors = {
      401: { title: "Session expired", message: "Session expired. Please login again", event: "reload" },
      403: { title: "Not Authorized", message: "You are not allowed to perform this action.", event: "reload" },
      404: { title: "Not Found", message: "Element not found." },
      500: { title: "System Error", message: "An unexpected error happen. Please try again later", ...options }
    };

    const status = options.status;
    let title = options.title;
    let content = options.message;
    let action = options.event;
    if (status) {
      if (!title) {
        title = errors[status].title;
      }
      if (!content) {
        content = errors[status].message;
      }
      if (!action) {
        action = errors[status].event;
      }
    }

    const error = {
      title,
      content,
      event: action,
      callback: options.callback || null
    };

    if (document.getElementById("gh-Modal")) {
      Modal.hide();
      Modal.display(error);
    } 
    if (options.status === 401 || options.status === 403) {
      window.Config.clear();
      window.sessionStorage.clear();
      window.localStorage.clear();
    }
  }
}

const WindowConfig = {
  ...Config,
  savedState: null,
  fieldsToUpdate: {},
  editableFields: {},
  controller: new AbortController(),
  Error,
  fieldToUpdate,
  clear: function clear() {
    //console.warn('Clear Config');
    const controller = new AbortController();
    window.Config = {
      ...window.Config,
      claim: {
        claimActions: []
      },
      savedState: null,
      claimId: null,
      isClaimLoaded: false,
      warningErrors: false,
      fields: [],
      conditionalFields: [],
      fieldsToUpdate: {},
      editableFields: {},
      filesToUpload: [],
      metaFields: {},
      policyFields: {},
      controller,
      signal: controller.signal
    };
    //console.log('Config', window.Config);
  }
};

/**
 * gatherDataFromSessionStorage
 * @param none
 * @return either the initialState (if no sessionStorate.state is initialized) of the sessionStorage.state value
 */
WindowConfig.getCurrentStateFromSessionStorage = (currentStep = null) => {
  let savedState = window.savedState;
  if (!savedState) {
    savedState =
      window.sessionStorage.getItem("state") !== null && window.sessionStorage.getItem("state") !== "undefined"
        ? JSON.parse(window.sessionStorage.getItem("state"))
        : null;
  }
  return savedState && savedState.data ? savedState : initialState;
};

WindowConfig.signal = WindowConfig.controller.signal;

window.Config = WindowConfig;

export default WindowConfig;
