/* eslint-disable no-loop-func */
import moment from 'moment-timezone';
import XConfig from '../XConfig';
import Modal from '../Components/Common/ModalComponent';
import { isItAnUpdatedField, getValueFromField, getClaimActionForField, getClaimActions } from './FieldsToUpdate';
import Requests from './Requests';
import mime from 'mime-types';
import { loadClaimTranslation } from '../i18n';

moment.tz.setDefault(moment.tz.guess());

class FieldValueError {
  constructor(options) {
    Modal.display(options);
  }
}

const Common = {
  currentFrameCounter: 0,
  checkBooleanValue(value) {
    if (value === 'false' || value === 'true') {
      value = value === 'true';
    }
    return value;
  },
  setFields: async () => {
    try {
      const xConfig = new XConfig();
      const configuration = await xConfig.init(window.Config.claim.productName);
      const fields = JSON.stringify(configuration);
      window.sessionStorage.setItem('fields', fields);
      window.Config.fields = JSON.parse(fields);
      const language = navigator.language.split('-')[0];
      const globalLiterals = await loadClaimTranslation(language);
      this.props.loadLiterals(globalLiterals);
    } catch (error) {
      console.error(error);
    }
  },
  /**
   * getURLParameters
   * Get Query String parameters from the current URL
   * @return { Array } Array made of key : value as key is the parameter name, value its... value.
   */
  getURLParameters() {
    if (window.location.search.length === 0) {
      return [];
    }
    const splitedParameters = window.location.search.replace(/\?/, '').split('&');
    if (splitedParameters[0].length > 0) {
      const parameters = {};
      for (let i = 0; i < splitedParameters.length; i++) {
        const string = splitedParameters[i];
        const a = string.split(/=/);
        parameters[a[0]] = encodeURIComponent(a[1]);
      }
      return parameters;
    } else {
      return [];
    }
  },

  setConditionalFields: (claim, recievedFields) => {
    if (recievedFields) {
      const fields = [...recievedFields];
      const claimFieldValues = (claim && claim.fieldValues) || [];
      const conditionFields = fields.filter((obj) => !!obj.condition);
      window.Config.conditionalFields = conditionFields;
      window.Config.triggerFields = {};
      conditionFields.forEach((f) => {
        Object.entries(f.condition).forEach(([fieldName]) => {
          if (!window.Config.triggerFields[fieldName]) {
            window.Config.triggerFields[fieldName] = [];
          }
          window.Config.triggerFields[fieldName].push(f.name);
        });
      });

      conditionFields.forEach((field) => {
        const itemIndex = fields
          .map((item) => {
            return item.name;
          })
          .indexOf(field.name);

        fields[itemIndex] = {
          ...fields[itemIndex],
          unfullfillAtload: false,
        };
      });

      const conditionsUnfullfilled = conditionFields.filter(({ condition }) => {
        let result = [];
        for (let [fieldName, values] of Object.entries(condition)) {
          const claimFieldName = claimFieldValues[fieldName] || [];
          claimFieldName.length
            ? result.push(claimFieldName.map((value) => values.includes(value)).includes(true))
            : result.push(false);
        }
        return result.includes(false);
      });
      conditionsUnfullfilled.forEach((field) => {
        const itemIndex = fields
          .map((item) => {
            return item.name;
          })
          .indexOf(field.name);
        fields[itemIndex] = {
          ...fields[itemIndex],
          'x-conditionalHidden': 'true',
          unfullfillAtload: true,
        };
      });
      return fields;
    }
    return [];
  },
  async getSignedUrl(file, claimId, accessToken) {
    const fileNameRecieved = file.filename;
    const fileName = `${claimId}%2F${fileNameRecieved}`;
    const body = {
      fileName,
      fileType: file.mimeType,
    };
    const configRequest = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/octet-stream',
      },
      signal: window.Config.signal,
      body,
    };

    const fetchRequest = await Requests.fetch('signed-url', configRequest);
    if (!fetchRequest.ok || fetchRequest.status >= 400) {
      new window.Config.Error({
        status: fetchRequest.status,
      });
      const error = `Can't reach signed url`;
      throw error;
    }
    return await fetchRequest.json();
  },
  async uploadFileToSignedUrl(file, url) {
    const type = mime.lookup(file.filename);
    const body = new Blob([file.resource], { type });

    // Upload the file with the signedURL
    const requestUpload = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/octet-stream',
      },
      signal: window.Config.signal,
      body,
    });
    if (!requestUpload.ok) {
      throw new Error(`${requestUpload.status} ${requestUpload.statusText}`);
    }
    return requestUpload;
  },
  async postFileToSocotra(claimId, fileName) {
    const fileNamePath = `${claimId}%2F${fileName}`;
    const configRequest = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/octet-stream',
      },
      signal: window.Config.signal,
    };

    const fetchRequest = await Requests.fetch(`media?fileName=${fileNamePath}`, configRequest);

    if (!fetchRequest.ok || fetchRequest.status >= 400) {
      new window.Config.Error({
        status: fetchRequest.status,
      });
      const error = `Can't load media file ${fileNamePath}`;
      throw error;
    }
    return await fetchRequest.json();
  },
  async processUploadToSocotra(file, claimId) {
    const socotraAccessToken = window.localStorage.getItem('socotraAccessToken');
    const responseSignedUrl = await this.getSignedUrl(file, claimId, socotraAccessToken);
    const { signedRequest } = responseSignedUrl;
    await this.uploadFileToSignedUrl(file, signedRequest);
    const responseUploadSocotra = await this.postFileToSocotra(claimId, file.filename);
    return responseUploadSocotra;
  },

  /**
   * Function used to save file to socotra
   * @param {string} name The field concerned by the upload
   */
  async processFile(name, data) {
    const claimId = window.Config.claim.locator;
    let files = window.Config.filesToUpload[name];
    // We gather only files that ARE NOT to delete !!
    files = files.filter((file) => typeof file.toDelete === 'undefined' || file.toDelete === false);
    const uploadTables = await Promise.all(
      files.map(async (file) => {
        // check si le fichier est à uploader ou pas
        if (typeof file.mediaItem === 'undefined') {
          return await this.processUploadToSocotra(file, claimId);
        }
        return file.mediaItem;
      })
    );

    const originalName = data.originalName || name;
    let filesToUpload = [...uploadTables.map((item) => item.locator)];
    filesToUpload = filesToUpload.filter((v) => !!v);

    const payload = {
      [originalName]: filesToUpload,
    };
    window.Config.filesToUpload[name] = files;
    return payload;
  },

  /**
   * Function used to gather data from the form
   * @param {Object} field The field concerned by the update
   * @param {Object} fieldValues The rest of the updated fields
   */
  processCommonField(name, field) {
    let type = field.type;
    if (type === 'group') {
      type = field.subType;
    }
    const originalName = field.originalName || name;
    let { value } = getValueFromField(name);
    return { [originalName]: value };
  },

  /**
   * Function used to gather data from groups
   * @param {Object} field The field concerned by the update
   * @param {Object} fieldGroups The rest of the updated fields
   */
  async processGroup(fieldName, field) {
    let fieldValues = {};
    const fieldGroupLocator = field.parentId;
    const parent = Object.entries(window.Config.claim.fieldValues).filter((item) => {
      if (item[1].indexOf(fieldGroupLocator) !== -1) return true;
      return false;
    });
    switch (field.subType) {
      case 'media':
        const file = await this.processFile(fieldName, field);
        fieldValues = file;
        break;
      default:
        fieldValues = this.processCommonField(fieldName, field);
        break;
    }
    let returnValue = {};
    if (parent.length === 0) {
      returnValue = {
        addFieldGroups: {
          fieldName: field.parentId,
          fieldValues,
        },
      };
    } else {
      returnValue = {
        updateFieldGroups: {
          fieldGroupLocator,
          fieldName: parent[0][0],
          fieldValues,
        },
      };
    }
    return Promise.resolve(returnValue);
  },

  /**
   *
   * @param {Object} field The field concerned by the update
   * @param {Object} fieldValues The rest of the updated fields
   * @param {Array} fieldGroups The list of the updated group
   */
  async processField(field) {
    const [name, data] = field;
    let response = undefined;
    switch (data.type) {
      case 'group':
        response = await this.processGroup(name, data);
        break;
      case 'media':
        response = { fieldValues: await this.processFile(name, data) };
        break;
      default:
        response = { fieldValues: this.processCommonField(name, data) };
        break;
    }
    return response;
  },

  /**
   * Function used to manage save of a claim
   * @param {String} claimLocator Current claim id
   * @param {String} currentStatus Current status of the claim
   */
  async processAXAClaim(claimLocator, status, state) {
    let payload = {
      addSubClaims: [],
      fieldValues: {},
      addFieldGroups: [],
      removeFieldGroups: [],
      updateFieldGroups: [],
      updateSubClaims: [],
    };

    const promisesResult = await Promise.all(
      Object.entries(window.Config.fieldsToUpdate).map(async ([fieldName, data]) => {
        if (data.visible === false) {
          data.value = [];
        }
        let mandatory = false;
        if (data.parentId) {
          mandatory = getClaimActions(fieldName).mandatory;
        } else {
          mandatory = getClaimActionForField(fieldName).mandatory;
        }
        if (mandatory.includes(state.reviewAction) || isItAnUpdatedField(fieldName)) {
          const result = await this.processField([fieldName, data]);
          return result;
        }
        return {};
      })
    );

    promisesResult.forEach((response) => {
      if (response.fieldValues) {
        let fieldValues = {};
        Object.entries(response.fieldValues).forEach(([k, v]) => {
          fieldValues[k] = typeof v === 'undefined' || v.length === 0 ? [] : v;
        });
        payload = {
          ...payload,
          fieldValues: {
            ...payload.fieldValues,
            ...fieldValues,
          },
        };
      }
      ['addFieldGroups', 'updateFieldGroups', 'removeFieldGroups'].forEach((id) => {
        if (response[id]) {
          payload = {
            ...payload,
            [id]: mergeGroups(payload[id], response[id]),
          };
        }
      });
    });

    function mergeGroups(payload, response) {
      const existingIndex = payload.findIndex(
        (payloadElement) => payloadElement.fieldGroupLocator === response.fieldGroupLocator
      );
      if (existingIndex !== -1) {
        let existingGroup = payload[existingIndex];
        let fieldValues = {};
        Object.entries(response.fieldValues).forEach(([fieldName, value]) => {
          fieldValues[fieldName] = typeof value === 'undefined' || value.length === 0 ? [] : value;
        });
        existingGroup = {
          fieldGroupLocator: existingGroup.fieldGroupLocator,
          fieldName: existingGroup.fieldName,
          fieldValues: {
            ...existingGroup.fieldValues,
            ...fieldValues,
          },
        };
        payload[existingIndex] = existingGroup;
        return [...payload];
      } else {
        return [response];
      }
    }

    /* FOR TEST USES ONLY */
    //payload
    /*// just remove the /* to change to test mode
    console.log(payload);
    return {
      hasError: true,
      errors: [
        {
          code: '200',
          message: JSON.stringify(payload),
        },
      ],
    };
    /* FOR TEST USES ONLY */

    const configRequest = {
      method: state.reviewAction === 'edit' ? 'PUT' : state.currentAction.method,
      headers: {
        'Content-Type': 'application/json',
      },
      url:
        state.reviewAction === 'edit' ? `${window.Config.apiURL}/claims/${claimLocator}` : state.currentAction.resource,
      body: payload,
    };

    try {
      const result = await Requests.fetch(`claims/${claimLocator}`, configRequest);
      const response = await result.json();
      if (result.status >= 400) {
        if (
          response.claimFieldErrors &&
          response.claimFieldErrors.fieldErrors &&
          response.claimFieldErrors.fieldErrors.hasErrors
        ) {
          const errors = response.claimFieldErrors.fieldErrors.errors.map((error) => {
            const field = window.Config.fields[window.Config.fields.findIndex((i) => i.name === error.fieldName)];
            return {
              title: field.title || 'no field found',
              ...error,
            };
          });
          return {
            hasError: true,
            errors,
          };
        } else {
          return {
            hasError: true,
            errors: [response],
          };
        }
      } else {
        response.hasError = false;
        return response;
      }
    } catch (error) {
      return {
        hasError: true,
        errors: [error],
      };
    }
    // comment here to TEST */
  },

  getFieldValue(domElement, name, type, field = {}) {
    const domElementValue = {};
    switch (type) {
      case 'single':
        const selectedElement = [...document.querySelectorAll(`[name="${name}"]:checked`)][0];
        if (selectedElement && selectedElement.value) {
          const value = selectedElement.value;
          domElementValue[name] = value === 'undefined' ? [] : value;
        } else {
          console.warn('No value found for ', name, 'type single');
          domElementValue[name] = [];
        }
        break;
      case 'number':
        const valueNum = Number(domElement.value);
        const decimals = (field.decimalPlaces && field.decimalPlaces) || 0;
        if (decimals === 0 && valueNum !== parseInt(valueNum, 10)) {
          throw new FieldValueError({
            title: 'Field error',
            content: `Field ${domElement.getAttribute('x-title')} not an Integer.
                    Please fix`,
          });
        }
        const fixValue = Number(valueNum.toFixed(decimals)) || valueNum;
        domElementValue[name] = valueNum.length === 0 || valueNum === 'undefined' ? [] : fixValue;
        break;
      case 'email':
        const email = domElement.value;
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
          throw new FieldValueError({
            title: 'Field error',
            content: `Field ${domElement.getAttribute('x-title')} not a valid email.
                    Please fix`,
          });
        }
        domElementValue[name] = email.length === 0 || email === 'undefined' ? [] : email;
        break;
      case 'date':
        let date = domElement.value;
        const dateFormat = moment(date).format();
        domElementValue[name] =
          dateFormat.length === 0 || dateFormat === 'undefined' || dateFormat === '' ? null : dateFormat;
        break;
      case 'select':
      case 'string':
      case 'text':
        let value = domElement.value;
        if (
          isNaN(parseInt(value)) &&
          domElement.getAttribute('pattern') &&
          domElement.getAttribute('pattern') === 'number'
        ) {
          throw new FieldValueError({
            title: 'Field error',
            content: `Field ${domElement.getAttribute('x-title')} has a wrong type value.
                    Please fix`,
          });
        }
        domElementValue[name] = value.length === 0 || value === 'undefined' ? [] : value;
        break;
      default:
        console.warn('No type found for', name, 'should be', type);
        domElementValue[name] = undefined;
        break;
    }

    return domElementValue;
  },

  getDataFromGroup(group) {},

  processSocotraClaim(claim, descriptor) {
    let processedClaim = {
      claimActions: claim.claimActions,
    };
    window.Config.metaFields = {
      claimId: claim.locator,
      policyId: claim.policyLocator,
      productName: claim.productName,
      currentStatus: claim.currentStatus,
      incidentTimestamp: parseInt(claim.incidentTimestamp),
      notificationTimestamp: parseInt(claim.notificationTimestamp),
      updatedTimestamp: parseInt(claim.updatedTimestamp),
    };

    if (!claim.fieldValues) {
      console.error('Claim > ', claim);
      throw new Error('No field values, should be an error related to claim');
    }

    descriptor.forEach((field) => {
      const item = claim.fieldValues[field.name] || [];
      processedClaim = {
        ...processedClaim,
        [field.name]: processItem([field.name, item], claim, descriptor),
      };
      const name = field.name;
      if (field.fields) {
        // group
        field['x-fields'].forEach((field) => {});
      } else {
        field.domIndex = name;
      }
    });

    function processItem(item, claim, descriptor) {
      const [key, value] = item; // From claim.fieldsValue
      //console.log(key, value);
      // key is fieldName, value can be a locator for group/media
      // --- managing titles ---
      const descriptorIndex = descriptor.findIndex((e) => e.name === key);
      //console.log(descriptorIndex);
      const description = descriptor[descriptorIndex];
      if (!description) {
        console.log('No description found for ', key, 'in claim descriptor');
      }
      let titles = null;
      //console.log(description);
      if (description) {
        if (description.fields) {
          titles = [];
          description.fields.forEach((e) => {
            titles.push({
              name: e.name,
              title: e.title,
            });
          });
        } else {
          titles = {
            name: description.name,
            title: description.title,
          };
        }
      }

      // --- switch between types
      const uuidRecognizer = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;
      if (value[0] && value[0].match(uuidRecognizer) !== null) {
        // group or media
        let values = value.map((locator, subIndex) => {
          let fields = {};
          const group = claim.fieldGroupsByLocator[locator];
          const media = claim.mediaByLocator[locator];
          if (!!group) {
            Object.entries(group).forEach(([fieldName, fieldValue]) => {
              let subType = 'string';
              if (description && description.fields) {
                const fieldIndex = description.fields.findIndex((f) => f.name === fieldName);
                subType = description.fields[fieldIndex].type;
              }
              let options = {
                id: `${locator}-${fieldName}`,
                defaultValue: fieldValue,
                value: fieldValue,
                parentId: locator,
                originalName: fieldName,
                type: 'group',
                subType,
                claimActions: claim.claimActions,
              };

              // TODO 103609852
              let rights = getClaimActionForField(fieldName, claim.claimActions);
              if (options.parentId) {
                let mandatory = [],
                  optional = [],
                  informative = [];
                claim.claimActions.forEach((action) => {
                  const currentAction = action.id;
                  const mandatoryIndex = action.mandatoryFields.findIndex((f) => f.fieldName === key);
                  if (mandatoryIndex !== -1) {
                    mandatory.push(currentAction);
                  }
                  if (action.informativeFields.includes(key)) {
                    informative.push(currentAction);
                  }
                  if (action.optionalFields.includes(key)) {
                    optional.push(currentAction);
                  }
                });
                rights = { mandatory, informative, optional, defaultValue: [] };
              }

              let values = fieldValue.sort();

              // If value is a uuid, it should be a media
              if (fieldValue[0] && fieldValue[0].match(uuidRecognizer) !== null) {
                values = fieldValue.map((mediaLocator) => {
                  const mediaFromGroup = claim.mediaByLocator[mediaLocator];
                  options = {
                    ...options,
                    defaultValue: [mediaFromGroup.fileName],
                    value: [mediaFromGroup.fileName],
                    type: 'media',
                    ...mediaFromGroup,
                  };
                  return options;
                });
                const value = values.map((i) => i.defaultValue[0]);
                window.Config.fieldToUpdate({
                  id: options.id,
                  initialized: true,
                  defaultValue: value,
                  value,
                  parentId: locator,
                  locator,
                  type: 'group',
                  subType: 'media',
                  rights,
                });
              } else {
                window.Config.fieldToUpdate({
                  id: `${locator}-${fieldName}`,
                  defaultValue: fieldValue,
                  initialized: true,
                  value: fieldValue,
                  parentId: locator,
                  originalName: fieldName,
                  type: 'group',
                  subType,
                  claimActions: claim.claimActions,
                  rights,
                });
              }
              fields = {
                ...fields,
                [fieldName]: values,
              };
            });
            return {
              id: locator,
              type: 'group',
              claimActions: claim.claimActions,
              fields,
            };
          }
          if (!!media) {
            const options = {
              id: locator,
              defaultValue: [media.fileName],
              value: [media.fileName],
              type: 'media',
              claimActions: claim.claimActions,
              ...media,
              initialized: true,
              rights: getClaimActionForField(locator, claim.claimActions),
            };
            window.Config.fieldToUpdate(options);
            return options;
          }
          // standard field
          return locator;
        });

        return values.length === 1 && typeof values[0].id === 'undefined' ? values[0] : values;
      } else {
        const type = (description && description.type) || 'string';
        const options = {
          id: key,
          defaultValue: value,
          value,
          type,
          claimActions: claim.claimActions,
          initialized: true,
          rights: getClaimActionForField(key, claim.claimActions),
        };
        window.Config.fieldToUpdate(options);
        return value;
      }
    }

    Object.entries(processedClaim).forEach(([parentName, values]) => {
      if (values.length >= 1 && !!values[0].fields) {
        values.forEach((item) => {
          item.fields &&
            Object.entries(item.fields).forEach(([fieldName, field]) => {
              if (field.originalName) processedClaim[field.originalName] = field;
              else processedClaim[fieldName] = field;
            });
        });
      }
    });

    //processedClaim.claimActions = claim.claimActions;
    //console.log(processedClaim);

    setPolicyInformation(claim);

    return processedClaim;
  },
};

async function setPolicyInformation(claim) {
  const configRequest = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  try {
    const requestPolicy = await Requests.fetch(`policy/${claim.policyLocator}`, configRequest);
    if (!requestPolicy.ok || requestPolicy.status >= 400) {
      new window.Config.Error({
        title: `${window.savedState.Literals.errors.requestingPolicy}`,
        content: `${window.savedState.Literals.errors.requestingPolicy} ${claim.policyLocator}. ${window.savedState.Literals.errors.pleaseTryAgain}`,
      });
      const error = `Error requesting Policy ${claim.policyLocator}`;
      throw error;
    }
    const policy = await requestPolicy.json();
    Object.entries(policy).forEach(([key, value]) => {
      if (typeof value === 'string') {
        window.Config.policyFields[key] = value;
      } else {
        Object.entries(value).forEach(([subKey, subValue]) => {
          window.Config.policyFields[subKey] = subValue;
        });
      }
    });
    window.Config.policyFields.claims = policy.claims;
  } catch (e) {
    throw new Error(e);
  }
}

export default Common;
