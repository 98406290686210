import React from 'react';

class ListTableComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      hideFieldFromAction: false,
    };
  }

  render() {
    const context = this.state[this.state.context];
    const data = this.state.data[context.name];
    let values = data && data[0] ? data : [];
    const descriptorIndex = window.Config.fields.findIndex((e) => e.name === context.name);
    let titles = [];
    if (descriptorIndex !== -1) {
      const currentRoleIndex = window.Config.fields[descriptorIndex]['x-rights'].findIndex(
        (f) => f.role === window.Config.role
      );
      if (currentRoleIndex !== -1) {
        titles = window.Config.fields[descriptorIndex]['x-fields'].filter(
          (f) => f['x-rights'][currentRoleIndex].right !== 'hidden'
        );
      }
    }
    const hideField = this.state.readMode === false;
    return hideField ? null : (
      <section className={`App-List App-element`}>
        <table cellSpacing="0" cellPadding="0">
          <thead>
            <tr>
              {titles.map((t, k) => {
                const title = this.state.Literals[`${context.name}-${t.name}`] || t.title;
                return <th key={k}>{title}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {values.map((value, mainIndex) => {
              const fields = value.fields;
              return (
                <tr key={mainIndex}>
                  {titles.map((t, index) => {
                    const entries = Object.entries(fields);
                    if (!entries[index]) {
                      return <td key={index}>&nbsp;</td>;
                    }
                    //console.log('List', fields);
                    let fieldValue = entries[index][1];
                    if (typeof fieldValue[0] !== 'string') {
                      fieldValue = [fieldValue[0].fileName];
                    }
                    const tradKey = `${t}-${fieldValue[0].replace(/\s/g, '')}`;
                    const title = (tradKey && this.state.Literals[tradKey]) || fieldValue;
                    return <td key={index}>{title}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </section>
    );
  }
}

export default ListTableComponent;
