import React, { Component } from 'react';
import Moment from 'moment';
//import GoogleMapReact from 'google-map-react';
import GeoLocationComponent from './FormComponents/GeoLocationComponent';
import { getExif, createThumbnail } from './Exif-Utils';
import exifr from 'exifr';
import heic2any from 'heic2any';

import './MediaComponent.scss';
import { SpinnerComponent } from '.';

Moment.tz.setDefault('Europe/Bublin');

const exifsTag = [
  'DateTimeOriginal',
  'Make',
  'Model',
  'ModifyDate',
  'Software',
  'OffsetTime',
  'Orientation',
  'SceneType',
  'latitude',
  'longitude',
  'ImageDescription',
];

//const AnyReactComponent = ({ text }) => <div className="MediaGoogleMapMarker">{text}</div>;

const defaultWidth = 400;

class FileDescriptorComponent extends Component {
  constructor(props) {
    super();
    this.state = {
      ...props,
      sectionClassName: 'Form-thumbnail-Section ',
      hasWriteRight: false,
      tooBig: false,
      loaded: false,
      edit: false,
    };
  }

  renderFile = async () => {
    const file = await this.displayFile();
    const context = this.state[this.state.context];
    const hasWriteRight =
      context['x-rights'].findIndex((r) => r.role === window.Config.role && r.right === 'write') !== -1;
    this.setState({
      file,
      hasWriteRight,
    });
  };

  componentDidMount() {
    this.renderFile();
  }

  componentDidUpdate(newProps) {
    if (this.state.file && this.props.file.filename !== this.state.file.filename) {
      const updatedState = { ...newProps };
      if (updatedState.file && updatedState.file.exif && updatedState.file.exif.latitude) {
        updatedState.center = {
          lat: updatedState.file.exif.latitude,
          lng: updatedState.file.exif.longitude,
        };
      }
      this.setState(updatedState, () => {
        this.renderFile();
      });
    }
    const updatedProps = this.props.componentDidUpdate(this.props, this.state);
    if (updatedProps) {
      this.setState(
        {
          ...updatedProps,
        },
        () => {
          this.renderFile();
        }
      );
    }
  }

  createMapOptions(maps) {
    return {
      zoomControl: false,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
    };
  }

  displayFile = async () => {
    const context = this.state[this.state.context];
    const imageId = `${this.state.name}_${this.state.index}_thumbnail`;
    const image = new Image();
    const file = this.state.file;
    const targetCanvas = document.getElementById(imageId);
    try {
      if (targetCanvas.tagName === 'IMG') {
        const parentElement = targetCanvas.parentElement;
        parentElement.querySelectorAll('*').forEach((n) => n.remove());
        const canvas = document.createElement('canvas');
        canvas.setAttribute('id', imageId);
        canvas.classList.add('Form-thumbnail');
        canvas.classList.add('columns');
        parentElement.appendChild(canvas);
      }

      try {
        if (file.extention.match(/(jpg|png|tiff|jpeg|gif|heic)/gi)) {
          const arrayBufferView = new Uint8Array(file.resource);
          let inputBuffer = new Blob([arrayBufferView], {
            type: file.type,
          });
          let outputBuffer = inputBuffer;
          if (file.extention.match(/(heic)/gi) !== null) {
            outputBuffer = await heic2any({ blob: inputBuffer, format: 'PNG' });
          }
          const urlCreator = window.URL || window.webkitURL;
          const imageUrl = urlCreator.createObjectURL(outputBuffer);
          image.src = imageUrl;
          image.exifData = this.state.file.exif;
          await new Promise(function(resolve, reject) {
            image.addEventListener(
              'load',
              async function loadFile() {
                const width = this.width > defaultWidth ? defaultWidth : this.width;
                createThumbnail.call(this, imageId, width);
                if (typeof this.exifData === 'undefined') {
                  this.exifData =
                    file.extention.match(/heic/gi) !== null ? await exifr.parse(file.resource) : await getExif(this);
                }
                resolve(true);
              },
              false
            );
          });
          //console.log(image.exifData);
  
          file.mimeType = 'Image';
          const updatedState = {
            loaded: true,
            file,
          };
  
          if (typeof window.Config.filesToUpload[context.name] !== 'undefined') {
            const currentImageIndex = window.Config.filesToUpload[context.name].findIndex(
              (f) => f.filename === file.filename
            );
            if (
              image.exifData &&
              currentImageIndex !== -1 &&
              window.Config.filesToUpload[context.name][currentImageIndex]
            ) {
              const { DateTimeOriginal, ModifyDate } = image.exifData;
              const tags = image.exifData.tags ? image.exifData.tags : image.exifData;
              file.exif = {
                ...tags,
              };
              //console.log('exif', file.exif);
              const lang = [navigator.language, 'en'];
              if (DateTimeOriginal) {
                file.exif.DateTimeOriginal = Moment(DateTimeOriginal, 'YYYY:MM:DD HH:mm:ss')
                  .locale(lang)
                  .format('LLL')
                  .toString();
              }
              if (ModifyDate) {
                file.exif.ModifyDate = Moment(ModifyDate, 'YYYY:MM:DD HH:mm:ss')
                  .locale(lang)
                  .format('LLL')
                  .toString();
              }
              updatedState.center = {
                lat: file.exif.latitude,
                lng: file.exif.longitude,
              };
            }
          }
  
          this.setState({ ...updatedState });
          return file;
        }
      } catch (e) {
          // Couldn't create the image so we're defaulting to the below
      }
      image.setAttribute('id', imageId);
      image.src = '/Images/file.png';
      const extention = file.extention.substr(1);
      const typeFlea = document.createElement('div');
      typeFlea.classList.add('Media-type-notice');
      typeFlea.classList.add(`Media-type-${extention}`);
      typeFlea.innerHTML = extention;
      const parent = document.getElementById(imageId).parentNode;
      parent.removeChild(document.getElementById(imageId));
      parent.appendChild(image);
      parent.appendChild(typeFlea);
      file.mimeType = 'Other';
      this.setState({ loaded: true });
      return file;
    } catch (e) {
      console.error(e);
    }
    // in case of refreshing the component
  };

  openLink(link) {
    window.open(this.state.url);
  }

  cancelDeletion(event) {
    const context = this.state[this.state.context];
    const domIndex = `Media-${this.state.name}-${this.state.index}`;
    const container = document.getElementById(`${domIndex}`);
    container.classList.remove('edit');
    const parent = container.parentElement;
    const children = parent.childNodes;
    const otherEditableSibling = [...children].some((domElement) => domElement.classList.contains('edit'));
    if (!otherEditableSibling) {
      const groupParent = container.closest('.Group');
      if (groupParent) {
        groupParent.classList.remove('edit');
      }
      const AppElementParent = container.closest('.App-element');
      if (AppElementParent) {
        AppElementParent.classList.remove('edit');
      }
    }
    window.Config.editableFields[context['x-id']] = false;
    this.state.cancelDeleteFile(event, this.state.name, this.state.file.filename, domIndex);
  }

  markForDeletion() {
    const context = this.state[this.state.context];
    const domIndex = `Media-${this.state.name}-${this.state.index}`;
    const container = document.getElementById(domIndex);
    container.classList.add('edit');
    const AppElementParent = container.closest('.App-element');
    if (AppElementParent) {
      AppElementParent.classList.add('edit');
    }
    const groupParent = container.closest('.Group');
    if (groupParent) {
      groupParent.classList.add('edit');
    }
    window.Config.editableFields[context['x-id']] = true;
    this.props.removeFileFromCue(this.state.name, this.state.index);
  }

  downloadFile = (file) => {
    if (file && file.mediaItem && file.mediaItem.url) {
      window.open(file.mediaItem.url);
    }
  };

  render() {
    const domIndex = `Media-${this.state.name}-${this.state.index}`;
    const exifTags =
      this.state.file && this.state.file.exif
        ? this.state.file.exif.tags
          ? this.state.file.exif.tags
          : this.state.file.exif
        : null;
    return (
      <React.Fragment>
        {this.state.hasWriteRight && this.state.file && this.state.file.toDelete ? (
          <div className="Media-items-toDelete-notification">
            <div>
              <i className="icon icon-trash Media-items-toDelete-notification-illustration"></i>
              <div className="Media-items-toDelete-text">
                File {decodeURIComponent(this.state.file.filename)} will be <strong>deleted</strong>
              </div>
            </div>
            {!this.state.reviewMode ? (
              <button className="button Media-items-toDelete-cancel" onClick={(e) => this.cancelDeletion(e)}>
                {this.props.Literals.ui.Cancel}
              </button>
            ) : (
              <div className="Media-items-toDelete-cancel-placeholder"></div>
            )}
          </div>
        ) : null}
        <section className="Media-Spinner">{this.state.loaded === false ? <SpinnerComponent /> : null}</section>
        {this.state.file ? (
          <section
            className={`Media-items Media-item ${this.state.file.creating ? 'Media-newFile edit' : ''}`}
            locator={this.state.file.mediaItem ? this.state.file.mediaItem.locator : null}
            name={this.state.name}
            id={domIndex}
          >
            <section className="columns">
              <section className="MediaLeftColumn Media-item-picture">
                {this.state.hasWriteRight ? (
                  this.state.readMode === false && this.state.reviewMode === false ? (
                    <section className="Media-deleteFile rows" onClick={(e) => this.markForDeletion(this.state.index)}>
                      <i className="icon icon-trash" />
                      {this.props.Literals.files.delete}
                    </section>
                  ) : null
                ) : null}
                {this.state.hasWriteRight ? (
                  this.state.reviewMode === false ? (
                    <section className="Media-downloadFile rows" onClick={(e) => this.downloadFile(this.state.file)}>
                      <i className="icon icon-arrow-down" />
                      {this.props.Literals.files.download}
                    </section>
                  ) : null
                ) : null}
                <span className="Media-link">
                  <canvas
                    id={`${this.state.name}_${this.state.index}_thumbnail`}
                    locator={this.state.data && this.state.data.locator}
                    className="Form-thumbnail columns"
                  />
                </span>
              </section>
              <section className="MediaRightColumn">
                <table id={`EXIF_${this.state.name}_${this.state.index}_table`}>
                  <thead>
                    <tr>
                      <th colSpan="2">{this.props.Literals.ui.FileInformation}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <strong>{this.props.Literals.ui.Name}: </strong>
                      </td>
                      <td>
                        {this.state.file.name.length > 20 ? (
                          <span>
                            {decodeURIComponent(this.state.file.name).substr(0, 20)}
                            ...
                          </span>
                        ) : (
                          this.state.file.name
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>{this.props.Literals.ui.Extention}: </strong>
                      </td>
                      <td>{this.state.file.extention}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>{this.props.Literals.ui.Size}: </strong>
                      </td>
                      <td>{this.state.file.size.fileSize}</td>
                    </tr>
                    {exifTags ? (
                      Object.keys(exifTags).length > 0 ? (
                        <React.Fragment>
                          <tr>
                            <td colSpan="2">
                              <strong>EXIF</strong>
                            </td>
                          </tr>
                          {Object.entries(exifTags).map((item, key) => {
                            if (exifsTag.includes(item[0])) {
                              return (
                                <tr key={key}>
                                  <td>
                                    <strong>{item[0]}</strong>
                                  </td>
                                  <td>{item[1]}</td>
                                </tr>
                              );
                            }
                            return null;
                          })}
                        </React.Fragment>
                      ) : (
                        <tr>
                          <td>{this.props.Literals.ui.NoEXIFinformation}</td>
                        </tr>
                      )
                    ) : null}
                  </tbody>
                </table>
                {this.state.file.exif && this.state.file.exif.latitude ? (
                  <GeoLocationComponent
                    latitude={exifTags.latitude.toString()}
                    longitude={exifTags.longitude.toString()}
                  />
                ) : null}
              </section>
            </section>
          </section>
        ) : null}
      </React.Fragment>
    );
  }
}

export default FileDescriptorComponent;
