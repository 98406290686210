import React from 'react';

import { FormComponent, GroupComponent, SpanComponent, MediaComponent } from './';

class ComponentBuilder extends React.Component {
  constructor(props) {
    super();
    this.state = {
      ...props,
      canAddFile: false,
      hideFieldFromAction: false,
    };
  }

  componentDidMount() {
    this.setupComponent(this.state);
  }

  componentDidUpdate() {
    const updatedProps = this.props.componentDidUpdate(this.props, this.state);
    if (updatedProps) {
      this.setupComponent(this.props);
    }
  }

  setupComponent = (props) => {
    const state = { ...props };
    const context = state[state.context];
    ['informative', 'optional', 'mandatory'].forEach((rule) => {
      context[rule] =
        window.Config.fieldsToUpdate[props.context] &&
        window.Config.fieldsToUpdate[props.context][rule] &&
        window.Config.fieldsToUpdate[props.context][rule].indexOf(props.reviewAction) !== -1;
    });

    // Rights management
    // Rule : if the child right is higher than the parent's, parent instead
    if (!!context.parentId && window.Config.claim.fieldValues) {
      const roleWeight = ['hidden', 'read', 'write'];
      const parentId = context.parentId;
      // Get parent name first
      Object.entries(window.Config.claim.fieldValues).forEach(([name, values]) => {
        if (values.indexOf(parentId) !== -1) {
          context['x-parentName'] = name;
        }
      });
      // Then get parentIndex to get its right in window.Config.fields
      const parentIndex = window.Config.fields.findIndex((i) => i.name === context['x-parentName']);
      // if parent is found, then get its rights and the rights from the child
      if (parentIndex !== -1) {
        const parentRights = window.Config.fields[parentIndex]['x-rights'];
        const childIndex = window.Config.fields[parentIndex]['x-fields'].findIndex((i) => i.name === context.name);
        // if the children is found, then we check its rights
        if (childIndex !== -1) {
          const childRights = window.Config.fields[parentIndex]['x-fields'][childIndex]['x-rights'];
          const currentParentRoleIndex = parentRights.findIndex((i) => i.role === window.Config.role);
          const currentChildRoleIndex = childRights.findIndex((i) => i.role === window.Config.role);
          // We get parent role weight
          const parentRoleWeight = roleWeight.indexOf(parentRights[currentParentRoleIndex].right);
          // We get children role weight
          const childRoleWeight = roleWeight.indexOf(childRights[currentChildRoleIndex].right);
          // Now we check if the children role is higher thant the parent role weight (we are using their index from the roleWeight variable, 0 is hidden, 3 is write)
          if (childRoleWeight > parentRoleWeight) {
            // we set the child right to its parent
            // it will be used in group element and so on afterward.
            childRights[currentChildRoleIndex].right = parentRights[currentParentRoleIndex].right;
          }
        }
      }
    }

    const hasWriteAccess =
      context['x-rights'].findIndex((r) => r.role === window.Config.role && r.right === 'write') !== -1;
    let domElementType = context['x-type'];

    context['x-disabled'] = null;

    if (
      state.readMode === true ||
      (!hasWriteAccess && domElementType !== 'file') ||
      (props.reviewAction !== 'edit' && state.isInformative)
    ) {
      context['x-disabled'] = 'disabled';
    }

    const isHidden = context['x-rights'].findIndex((r) => r.role === window.Config.role && r.right === 'hidden') !== -1;

    if (context['multiline'] === true && context['x-type'] === 'text') {
      domElementType = 'textarea';
      context.rows = 10;
      context.cols = 30;
    }

    if (props.forceView === true && domElementType !== 'file') {
      domElementType = 'span';
    }
    props[props.context]['x-type'] = domElementType;
    props[props.context]['x-hidden'] = isHidden;
    this.setState({
      ...props,
      canAddAFile: false,
    });
  };
  render() {
    const Components = {
      date: FormComponent,
      email: FormComponent,
      geolocation: FormComponent,
      radio: FormComponent,
      select: FormComponent,
      checkbox: FormComponent,
      single: FormComponent,
      tel: FormComponent,
      text: FormComponent,
      string: FormComponent,
      number: FormComponent,
      file: MediaComponent,
      textarea: FormComponent,
      group: GroupComponent,
      span: SpanComponent,
    };
    const field = this.state[this.state.context];
    if (field['x-hidden'] === true) {
      return null;
    }

    let TagName = Components[field['x-type']];
    if (field['x-type'] === 'select' && field.repeatable === true) {
      TagName = Components['checkbox'];
    }

    return (
      <React.Fragment>
        <TagName {...this.state} data-form="App" required={true} />
      </React.Fragment>
    );
  }
}

export default ComponentBuilder;
