import React, { useState, useEffect } from "react";

import "./SessionClock.scss";

const threshold = {
  inform: 300, // in seconds, so 5 minutes
  warn: 30 // seconds
};

const toSeconds = milliseconds => Math.round(milliseconds/1000);
const toMinutes = seconds => Math.ceil(seconds/60);

const SessionClock = props => {
  const socotraTokenTTL = window.localStorage.getItem("socotraTokenTTL");
  const [secondsRemaining, setSecondsRemaining] = useState(socotraTokenTTL - toSeconds(Date.now()));
  const [isSessionActive, setSession] = useState(true);
  
  //Replaces componentDidMount and componentWillUnmount
  useEffect(() => {
    var timerID = setInterval( () => tick(), 500 );
    return function cleanup()
    {
      clearInterval(timerID);
    };
  });
  
  const tick = () => {
    setSecondsRemaining(socotraTokenTTL - toSeconds(Date.now()));
    if (secondsRemaining <= 0) {
      setSession(false)
    }
  };

  // There is no need to show any timer since the session ended. Simply display the banner explaining it with the restart link
  const sessionEnded = () => (
    <section className="section-col section-col-100 Menu-foreground">
      <div className="sessionClock">
        {props.Literals.sessionClock.sessionEnded}
        <span>
          <button 
            type="button"
            className="ClaimHandling-link float-right"
            onClick={e => {
              window.location.href = '/sso';
            }}>{props.Literals.sessionClock.restart}</button>
        </span>
      </div>
    </section>
  );

  const warnUser = () => (
    <div className="warningUser">{props.Literals.sessionClock.warningUser}</div>
  )
  // The user should be informed that its session is reaching the end. He should refresh it to continue
  const showRemainingTime = () => (
    <section className="section-col section-col-100 Menu-foreground">
      <div className="sessionClock">
        {props.Literals.sessionClock.timeDescription} {secondsRemaining} {props.Literals.sessionClock.secondsUnit} (~{toMinutes(secondsRemaining)} {props.Literals.sessionClock.minutesUnit})
        <span>
          <button 
            type="button"
            className="ClaimHandling-link float-right"
            onClick={e => {
              window.location.href = '/sso';
            }}>{props.Literals.sessionClock.refresh}</button>
        </span>
      </div>
      {secondsRemaining < threshold.warn ? warnUser() : false}
    </section>
  );

  // The remaining time for this session is high so we don't want to inform the user.
  const hiddenBanner = () => (false);

  if (!isSessionActive) {
    return sessionEnded();
  };

  if (secondsRemaining <= threshold.inform && secondsRemaining >= 0) {
    return showRemainingTime();
  } else {
    return hiddenBanner();
  }
}

export default SessionClock;
