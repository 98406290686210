/* eslint-disable no-unreachable */
/* eslint-disable no-unused-expressions */
import React from 'react';

class TextareaComponent extends React.Component {
  onBlur = (e) => {
    let { value } = e.target;
    this.props.updateAndCheckValue(value, e.target);
    this.props.triggerCondition(this.props.context, true);
    this.props.toggleChangeHasBeenMade();
  };

  onChange = (e) => {
    let { value } = e.target;
    this.props.updateAndCheckValue(value, e.target);
  };

  render() {
    const context = this.props[this.props.context];
    const translatedValue =
      this.props.value && this.props.Literals[`${this.props.context}-${this.props.value.replace(/\s/g, '')}`];
    const tradValue = translatedValue ? translatedValue : this.props.value;
    return (
      <React.Fragment>
        <label htmlFor={`gh-${context['x-id']}`}>{this.props.title}</label>
        <textarea
          name={context['x-id']}
          id={`gh-${context['x-id']}`}
          data-form={this.props['data-form']}
          required={context.required}
          subtype={context.subtype ? context.subtype : null}
          type={context['x-type']}
          size={context.size && context.size()}
          value={tradValue}
          step={context.step}
          onChange={this.onChange}
          onBlur={this.onBlur}
          className={`${context.className} ${this.props.errorClassName}`}
          placeholder={context['x-placeholder']}
          disabled={this.props.disabled}
          x-hidden={context['x-hidden'] ? context['x-hidden'].toString() : null}
          cols={context.cols}
          rows={context.rows}
        />
      </React.Fragment>
    );
  }
}

export default TextareaComponent;
