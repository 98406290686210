import Requests from "../../Utils/Requests";

function Login(props) {
  const access_token = window.localStorage.getItem("socotraAccessToken");
  const now = Math.round(Date.now()/1000);
  const socotraTokenTTL = window.localStorage.getItem("socotraTokenTTL");
  if ((window.Config.ssoRedirect && !access_token) || socotraTokenTTL - now <= 0) {
    Requests.renewSession();
  }

  if (access_token) {
    props.gotoStep("CLAIM_SELECTION");
  }
  return null;
}

export default Login;
