//TODO Dynimise APIEndpoint and GoogleAPIKey
const Config = {
  saveTimelap: 600,
  role: null,
  roles: [
    { id: 'account.tenant.read.only.user', scope: 'readOnly', name: 'Reader' },
    {
      id: 'account.tenant.claims.only.user',
      scope: 'claimsOnly',
      name: 'Operator',
    },
    { id: 'account.tenant.employee', scope: 'full', name: 'Admin' },
  ],
  rights: ['hidden', 'read', 'write'],
  inlineTextSizeLimit: 256,
  descriptionSizeLimit: 500,
  apiURL:
    window.location.hostname === 'chi.localtest.me'
      ? 'https://chi.localtest.me:3001'
      : `${window.location.origin.replace('//','//api-')}`,
  socotraHostname:
    process.env.REACT_APP_SOCOTRA_HOSTNAME ||
    'avanhaverbeke-axa-uber-income-protection-dev.co.sandbox.socotra.com',
  socotraProduct: process.env.REACT_APP_SOCOTRA_PRODUCT || 'uber-income-protection-v2',
  ssoRedirect: process.env.REACT_APP_SSO_REDIRECT || undefined,
};
export default Config;
