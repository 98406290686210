import React from 'react';

class Error404 extends React.Component {
  render() {
    const style = {
      fontSize: '48px',
      float: 'right'
    };
    return (
      <section className='Form Form-Summary'>
        <section className='Form-NotEligible'>
          <section className='App-element'>
            <h2>
              <i className='icon icon-search-left' style={style}></i> Page not
              Found ! (404)
            </h2>
            <p>
              It's dark there ! Where am I ?<br />
              <br />
              Can't find the page you want, or maybe we just removed an old
              artifact ?<br />
              <br />
              If you have difficulties to found what the thing you're looking
              for, do not hesitate to contact us !<br />
              <br />
              <a href={`mailto:${this.props}`}>Contact the support team.</a>
              <br />
            </p>
          </section>
        </section>
        <section className='Navigation'>
          <button
            className='Navigation-LeftButton Button-hollow'
            onClick={e => this.props.gotoStep('PORTAL')}>
            {this.props.Literals.navigation.back_to_portal}
          </button>
        </section>
      </section>
    );
  }
}

export default Error404;
