import React from 'react';
import Requests from '../../Utils/Requests';
import XConfig from '../../XConfig';
import Modal from '../Common/ModalComponent';

import Field from './Field';

import './i18n.scss';

const localesTitle = {
  en: 'English',
  fr: 'Français',
  es: 'Español',
};

class i18n extends React.Component {
  constructor(props) {
    super();
    this.state = {
      ...props,
      fields: [],
      locales: [],
      i18n: null,
      saved: false,
    };

    // CMD/Ctrl + S
    document.addEventListener(
      'keydown',
      (e) => {
        if ((window.navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey) && e.key === 's') {
          e.preventDefault();
          this.save();
        }
      },
      false
    );
  }

  componentDidMount() {
    this.loadFile();
  }

  componentDidUpdate() {
    if (this.props.productName !== this.state.productName) {
      this.setState({ productName: this.props.productName }, () => {
        this.loadFile();
      });
    }
    if (this.props.currentLocale !== this.state.currentLocale) {
      this.setState({ ...this.props });
    }
  }

  loadFile = async () => {
    if (this.props.productName === null) {
      return false;
    }
    this.setState({ fields: [] });
    const xConfig = new XConfig();
    const fields = await xConfig.init(this.state.productName);
    try {
      const transRequest = await Requests.fetch(`translations/${this.state.productName}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        signal: window.Config.signal,
      });
      if (!transRequest.ok || transRequest.status >= 400) {
        const title = `${window.savedState.Literals.errors.requestingTranslation} ${this.state.productName}`;
        new window.Config.Error({
          status: transRequest.status,
          title,
          event: 'reload',
        });
        throw title;
      }
      let i18n = await transRequest.json();
      if (typeof i18n === 'string') {
        i18n = JSON.parse(i18n);
      }
      const locales = Object.keys(i18n).map((k) => ({ code: k, title: localesTitle[k] }));
      const options = {
        fields,
        i18n,
        locales,
      };
      window.Config.i18n = {};
      locales.forEach((locale) => {
        window.Config.i18n[locale.code] = {};
      });
      this.setState(options);
    } catch (errorOnRequest) {
      const error = 'Error';
      return error;
    }
  };

  blurOnField = () => {
    this.setState({ saved: false });
  };

  // save
  async save() {
    const stringifiedFields = JSON.stringify(window.Config.i18n);
    try {
      const transRequest = await Requests.fetch(`translations/${this.state.productName}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: stringifiedFields,
      });

      if (!transRequest.ok || transRequest.status >= 400) {
        const title = `${window.savedState.Literals.errors.requestingTranslation} ${this.state.productName}`;
        new window.Config.Error({
          status: transRequest.status,
          title,
          message: i18n.message,
          event: 'reload',
        });
        throw title;
      }
      this.setState({
        saved: true,
      });
      Modal.display({
        title: 'Information',
        content: 'Configuration saved !',
        button: 'Close',
        event: 'reload',
      });
    } catch (error) {
      throw new Error(error);
    }
  }

  render() {
    if (this.state.productName === null) return null;
    return (
      <section className="i18n">
        <label htmlFor="gh-selectLocale">Current language</label>
        <section className="Select">
          <select
            id="gh-selectLocale"
            onChange={(e) => this.state.selectLocale(e.target.value)}
            value={this.state.currentLocale}
          >
            {this.state.locales.map((l, key) => {
              return (
                <option key={key} value={l.code}>
                  {l.title}
                </option>
              );
            })}
          </select>
        </section>
        <hr />
        {this.state.fields.map((field) => {
          const fieldNames = [];
          if (field.heading) {
            fieldNames.push({
              isHeading: true,
              name: `${field.name}-heading`,
              heading: field.heading,
              placeholder: field.heading,
            });
          }
          fieldNames.push({ isHeading: false, name: `${field.name}-title`, placeholder: field.title });
          if (field.values) {
            field.values.forEach((subValue) => {
              fieldNames.push({
                subValue,
                placeholder: subValue,
                name: `${field.name}-${subValue.replace(/\s/g, '')}`,
              });
            });
          }
          if (field.fields) {
            field.fields.forEach((subField) => {
              fieldNames.push({
                subField,
                name: `${field.name}-${subField.name.replace(/\s/g, '')}`,
                placeholder: subField.title,
              });
            });
          }
          return fieldNames.map((fieldName, k) => {
            return (
              <Field
                key={k}
                {...field}
                name={fieldName.name}
                hasHeading={fieldName.heading}
                subValue={fieldName.subValue}
                subField={fieldName.subField}
                locales={this.state.locales}
                placeholder={fieldName.placeholder}
                i18n={this.state.i18n}
                onBlur={this.onBlur}
                blurOnField={this.blurOnField}
                currentLocale={this.state.currentLocale}
              />
            );
          });
        })}
        <section className="i18n-save-button">
          <button
            id="gh-save"
            className={`Edit-notification Edit-notification-${this.state.saved ? 'saved' : 'edit'}`}
            onClick={(e) => {
              this.save();
            }}
          >
            {this.state.saved ? 'Saved !' : 'Save'}
          </button>
        </section>
      </section>
    );
  }
}

export default i18n;
