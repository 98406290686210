import jwt from "jsonwebtoken";
import Cookies from "../Utils/Cookie";

function verifyToken(token) {
  return jwt.decode(token);
}

const renew = () => {
  const redirect = `${window.Config.apiURL}/sso`;
  window.Config.clear();
  window.savedState = null;
  window.sessionStorage.clear();
  window.localStorage.clear();
  Cookies.eraseAll();
  if (redirect) {
    window.location.href = redirect;
  }
};

const Requests = {
  async renewSession() {
    renew();
  },
  async fetch(endpoint, { body, url, ...customConfig } = {}) {
    const token = window.localStorage.getItem("socotraAccessToken");
    if (!token || verifyToken(token) === null) {
      return {
        status: 500,
        message: "Refresh token is not valid. Please try again later."
      };
    }

    const headers = {};
    const completeURL = url || `${window.Config.apiURL}/${endpoint}`;
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
    const config = {
      //method: body ? 'POST' : 'GET',
      ...customConfig,
      headers: {
        ...headers,
        ...customConfig.headers
      }
    };
    if (body) {
      config.body = typeof body !== "string" ? JSON.stringify(body) : body;
    }


    const request = await fetch(completeURL, config);
    if (request.status >= 400) {
      switch (request.status) {
        case 401:
          await this.renewSession();
          const socotraToken = window.localStorage.getItem("socotraAccessToken");
          if (!socotraToken || !verifyToken(socotraToken)) {
            const error = "Access token is not valid.";
            throw error;
          }
          config.headers.Authorization = `Bearer ${socotraToken}`;
          const retryRequest = await fetch(`${window.Config.apiURL}/${endpoint}`, config);
          if (!retryRequest.ok) {
            new window.Config.Error({
              status: retryRequest.status
            });
            const error = "Not able to renew session.";
            throw error;
          }

          // Set interval for renewing session.
          const oldInterval = parseInt(window.localStorage.getItem("renewInterval"));
          if (oldInterval) {
            clearInterval(oldInterval);
          }
          const ttl = window.localStorage.getItem("socotraTokenTTL");
          const currentTime = new Date().getTime();
          const timeleft = ttl - currentTime - 300000;
          const interval = window.setInterval(() => this.renewSession(), timeleft);
          window.localStorage.setItem("renewInterval", interval);

          return retryRequest;
        case 500:
          return {
            status: 500,
            message: "Something unexpected happen. Please "
          };
        default:
          return request;
      }
    }
    return request;
  }
};

export default Requests;
