import React from 'react';
import ComponentBuilder from './ComponentBuilder';
//import RepeatableTableComponent from './RepeatableTableComponent';
import ListTableComponent from './ListTableComponent';

import './GroupComponent.scss';

class GroupComponent extends React.Component {
  constructor(props) {
    super();
    const field = props.field;
    const context = props.data[field['x-id']]
      ? {
          ...field,
          defaultValue: props.data[field['x-id']],
        }
      : { ...field, defaultValue: null };

    context.hasWriteRight =
      field['x-rights'].findIndex((r) => r.role === window.Config.role && r.right === 'write') !== -1;

    context.children = field['x-fields']
      ? field['x-fields']
          .map((subField) => {
            return subField['x-id'];
          })
          .join(',')
      : field['x-id'];

    this.state = {
      ...props,
      context: field['x-id'],
      hideFieldFromAction: false,
      [field['x-id']]: context,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (JSON.stringify(state) !== JSON.stringify(props)) {
      return props;
    }
    return null;
  }

  componentDidUpdate() {
    const updatedProps = this.props.componentDidUpdate(this.props, this.state);
    if (updatedProps) {
      this.setState({
        ...updatedProps,
      });
    }
  }

  groupComponentBuilder = () => {
    const context = this.state[this.state.context];
    const locators =
      window.Config.claim && window.Config.claim.fieldValues && window.Config.claim.fieldValues[this.state.context];
    let groupValues = [];
    const title = this.state.Literals[`${this.state.context}-title`] || context.title;

    // For new groups
    if (!locators) {
      return (
        <section className="Group" id={context['x-id']}>
          <h2 className="section-title">{title}</h2>
          {context['x-fields'].map((field, index) => {
            const fieldName = field.name;
            const state = {
              ...this.state,
              context: field.name,
              [field.name]: {
                ...field,
                'x-id': fieldName,
                parentId: this.state.context,
                originalName: fieldName,
              },
            };
            return <ComponentBuilder key={`component_${index}`} {...state} fromGroup={this.state.context} />;
          })}
        </section>
      );
    }

    // For group that already been saved
    locators.forEach((locator, index) => {
      groupValues = [
        ...groupValues,
        ...context['x-fields'].map((field, subIndex) => {
          const id = `${locator}-${field.name}`;
          const fieldName = field.name;
          const state = {
            ...this.state,
            context: fieldName,
            [fieldName]: {
              ...field,
              ...window.Config.fieldsToUpdate[id],
              'x-id': id,
              originalName: fieldName,
              parentId: locator,
            },
          };
          return state;
        }),
      ];
    });

    let { informative, mandatory, optional } = this.props.getDefaultValueFromState(this.state);

    const hideField =
      this.state.reviewAction !== 'edit'
        ? [mandatory, informative, optional].every((v) => !v.includes(this.state.reviewAction))
        : this.props.isFieldShouldBeHidden(this.state);
    //console.log('Group Hide field', hideField);

    return hideField ? null : (
      <section className="Group" id={context['x-id']}>
        <fieldset>
          <legend className="section-title">
            <h2>{title}</h2>
          </legend>
          {groupValues.map((state, index) => (
            <ComponentBuilder key={`componentBuilder_${index}`} {...state} fromGroup={state[state.context].parentId} />
          ))}
        </fieldset>
      </section>
    );
  };

  listTableComponent = () => {
    const context = this.state[this.state.context];
    const hideField = !this.state.readMode;
    const title = this.state.Literals[`${this.state.context}-title`] || context.title;
    return hideField ? null : (
      <section className="Group" id={context['x-id']}>
        <fieldset>
          <legend>
            <h2 className="section-title">{title}</h2>
          </legend>
          <ListTableComponent {...this.state} field={context} fromGroup={context.parentName} />
        </fieldset>
      </section>
    );
  };

  render() {
    const context = this.state[this.state.context];
    const state = {
      ...this.state,
    };

    const hideField = this.props.isFieldShouldBeHidden(this.state);
    if (hideField) {
      return null;
    }
    let group = this.groupComponentBuilder;
    if (!!context.repeatable) {
      group = this.listTableComponent;
    }
    return context['x-type'] === 'group' ? group() : <ComponentBuilder key={state.context} {...state} />;
  }
}

export default GroupComponent;
