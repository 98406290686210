const fr = {
  claimId: "Identifiant sinistre",
  policyId: "Identifiant contrat",
  productName: "Nom Produit",
  currentStatus: "Status actuel",
  incidentTimestamp: "Date de l'incident",
  notificationTimestamp: "Date de notification",
  updatedTimestamp: "Date de dernière modification",
  action_empty: "Actions",
  action_reject_with_fraud: "Rejeter avec fraudes",
  action_request_additional_proof_documents: "Requérir des preuves",
  action_accept: "Accepter",
  action_reject: "Rejeter",
  action_edit: "Edition",
  action_consider_duplicated: "Noter duclicata",
  action_rejected_payment_to_resend: "Renvoyer paiement",
  action_reopen: "Réouvrir",
  action_abandon: "Abandonner",
  aside: {
    claim: "Résumé sinistre",
    policy: "Résumé contrat",
    reviewClaim: "Vérifier sinistre",
    mandatoryFields: "Les champs marqués d'une asterisque (*) sont obligatoires",
    loadAnotherClaim: "Choisir un autre sinistre",
    loadAnotherPolicy: "Choisir un autre contrat",
    loadAClaim: "Choisir un sinistre",
    loadAPolicy: "Choisir une police",
    firstName: "Prénom",
    lastName: "Nom",
    effectiveDateTime: "Date d'effet",
    cancellationDateTime: "Date d'annulation",
    email: "Email",
    policyId: "Identifiant contrat",
    postMailFormatted: "Adresse postale",
    partnerCustomerAccountId: "Identifiant conducteur",
    partnerId: "Identifiant partenaire",
    phoneNumber: "Numéro de téléphone",
    policyInformation: "Information contrat",
    policyHolderInformation: "Information sur le détenteur du contrat",
    copyToClipboard: "Copier",
    copy: "Copier",
    loadingPolicy: "Chargement du contrat, merci de patienter..."
  },
  selectDefaultOption: {
    label: "Merci de faire un choix",
    yes: "Oui",
    no: "Non"
  },
  lang: {
    en: "English / en",
    fr: "French / fr"
  },
  menu: {
    PolicySelection: "Séléction d'une police",
    ClaimSelection: "Séléction d'un sinistre",
    Admin: "Administration",
    Logout: "Déconnexion",
    claimTitle: "Sinistre"
  },
  ui: {
    welcome: "Interface de gestion de sinistre",
    claimActions: "Actions sur le sinistre",
    Username: "Nom d'utilisateur",
    Password: "Mot de passe",
    Validate: "Valider",
    Information: "Information",
    LoadingClaimPleaseWait: "Chargement du sinistre, merci de patienter...",
    Claim: "Sinistre",
    Policy: "Policy",
    claimsHistory: "Claims history",
    hasBeenSaved: "a été sauvegardé.",
    Cancel: "Annuler",
    ReviewChanges: "Vérifier modifications",
    Loading: "Chargement...",
    Confirm: "Confirmer",
    FileInformation: "Information du fichier",
    Name: "Nom",
    Extention: "Extention",
    Size: "Taille",
    NoEXIFinformation: "Aucune information EXIF",
    ClaimSelection: "Sélection du sinistre",
    SavingClaimPleaseWait: "Sauvegarde du sinistre, merci de patienter...",
    reviewChanges: "Merci de vérifier vos modifications avant de sauvegarder",
    copied: "Copié !",
    textCopied: "Le texte à été copié dans le presse-papier",
    reopenClaim: {
      title: "Rouvrir le sinistre",
      message1: "Vous êtes sur le point de rouvrir le sinistre",
      message2: ". Voulez-vous continuer ?",
      reopenSuccess: "Sinistre rouvert avec succès.",
      reopeninClaimLoading: "Réouverture du sinistre, merci de patienter...",
      button: "Rouvrir le sinistre"
    },
    modal: {
      title: "Information",
      content: "Information",
      ok: "Valider",
      cancel: "Annuler",
      close: "Fermer"
    },
    understood: "J'ai compris",
    loginWarningMessage: `Cette plateforme est un outil professionnel de traitement des sinistres fourni par AXA. Vous reconnaissez et acceptez de ne l'utiliser qu'à partir de votre environnement technique professionnel (fourni par votre entreprise) et dans le but approprié et défini contractuellement. <br /> <br /> L'ordinateur que vous utilisez pour accéder à cette plate-forme ne doit être accessible que par du personnel autorisées. Toute tentative d'accès non autorisé serait considérée comme une violation contractuelle et potentiellement légale. <br /> <br /> Vous reconnaissez et acceptez que cette plateforme puisse être surveillé à des fins de sécurité et de gestion.`,
    policyId: "Policy ID",
    claimInformation: "Claim information",
    effectiveDateTime: "Effective date",
    cancellationDateTime: "Cancellation date",
    productName: "Product name",
    policyHolder: {
      title: "Policyholder",
      firstname: "First name",
      lastname: "Last name",
      email: "email",
      postalAddress: "Postal address",
      phoneNumber: "Phone number",
      DriverId: "Driver ID"
    },
    claims: {
      title: "Claims history",
      claimId: "Claim ID",
      policyId: "Policy ID",
      currentStatus: "Current status",
      incidentTimestamp: "Incident date",
      notificationTimestamp: "Notification date",
      updatedTimestamp: "Last modification date",
      subStatus: "Sub Status",
      productName: "Product name",
      incidentDate: "Incident Date",
      notificationDate: "Notification Date"
    }
  },
  errors: {
    ErrorOnAppLoadClaim: "Error on App.LoadClaim",
    FatalErrorOnSave: `Fatal error on save`,
    ErrorOnSave: `Error on save`,
    SaveSuccess: "Save success",
    SavingClaimPleaseWait: "Saving claim, please wait...",
    errorSelectingClaim: "Erreur lors de la sélection du sinistre",
    errorSelectingPolicy: "Error on selecting the policy",
    unknownClaimRef: "Référence inconnue",
    unknownPolicyRef: "Unknown policy reference",
    invalid_credentials: "Invalid login or password, please check and retry.",
    error500: "The server encountered an unexpected condition which prevented it from fulfilling the request.",
    label: `We have received your notification of loss. <br />
    <br />
      After reviewing your submission, unfortunately our record shows that your claim is not covered because `,
    otherException:
      "Sorry, this product is intended to only cover accident-related vehicle interruption, you do not appear to be eligible to claim on this occasion",
    format: "This format is not valid.",
    mandatory: "This field is mandatory.",
    dateIsNotValid: "This date is not valid.",
    claimRefMustBeInteger: "The claim reference must be a whole number",
    policyRefMustBeInteger: "The policy reference must be a whole number",
    dateIsInTheFuture: "You cannot set a date in the future.",
    dateMustHaveOneDayOfDifference: "Start date and end date must be at least 24 hours apart",
    noIncidentLocation: "No incident location defined.",
    size: `There are too many characters (maximum %size%)`,
    numberTooBig: "Le nombre saisie est trop grand (maximum %maximum%)",
    numberTooSmall: "Le nombre saisie est trop grand (maximum %maximum%)",
    stringTooLong: "La valeur saisie contient trop de caractères (maximum %maximum%)",
    stringTooSmall: "La valeur saisie ne contient pas assez de caractères (minimum %minimum%)",
    geolocationFromNavigatorIsNotAvailable:
      "Your browser is not allowed to access your position. Please use the text field above.",
    incidentPartnerStatus: "Please select the status of your app when the incident happened",
    InvalidIncidentLocation: "You have declared a claim for an incident that did not happen in the United Kingdom",
    resource_not_found_title: "No active policy at incident time",
    resource_not_found:
      "you did not have an active insurance policy with us at the time of the incident that resulted in your claim",
    claim_not_found_title: "Claim not found",
    claim_not_found: "We could not find this claim, please check the reference you provided.",
    policy_not_found: "We could not find this policy, please check the reference you provided.",
    schema_validation_failed_title: "Technical error",
    schema_validation_failed: "The request does not have required parameters or fails the validation",
    claim_complete_title: "Claim already completed",
    claim_complete: "You have already completed this claim",
    internal_server_error_title: "Technical error",
    internal_server_error:
      "The server encountered an unexpected condition which prevented it from fulfilling the request",
    repairMustHaveStarted: "The repair must have begun before the claim declaration can be completed.",
    noClaimInThisPolicy: "There are no claims on this policy",
    requestingConfiguration: "Error requesting configuration for",
    requestingTranslation: "Error requesting translation for",
    unableToLoadConfiguration: "Unable to load the configuration.",
    unableToLoadTranslation: "Unable to load the translation.",
    policyNotFound: "Policy not found",
    claimNotFound: "Claim not found",
    requestingPolicy: "Error requesting policy",
    requestingMedia: "Error requesting media",
    pleaseTryAgain: "Please try again.",
    uploadMedia: "Unable to upload "
  },
  defaultValue: {
    licencePlate: "AB12CDE"
  },
  formSteps: {
    PORTAL: "",
    ERROR: "%error_code%"
  },
  navigation: {
    nextStep: "Next Step",
    previousStep: "Previous Step",
    back: "Back",
    cancel: "Cancel"
  },
  files: {
    placeholder: "Click to upload file",
    loading: "Image loading to browser, please wait.",
    tooBig: "Image is larger than 5MB. It may take a while to upload.",
    delete: "Supprimer",
    download: "Télécharger",
    hint: {
      label: "Please choose at least one picture of the",
      sizeLimit: "We recommend to upload files lower than 5MB",
      incidentScenePictures: "Please upload photos regarding the incident scene. (png, jpeg, jpg...)",
      damagedVehiclePictures:
        "Your damaged vehicle pictures. Please make sure your licence plate is visible on at least one picture. (png, jpeg, jpg...)",
      vehicleRepairProof: "Please upload your vehicle repair proof documents (PDF, jpeg, png...)",
      vehicleReplacementProof:
        "For a leased vehicle, an invoice or fee from the leasing company proving that the vehicle had to be repaired or replaced. (PDF, jpeg, png...)"
    },
    descriptors: {
      thumbnail: "Thumbnail",
      name: "Name",
      size: "Size",
      extention: "Ext.",
      remove: "Del."
    },
    noFile: "Please select a file.",
    add: "Add files",
    update: "Update files",
    selectAFile: "Select a file",
    description: "Add description details if needed...",
    incidentScenePictures: "Pictures of the incident scene",
    damagedVehiclePictures: "Pictures of your damaged vehicle",
    vehicleRepairProof: "Garage repair estimate or invoice",
    vehicleReplacementProof: "Invoice or fee from the leasing company"
  },
  IncidentWThirdParty: "If the accident involved another party please provide the following information:",
  text: {
    loadingClaim: "Chargement sinistre...",
    loadClaim: "Charger Sinistre",
    claimReference: "Référence sinistre",
    addNewFile: "Ajouter fichier",
    loadingPolicy: "Loading policy...",
    loadPolicy: "Load policy",
    policyReference: "Policy Reference"
  },
  content: {}
};

export default fr;
