import React from 'react';

class CheckboxComponent extends React.Component {
  constructor(props) {
    super();
    const context = props[props.context];
    this.state = {
      ...props,
      checkedValues: [],
      choices: context['x-choices'],
    };
  }

  componentDidMount() {
    const context = this.props[this.props.context];
    let defaultValue = context.defaultValue
      ? context.defaultValue
      : this.props.data[context['x-id']]
      ? this.props.data[context['x-id']]
      : null;
    this.setState({
      checkedValues: defaultValue,
    });
  }

  onChange = (e) => {
    const { checked, value } = e.target;
    let checkedValues = this.state.checkedValues || [];
    if (checked) {
      checkedValues = [...checkedValues, value];
    } else {
      checkedValues = checkedValues.filter((singleValue) => singleValue !== value);
    }
    checkedValues.sort();
    this.setState({
      checkedValues,
    });

    this.props.updateAndCheckValue(checkedValues, e.target);
    this.props.toggleChangeHasBeenMade();
  };

  render() {
    const context = this.props[this.props.context];
    return (
      <React.Fragment>
        <label htmlFor={`gh-${context['x-id']}`}>{this.props.title}</label>
        <ul className="CheckBoxes">
          {this.state.choices.map((choice) => {
            const checkedValue = this.props.value && this.props.value.includes(choice['x-value']);
            const checkedProps = {};
            const id = `gh-${context['x-id']}_${choice['x-id']}`;
            checkedProps.checked = checkedValue;
            return (
              <li key={id}>
                <input
                  type="checkbox"
                  id={id}
                  name={context['x-id']}
                  disabled={this.props.disabled}
                  className={`Form-${choice['x-id']} Form-checkbox ${choice.className || ''} not-visible ${
                    this.props.disabled
                  }`}
                  onChange={this.onChange}
                  required={context.required}
                  defaultChecked={checkedValue}
                  defaultValue={choice['x-value']}
                  label={choice['x-value']}
                  x-hidden={context['x-hidden'] ? context['x-hidden'].toString() : null}
                />
                <label
                  htmlFor={id}
                  className={`${choice['x-className']} Form-Checkbox-Label Checkbox-button ${
                    this.props.value && this.props.value.includes(choice['x-value']) ? 'edit' : ''
                  } ${this.props.disabled}`}
                >
                  {choice['x-value']}
                </label>
              </li>
            );
          })}
        </ul>
      </React.Fragment>
    );
  }
}

export default CheckboxComponent;
