import React from "react";
import Requests from "../../Utils/Requests";
import moment from "moment";

import Modal from "../Common/ModalComponent";

import "./ClaimSelection.scss";

class PolicySelection extends React.Component {
  constructor(props) {
    super(props);
    this.modal = new Modal();
    this.state = {
      ...props,
      policy: {},
      errorCode: null,
      stuckButton: true,
      loadingPolicy: false
    };
    this.policyIdInput = React.createRef();
    this.props.initApp();
    window.addEventListener("keydown", this.onPolicySelection, false);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.onPolicySelection, false);
  }

  componentDidMount() {
    Modal.hide();
    //this.props.initApp();
    let policyId = this.policyIdInput.current.value;
    if (window.sessionStorage.getItem("policyid")) {
      policyId = parseInt(window.sessionStorage.getItem("policyid"));
      this.policyIdInput.current.value = policyId;
      this.loadPolicy();
      Modal.hide();
      Modal.display({
        title: this.props.Literals.ui.Information,
        content: this.props.Literals.ui.LoadingPolicyPleaseWait,
        noButton: true
      });
    }
    this.setState({
      policyId
    });
    document.getElementById("gh-policyId").focus();
  }

  onPolicySelection = e => {
    if (e.key === "Enter") {
      this.loadPolicy();
    }
  };

  checkPolicyId() {
    const filledPolicyId = this.policyIdInput.current;
    if (filledPolicyId === null) {
      this.setState({
        errorMessage: this.props.Literals.errors.policyRefMustBeInteger,
        stuckButton: true
      });
      return null;
    }
    const policyId = parseInt(filledPolicyId.value);
    if (filledPolicyId === null || isNaN(policyId) || policyId.length === 0 || policyId < 0) {
      this.setState({
        errorMessage: this.props.Literals.errors.policyRefMustBeInteger,
        stuckButton: true
      });
      return null;
    } else {
      this.setState({ errorMessage: null, stuckButton: false });
      return policyId;
    }
  }

  async loadPolicy() {
    if (this.state.stuckButton && window.sessionStorage.getItem("policyid") === null) {
      return false;
    }
    const policyId = this.checkPolicyId();
    if (policyId === null) {
      return false;
    }
    this.setState({
      loadingPolicy: true,
      stuckButton: true
    });
    if (policyId !== null) {
      const policy = await this.getPolicyInformation(policyId);
      Modal.hide();
      window.Config.policy = policy;
      const claimLoadedEvent = new Event("policyLoaded", { bubbles: false });
      document.dispatchEvent(claimLoadedEvent);
      window.sessionStorage.setItem("policyid", policyId);
      this.setState({
        policy,
        loadingPolicy: false,
        stuckButton: false
      });
    }
  }

  async getPolicyInformation(policyLocator) {
    const configRequest = {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    };
    const requestPolicy = await Requests.fetch(`policy/${policyLocator}`, configRequest);
    if (!requestPolicy.ok || requestPolicy.status >= 400) {
      const title = window.savedState.Literals.errors.policyNotFound;
      new window.Config.Error({
        status: requestPolicy.status,
        title,
        message: window.savedState.Literals.errors.policy_not_found,
        callback: () => {
          this.policyIdInput.current.value = "";
          this.setState({ loadingPolicy: false, stuckButton: true });
          Modal.hide();
        }
      });
      throw title;
    }
    const policy = await requestPolicy.json();
    return policy;
  }

  loadClaim = claimId => {
    if (claimId !== null) {
      window.sessionStorage.setItem("claimid", claimId);
      this.props.gotoStep("CLAIM_HANDLING");
    }
  };

  copyPaste = id => {
    const t = document.createElement("textarea");
    document.querySelector("body").appendChild(t);
    t.value = id;
    t.select();
    document.execCommand("copy");
    Modal.display({
      title: this.props.Literals.ui.copied,
      content: this.props.Literals.ui.textCopied,
      button: this.props.Literals.ui.modal.close
    });
    document.querySelector("body").removeChild(t);
  };

  clearConfig = () => {
    if (window.history.pushState) {
      var newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
      window.history.pushState({ path: newurl }, "", newurl);
    }
    window.sessionStorage.clear("policyid");
    window.sessionStorage.clear("claimid");
    this.props.toggleToReadMode(true);
    window.Config.controller.abort();
    window.Config.clear();
  };

  //103596077
  render() {
    const lang = [navigator.language, "en"];
    const { claims, policyholder, effectiveDateTime, cancellationDateTime, policyId, productName } = this.state.policy;
    return this.state.policy && claims ? (
      <React.Fragment>
        <section className="PolicySelection">
          <fieldset>
            <legend>
              <h3>{this.props.Literals.aside.policyInformation}</h3>
            </legend>
            <table cellSpacing="0" cellPadding="0" className="PolicySelection-table">
              <tbody>
                <tr>
                  <td>
                    <strong>{this.props.Literals.aside.effectiveDateTime}</strong>
                  </td>
                  <td>
                    {moment(new Date(effectiveDateTime))
                      .locale(lang)
                      .format("LLL")
                      .toString()}
                  </td>
                </tr>
                {cancellationDateTime ? (
                  <tr>
                    <td>
                      <strong>{this.props.Literals.aside.cancellationDateTime}</strong>
                    </td>
                    <td>
                      {moment(new Date(cancellationDateTime))
                        .locale(lang)
                        .format("LLL")
                        .toString()}
                    </td>
                  </tr>
                ) : null}
                <tr>
                  <td>
                    <strong>{this.props.Literals.aside.policyId}</strong>
                  </td>
                  <td>{policyId}</td>
                </tr>
                <tr>
                  <td>
                    <strong>{this.props.Literals.ui.productName}</strong>
                  </td>
                  <td>{productName}</td>
                </tr>
              </tbody>
            </table>
          </fieldset>
          <fieldset>
            <legend>
              <h3>{this.props.Literals.ui.claimsHistory}</h3>
            </legend>
            {!claims || claims.length === 0 ? (
              <span>{this.props.Literals.errors.noClaimInThisPolicy}</span>
            ) : (
              <table cellSpacing="1" cellPadding="0" className="PolicySelection-table">
                <thead>
                  <tr>
                    <th>{this.props.Literals.ui.claims.claimId}</th>
                    <th>{this.props.Literals.ui.claims.subStatus}</th>
                    <th>{this.props.Literals.ui.claims.incidentDate}</th>
                    <th>{this.props.Literals.ui.claims.notificationDate}</th>
                  </tr>
                </thead>
                <tbody>
                  {claims &&
                    claims.map((claim, claimIndex) => {
                      if (claim.locator === this.props.claimId) return null;
                      return (
                        <tr key={claimIndex} onClick={e => this.loadClaim(claim.locator)}>
                          <td>
                            <span className="ClaimHandling-link">{claim.locator}</span>
                          </td>
                          <td>{claim.fieldValues.subStatus}</td>
                          <td>
                            {moment(parseInt(claim.incidentTimestamp))
                              .locale(lang)
                              .format("LLL")
                              .toString()}{" "}
                          </td>
                          <td>
                            {moment(parseInt(claim.notificationTimestamp))
                              .locale(lang)
                              .format("LLL")
                              .toString()}{" "}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            )}
          </fieldset>
          <fieldset>
            <legend>
              <h3>{this.props.Literals.ui.policyHolder.title}</h3>
            </legend>
            <table cellSpacing="1" cellPadding="0" className="PolicySelection-table">
              <tbody>
                <tr>
                  <td>
                    <strong>{this.props.Literals.ui.policyHolder.firstname}</strong>
                  </td>
                  <td>{policyholder.firstName}</td>
                </tr>
                <tr>
                  <td>
                    <strong>{this.props.Literals.ui.policyHolder.lastname}</strong>
                  </td>
                  <td>{policyholder.lastName}</td>
                </tr>
                <tr>
                  <td>
                    <strong>{this.props.Literals.ui.policyHolder.email}</strong>
                  </td>
                  <td>{policyholder.email}</td>
                </tr>
                <tr>
                  <td>
                    <strong>{this.props.Literals.ui.policyHolder.postalAddress}</strong>
                  </td>
                  <td>{policyholder.postMailFormatted}</td>
                </tr>
                <tr>
                  <td>
                    <strong>{this.props.Literals.ui.policyHolder.phoneNumber}</strong>
                  </td>
                  <td>{policyholder.phoneNumber}</td>
                </tr>
                <tr>
                  <td>
                    <strong>{this.props.Literals.ui.policyHolder.DriverId}</strong>
                  </td>
                  <td>
                    {policyholder.partnerCustomerAccountId && policyholder.partnerCustomerAccountId.substr(0, 50)}
                    ...
                    <button
                      onClick={() => this.copyPaste(policyholder.partnerCustomerAccountId)}
                      className="DropdownMenu-copy"
                      title={this.state.Literals.aside.copyToClipboard}
                    >
                      <i className="DropdownMenu-icon icon icon-press-release"></i>
                      {this.props.Literals.aside.copy}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </fieldset>
        </section>
        <button
          className="DropdownMenu-hollow PolicySelection-button"
          onClick={() => {
            window.sessionStorage.clear("claimid");
            window.location.reload();
          }}
          id="gh-go-back-to-policy-selection"
        >
          {this.props.Literals.aside.loadAnotherPolicy}
        </button>
      </React.Fragment>
    ) : (
      <section className={`ClaimSelection ${this.state.policyId ? "hidden" : ""}`}>
        <section className="App-element">
          <label htmlFor="gh-policyId">{this.props.Literals.text.policyReference}</label>
          <input
            ref={this.policyIdInput}
            type="text"
            id="gh-policyId"
            placeholder="100034900"
            className={`centered`}
            onChange={e => this.checkPolicyId(e.target)}
          />
          <button
            type="button"
            id="gh-load-policy"
            className={`Button-orange centered ${!this.state.stuckButton ? "" : "disabled"}`}
            onClick={e => {
              this.loadPolicy();
            }}
          >
            {this.state.loadingPolicy ? this.props.Literals.text.loadingPolicy : this.props.Literals.text.loadPolicy}
          </button>
          {this.state.errorMessage ? (
            <div className="errorMessage" id="Error">
              {this.state.errorMessage}
            </div>
          ) : null}
          <span
            className="ClaimHandling-link"
            onClick={e => {
              this.clearConfig();
              this.props.gotoStep("CLAIM_SELECTION");
            }}
          >
            {this.props.Literals.aside.loadAClaim}
          </span>
        </section>
      </section>
    );
  }
}

export default PolicySelection;
