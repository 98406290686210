const en = {
  claimId: 'Claim ID',
  policyId: 'Policy ID',
  productName: 'Product name',
  currentStatus: 'Current status',
  incidentTimestamp: 'Incident date',
  notificationTimestamp: 'Notification date',
  updatedTimestamp: 'Last modification',
  no_action: 'Currently reading claim',
  action_empty: 'Actions',
  action_reject_with_fraud: 'Reject with fraud',
  action_request_additional_proof_documents: 'Request proof documents',
  action_accept: 'Accept claim',
  action_reject: 'Reject claim',
  action_edit: 'Edit',
  action_consider_duplicated: 'Mark as duplicated',
  action_rejected_payment_to_resend: 'Resend payment',
  action_reopen: 'Reopen',
  action_reopen_for_completion: 'Reopen for completion',
  action_reopen_for_assessment: 'Reopen for assessment',
  action_abandon: 'Abandon claim',
  action_reopen_after_bank_bounced_back: 'Reopen as rejected payment',
  aside: {
    claim: 'Claim summary',
    policy: 'Policy summary',
    claimsHistory: 'Other claims',
    noOtherClaims: 'There are no other claims on this policy',
    reviewClaim: 'Review claim',
    mandatoryFields: 'Fields marked with an asterisk (*) are mandatory',
    loadAnotherClaim: 'Select another claim',
    loadAnotherPolicy: 'Select another policy',
    loadAClaim: 'Select a claim',
    loadAPolicy: 'Select a policy',
    firstName: 'First name',
    lastName: 'Last name',
    effectiveDateTime: 'Effective Date',
    cancellationDateTime: 'Cancellation Date',
    email: 'Email',
    policyId: 'Policy ID',
    postMailFormatted: 'Postal address',
    partnerCustomerAccountId: 'Driver ID',
    partnerId: 'Partner ID',
    phoneNumber: 'Phone number',
    policyInformation: 'Policy information',
    policyHolderInformation: 'Policyholder information',
    copyToClipboard: 'Copy to clipboard',
    copy: 'Copy',
    loadingPolicy: 'Loading policy information, please wait...',
    id: 'ID:',
    status: 'status:',
  },
  IENotSupported: 'Sorry, but your browser is not supported. Please use a more recent version.',
  portal: {
    intro: 'Welcome to AXA XL Insurance Company UK Limited Vehicle Interruption Cover Application.',
  },
  disclaimer: {
    explanations: [
      'Welcome to the AXA XL Insurance Company UK Limited Claims Declaration Portal',
      'There are 2 steps to this process:',
      '1) Report your incident. Here you will be asked to provide information regarding the accident, including what happened, when it happened and where.',
      "2) Upload your supporting evidence. Here you will need to upload evidence to prove your claim and give us more detail about the accident. Don't worry if you don't have the information now, you can come back later.",
    ],
  },
  validation: {
    explanations: [
      'You have successfully passed the first step of your claim declaration!',
      'Before going any further with your claim declaration, check if you have the following documents / pictures :',
      '1- At least one picture of the damaged vehicle with its plate number visible',
      '2- A picture of the incident scene',
      '3- At least one repair (quotation or invoice) or replacement document indicating the completion date',
      'If you want to complete this last step later, you can use the resume link inside your notification of loss email.',
    ],
  },
  conclusion: {
    explanations: [
      'We will process your claim submission or contact you for more information shortly.',
      'Please quote your claim number %claimid% if you contact us.',
    ],
  },
  lang: {
    en: 'English / en',
    fr: 'French / fr',
  },
  menu: {
    PolicySelection: 'Policy selection',
    ClaimSelection: 'Claim selection',
    Admin: 'Administration',
    Logout: 'Logout',
    claimTitle: 'Claim',
    policyTitle: 'Policy',
  },
  ui: {
    welcome: 'Claim Handling Interface',
    claimActions: 'Claim actions',
    Username: 'Username',
    Password: 'Password',
    Validate: 'Validate',
    Information: 'Information',
    LoadingClaimPleaseWait: 'Loading claim, please wait...',
    LoadingPolicyPleaseWait: 'Loading policy, please wait...',
    Claim: 'Claim',
    Policy: 'Policy',
    claimsHistory: 'Claims history',
    hasBeenSaved: 'has been saved.',
    Cancel: 'Cancel',
    ReviewChanges: 'Review changes',
    Loading: 'Loading...',
    Confirm: 'Confirm',
    FileInformation: 'File Information',
    Name: 'Name',
    Extention: 'Extension',
    Size: 'Size',
    NoEXIFinformation: 'No EXIF information',
    ClaimSelection: 'Claim selection',
    SavingClaimPleaseWait: 'Saving claim, please wait...',
    reviewChanges: 'Please review your changes before saving.',
    copied: 'Copied !',
    textCopied: 'The text has been copied to your clipboard',
    reopenClaim: {
      title: 'Reopen the claim',
      message1: 'You are about to reopen the claim',
      message2: '. Do you want to continue?',
      reopenSuccess: 'Claim reopened with success.',
      reopeninClaimLoading: 'Reopening the claim, please wait...',
      button: 'Reopen claim',
    },
    modal: {
      title: 'Information',
      content: 'Information',
      ok: 'OK',
      cancel: 'Cancel',
      close: 'Close',
    },
    understood: 'Yes, I agree',
    loginWarningMessage: `This platform is a professional claim handling tool provided by AXA. You acknowledge and accept to only use it from your professional technical environment (provided by your company) and under the proper and contractually defined purpose.<br /><br />The computer you use to access this platform must only be accessed by authorized users. Any attempt of unauthorized access would be considered as a contractual and potentially legal breach.<br /><br />You acknowledge and accept that this platform system may be monitored for security and management purposes.`,
    policyId: 'Policy ID',
    claimInformation: 'Claim information',
    effectiveDateTime: 'Effective date',
    cancellationDateTime: 'Cancellation date',
    productName: 'Product name',
    policyHolder: {
      title: 'Policyholder',
      firstname: 'First name',
      lastname: 'Last name',
      email: 'email',
      postalAddress: 'Postal address',
      phoneNumber: 'Phone number',
      DriverId: 'Driver ID',
    },
    claims: {
      title: 'Claims history',
      claimId: 'Claim ID',
      policyId: 'Policy ID',
      currentStatus: 'Current status',
      incidentTimestamp: 'Incident date',
      notificationTimestamp: 'Notification date',
      updatedTimestamp: 'Last modification date',
      subStatus: 'Sub Status',
      productName: 'Product name',
      incidentDate: 'Incident Date',
      notificationDate: 'Notification Date',
    },
  },
  errors: {
    ErrorOnAppLoadClaim: 'Error on App.LoadClaim',
    FatalErrorOnSave: `Fatal error on save`,
    ErrorOnSave: `Error on save`,
    SaveSuccess: 'Save success',
    SavingClaimPleaseWait: 'Saving claim, please wait...',
    errorSelectingClaim: 'Error on selecting the claim',
    errorSelectingPolicy: 'Error on selecting the policy',
    unknownClaimRef: 'Unknown claim reference',
    unknownPolicyRef: 'Unknown policy reference',
    invalid_credentials: 'Invalid login or password, please check and retry.',
    error500: 'The server encountered an unexpected condition which prevented it from fulfilling the request.',
    label: `We have received your notification of loss. <br />
    <br />
      After reviewing your submission, unfortunately our record shows that your claim is not covered because `,
    otherException:
      'Sorry, this product is intended to only cover accident-related vehicle interruption, you do not appear to be eligible to claim on this occasion',
    format: 'This format is not valid.',
    mandatory: 'This field is mandatory.',
    dateIsNotValid: 'This date is not valid.',
    claimRefMustBeInteger: 'The claim reference must be a whole number',
    policyRefMustBeInteger: 'The policy reference must be a whole number',
    dateIsInTheFuture: 'You cannot set a date in the future.',
    dateMustHaveOneDayOfDifference: 'Start date and end date must be at least 24 hours apart',
    noIncidentLocation: 'No incident location defined.',
    size: `There are too many characters (maximum %size%)`,
    numberTooBig: 'Number too big (maximum %maximum%)',
    numberTooSmall: 'Number too small (minimum %minimum%)',
    stringTooLong: 'Too many characters (maximum %maximum%)',
    stringTooSmall: 'Not enough characters (minimum %minimum%)',
    geolocationFromNavigatorIsNotAvailable:
      'Your browser is not allowed to access your position. Please use the text field above.',
    incidentPartnerStatus: 'Please select the status of your app when the incident happened',
    InvalidIncidentLocation: 'You have declared a claim for an incident that did not happen in the United Kingdom',
    resource_not_found_title: 'No active policy at incident time',
    resource_not_found:
      'you did not have an active insurance policy with us at the time of the incident that resulted in your claim',
    claim_not_found_title: 'Claim not found',
    claim_not_found: 'We could not find this claim, please check the reference you provided.',
    policy_not_found: 'We could not find this policy, please check the reference you provided.',
    schema_validation_failed_title: 'Technical error',
    schema_validation_failed: 'The request does not have required parameters or fails the validation',
    claim_complete_title: 'Claim already completed',
    claim_complete: 'You have already completed this claim',
    internal_server_error_title: 'Technical error',
    internal_server_error:
      'The server encountered an unexpected condition which prevented it from fulfilling the request',
    repairMustHaveStarted: 'The repair must have begun before the claim declaration can be completed.',
    noClaimInThisPolicy: 'There are no claims on this policy',
    requestingConfiguration: 'Error requesting configuration for',
    requestingTranslation: 'Error requesting translation for',
    unableToLoadConfiguration: 'Unable to load the configuration.',
    unableToLoadTranslation: 'Unable to load the translation.',
    policyNotFound: 'Policy not found',
    claimNotFound: 'Claim not found',
    requestingPolicy: 'Error requesting policy',
    requestingMedia: 'Error requesting media',
    pleaseTryAgain: 'Please try again.',
    uploadMedia: 'Unable to upload ',
  },
  selectDefaultOption: {
    label: 'Please choose an option',
    yes: 'Yes',
    no: 'No',
  },
  defaultValue: {
    licencePlate: 'AB12CDE',
  },
  formSteps: {
    PORTAL: '',
    ERROR: '%error_code%',
  },
  navigation: {
    nextStep: 'Next Step',
    previousStep: 'Previous Step',
    back: 'Back',
    cancel: 'Cancel',
  },
  files: {
    placeholder: 'Click to upload file',
    loading: 'Image loading to browser, please wait.',
    tooBig: 'Image is larger than 5MB. It may take a while to upload.',
    delete: 'delete',
    download: 'download',
    hint: {
      label: 'Please choose at least one picture of the',
      sizeLimit: 'We recommend to upload files lower than 5MB',
      incidentScenePictures: 'Please upload photos regarding the incident scene. (png, jpeg, jpg...)',
      damagedVehiclePictures:
        'Your damaged vehicle pictures. Please make sure your licence plate is visible on at least one picture. (png, jpeg, jpg...)',
      vehicleRepairProof: 'Please upload your vehicle repair proof documents (PDF, jpeg, png...)',
      vehicleReplacementProof:
        'For a leased vehicle, an invoice or fee from the leasing company proving that the vehicle had to be repaired or replaced. (PDF, jpeg, png...)',
    },
    descriptors: {
      thumbnail: 'Thumbnail',
      name: 'Name',
      size: 'Size',
      extention: 'Ext.',
      remove: 'Del.',
    },
    noFile: 'Please select a file.',
    add: 'Add files',
    update: 'Update files',
    selectAFile: 'Select a file',
    description: 'Add description details if needed...',
    incidentScenePictures: 'Pictures of the incident scene',
    damagedVehiclePictures: 'Pictures of your damaged vehicle',
    vehicleRepairProof: 'Garage repair estimate or invoice',
    vehicleReplacementProof: 'Invoice or fee from the leasing company',
  },
  IncidentWThirdParty: 'If the accident involved another party please provide the following information:',
  text: {
    loadingClaim: 'Loading claim...',
    loadingPolicy: 'Loading policy...',
    loadClaim: 'Load claim',
    loadPolicy: 'Load policy',
    claimReference: 'Claim Reference',
    policyReference: 'Policy Reference',
    addNewFile: 'Add file',
  },
  content: {},
  sessionClock: {
    timeDescription: "The session will terminate in:",
    refresh: "Refresh the session",
    restart: "Restart the session",
    secondsUnit: "second(s)",
    minutesUnit: "minute(s)",
    sessionEnded: "The session ended",
    warningUser: "Beware, saving your work in the last seconds might be unsuccessful"
  }
};

export default en;
