import React from 'react';

import Common from '../../../Utils/Common';

class RadioComponent extends React.Component {
  onChange = (e) => {
    const { value } = e.target;
    this.props.updateAndCheckValue(value, e.target);
    this.props.toggleChangeHasBeenMade();
  };

  render() {
    const context = this.props[this.props.context];
    const defaultChecked = Common.checkBooleanValue(
      this.props.data[context['x-id']] && this.props.data[context['x-id']] === this.props.value
    );
    return (
      <React.Fragment>
        <label htmlFor={`gh-${context['x-id']}`}>{this.props.title}</label>
        <ul className={`${context.listClassName} Button-circle`}>
          {context['x-choices'].map((choice, index) => {
            return (
              <li key={index}>
                <input
                  type="radio"
                  id={`gh-${context['x-id']}-${choice['x-id']}`}
                  name={context['x-id']}
                  className={`Form-${context['x-name']}-${choice['x-id']} Form-radio`}
                  onChange={this.onChange}
                  value={choice['x-value']}
                  defaultChecked={defaultChecked}
                  disabled={this.props.disabled}
                />
                <label
                  htmlFor={`gh-${context['x-id']}-${choice['x-id']}`}
                  className={`${context['x-name']}-${choice['x-id']} Form-Radio-Label Radio-circle ${this.props.errorClassName}`}
                >
                  {choice['x-label']}
                </label>
              </li>
            );
          })}
        </ul>
      </React.Fragment>
    );
  }
}

export default RadioComponent;
