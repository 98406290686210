/**
 * React Native webapp main entry point
 */

// SYSTEM import
import React from "react";
import ReactDOM from "react-dom";
import { createStore } from "redux";
import { Provider } from "react-redux";
import jwt from "jsonwebtoken";
//SSO business
import Cookie from "./Utils/Cookie";

// Import internal libraries
import AppStore, { loadLiterals } from "./Store/App";
import loadUITranslation from "./i18n";

// Components import
import App from "./Components/App/App.js";
import Menu from "./Components/Menu/Menu.js";
import Navigation from "./Components/Navigation/Navigation.js";
import Modal from "./Components/Common/ModalComponent";

import "./WindowConfig";

// SCSS import (thanks to webpack, it's compiled automatically)
import "./index.scss";
import Requests from "./Utils/Requests";

const cookies = Cookie.readAll();

if (cookies.AccessToken && cookies.AccessToken !== "") {
  let oauthTokens;
  try {
    oauthTokens = JSON.parse(cookies.AccessToken);
  } catch (encodedError) {
    //This only seems to happen with false server - does Express encode cookies?
    oauthTokens = JSON.parse(decodeURIComponent(cookies.AccessToken));
  }
  const now = Date.now();
  window.localStorage.setItem("socotraAccessToken", oauthTokens.access_token);
  window.localStorage.setItem("socotraTokenTTL", parseInt(oauthTokens.expires_in));
  window.localStorage.setItem("loginTime", now);
  if (oauthTokens.ssoRedirect) {
    window.Config.ssoRedirect = oauthTokens.ssoRedirect;
  }
  window.Config.ssoNextStep = "CLAIM_SELECTION";
}

if (window.localStorage.getItem("socotraAccessToken")) {
  try {
    const accessToken = jwt.decode(window.localStorage.getItem("socotraAccessToken"));
    const index = window.Config.roles.findIndex(i => i.scope === accessToken["scope"]);
    window.Config = {
      ...window.Config,
      fields: JSON.parse(window.localStorage.getItem("fields")),
      socotraUser: accessToken,
      //TODO I'm not sure where this should be fixed - here or in the LoginLambda
      role: window.Config.roles && window.Config.roles[index] ? window.Config.roles[index].name : "Admin",
      roleWeight: window.Config.roles && window.Config.roles[index] ? window.Config.roles[index].weight : ""
    };
    //Common.setFields();
  } catch (error) {
    // if there is a problem with the authentication
    Requests.renewSession();
  }
} else {
  // if there is no more socotraAccessToken in localstorage
  Requests.renewSession();
}

// Load the locale system (store Literals)
const language = navigator.language.split("-")[0];
const uiTranslations = loadUITranslation(language);
const store = createStore(AppStore);
store.dispatch(loadLiterals(uiTranslations));

ReactDOM.render(
  <Provider store={store}>
    <Menu />
    <App />
    <Navigation />
    <Modal />
  </Provider>,
  document.getElementById("root")
);
// Start the react renderer
