/* eslint-disable no-unreachable */
/* eslint-disable no-unused-expressions */
import React from 'react';

class TextComponent extends React.Component {
  onBlur = (e) => {
    const { value } = e.target;
    this.props.updateAndCheckValue(value, e.target);
    this.props.toggleChangeHasBeenMade();
  };

  onChange = (e) => {
    const { value } = e.target;
    this.props.updateAndCheckValue(value, e.target);
  };

  render() {
    const context = this.props[this.props.context];
    return (
      <React.Fragment>
        <label htmlFor={`gh-${context['x-id']}`}>{this.props.title}</label>
        <input
          name={context['x-id']}
          id={`gh-${context['x-id']}`}
          data-form={this.props['data-form']}
          required={context.required}
          subtype={context.subtype ? context.subtype : null}
          type={context['x-type']}
          size={context.size && context.size()}
          value={this.props.value}
          step={context.step}
          onChange={(e) => this.onChange(e, context)}
          onBlur={(e) => this.onBlur(e, context)}
          className={`${this.props.errorClassName}`}
          placeholder={context['x-placeholder']}
          disabled={this.props.disabled}
          x-hidden={context['x-hidden'] ? context['x-hidden'].toString() : null}
        />
      </React.Fragment>
    );
  }
}

export default TextComponent;
