import React from 'react';
import Requests from '../../Utils/Requests';
import XConfig from '../../XConfig';

import Modal from '../Common/ModalComponent';

import './Edit.scss';

class Edit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      fields: [],
      saved: false,
      addField: false,
      fieldIndex: -1,
      subIndex: -1,
      newField: {
        rights: [],
      },
    };
    this.updateValue = this.updateValue.bind(this);
    this.updateRights = this.updateRights.bind(this);
    this.saveNewFieldRights = this.saveNewFieldRights.bind(this);
    this.saveNewFieldProperty = this.saveNewFieldProperty.bind(this);

    // CMD/Ctrl + S
    document.addEventListener(
      'keydown',
      (e) => {
        this.setState({ saved: false });
        if ((window.navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey) && e.keyCode === 83) {
          this.save();
        }
      },
      false
    );
  }

  displaySection(className) {
    [...document.querySelectorAll(`.${className}`)].forEach((e) => e.classList.toggle('hidden'));
  }

  addValues(id) {
    document.getElementById(id);
  }

  async loadFile() {
    if (this.props.productName === null) {
      return false;
    }
    const xConfig = new XConfig();
    const fields = await xConfig.init(this.props.productName);
    //console.log(fields);
    if (!fields) {
      const error = `Error fields is not set : ${this.props.productName}`;
      throw error;
    }
    let fieldRightError = false;
    fields.forEach((field) => {
      field['x-rights'].forEach(({ role, right }, index) => {
        if (role === 'Reader' && right === 'write') {
          fieldRightError = true;
          field['x-rights'][index].right = 'read';
        }
      });
    });
    window.Config = {
      ...window.Config,
      fields,
    };
    this.setState({
      fields,
    });
    if (fieldRightError) {
      this.save();
    }
    return true;
  }

  componentDidMount() {
    this.loadFile(this.props.productName);
  }

  componentDidUpdate() {
    if (this.props.productName !== this.state.productName) {
      this.setState({
        fields: [],
        productName: this.props.productName,
      });
      this.loadFile(this.props.productName);
    }
  }

  async save() {
    const stringifiedFields = JSON.stringify(window.Config.fields);
    const confRequest = await Requests.fetch(`configurations/${this.props.productName}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: stringifiedFields,
    });
    if (!confRequest.ok || confRequest.status >= 400) {
      new window.Config.Error({
        status: confRequest.status,
      });
      const error = `Error ${confRequest.status}`;
      throw error;
    }
    this.setState({
      saved: true,
    });
    Modal.display({
      title: 'Information',
      content: 'File successfully saved !',
      event: 'reload',
      button: 'close',
    });
  }

  updateRights(rights, index, subIndex = -1) {
    const fields = this.state.fields;
    const field = fields[index];

    if (subIndex !== -1) {
      if (typeof fields[index]['x-fields'][subIndex]['x-rights'] === 'undefined') {
        fields[index]['x-fields'][subIndex]['x-rights'] = [];
      }

      const subField = fields[index]['x-fields'][subIndex];
      const subRoleIndex = subField['x-rights'].findIndex((right) => right.role === rights.role);
      if (subRoleIndex > -1) {
        subField['x-rights'][subRoleIndex] = rights;
      } else {
        subField['x-rights'].push(rights);
      }
    } else {
      if (typeof field['x-rights'] === 'undefined') {
        field['x-rights'] = [];
      }
      const roleIndex = field['x-rights'].findIndex((right) => right.role === rights.role);
      if (roleIndex > -1) {
        field['x-rights'][roleIndex] = rights;
      } else {
        field['x-rights'].push(rights);
      }
    }

    this.setState({
      fields,
      saved: false,
    });
    window.Config.fields = fields;
  }

  updateValue(names, value, index, subIndex = -1) {
    const fields = this.state.fields;
    let keys = {};
    names.forEach((key) => {
      keys[key] = value;
    });
    if (subIndex !== -1) {
      const subFields = fields[index]['x-fields'];
      subFields[subIndex] = {
        ...subFields[subIndex],
        ...keys,
      };
      fields[index]['x-fields'][subIndex] = {
        ...fields[index]['x-fields'][subIndex],
        ...subFields[subIndex],
      };
    } else {
      fields[index] = {
        ...fields[index],
        ...keys,
      };
    }

    this.setState({
      fields,
      saved: false,
    });
    window.Config.fields = fields;
  }

  addNewField = (fieldIndex, subIndex) => {
    this.setState({ addField: true, fieldIndex, subIndex });
    window.location.href = '#addField';
  };

  cancelAddField = () => {
    this.setState({ addField: false, fieldIndex: -1, subIndex: -1 });
  };

  addFieldAtThisPoint = (index, field) => {
    const newField = this.state.newField;
    newField[index] = {
      title: '',
      id: '',
      type: '',
      rights: [],
    };
    this.setState({
      newField,
    });
  };

  async upload(e) {
    const response = window.confirm('You are going to override the current configuration. Are you sure ?');
    if (response === true) {
      const file = e.target.files[0];
      if (file) {
        var reader = new FileReader();
        reader.readAsText(file, 'UTF-8');
        const fileContent = await new Promise(function(resolve, reject) {
          reader.onload = function(evt) {
            let result = evt.target.result;
            if (typeof result === 'string') {
              result = JSON.parse(result);
            }
            resolve(result);
          };
          reader.onerror = function(evt) {
            reject({ error: { message: evt.message } });
          };
        });
        const fetchRequest = await Requests.fetch(`configurations/${this.props.productName}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: fileContent,
        });
        if (!fetchRequest.ok || fetchRequest.status >= 400) {
          new window.Config.Error({
            status: fetchRequest.status,
          });
          const error = `Error loading configurations ${fetchRequest.status}`;
          throw error;
        }
        if (fetchRequest.status === 200) {
          const fileLoaded = await this.loadFile();
          if (fileLoaded) {
            Modal.display({
              title: 'File loaded',
              content: 'Configuration set successfully !',
              event: 'reload',
              button: 'Close',
            });
            document.getElementById('gh-claim').value = null;
          }
        }
      }
    } else {
      document.getElementById('gh-claim').value = null;
    }
  }

  export(e) {
    const a = document.createElement('a');
    a.href = `data:attachment/json,${encodeURI(JSON.stringify(window.Config.fields))}`;
    a.target = '_blank';
    a.download = `claim-${getFormatedDate()}.json`;
    a.click();
  }

  moveChoiceTo = (index, direction, groupIndex = false) => {
    let fields = this.state.fields;

    if (groupIndex) {
      let xFields = fields[groupIndex]['x-fields'];
      this.move(xFields, index, index + direction);
      fields[groupIndex]['x-fields'] = xFields;
    } else {
      this.move(fields, index, index + direction);
    }

    this.setState({
      fields,
      saved: false,
    });
  };

  move = (input, from, to) => {
    let numberOfDeletedElm = 1;
    const elm = input.splice(from, numberOfDeletedElm)[0];
    numberOfDeletedElm = 0;
    input.splice(to, numberOfDeletedElm, elm);
    return input;
  };

  saveNewFieldProperty = (type, index, event, groupIndex) => {
    const { value } = event.target;
    this.setState({
      newField: {
        ...this.state.newField,
        [type]: value,
        index,
        groupIndex,
      },
    });
  };

  saveNewFieldRights = (role, index, groupIndex) => {
    this.setState({
      newField: {
        ...this.state.newField,
        rights: [...this.state.newField.rights, role],
      },
    });
  };

  saveNewField = () => {
    let newField = this.state.newField;
    let { title, name, type, rights, index, groupIndex, latitude, longitude } = newField;

    const check = [
      this.checkNewFieldProperty(title, 'Title'),
      this.checkNewFieldProperty(name, 'Name'),
      this.checkNewFieldProperty(type, 'Type'),
      this.checkNewFieldProperty(rights, 'Rights'),
    ].every((r) => r === true);
    if (type === 'long-string') {
      newField.type = 'string';
      newField.multiline = true;
    }
    if (type === 'geolocation') {
      const checkRequiredParameterForGeolocation = [
        this.checkNewFieldProperty(latitude, 'Latitude'),
        this.checkNewFieldProperty(longitude, 'Longitude'),
      ].every((r) => r === true);
      if (!checkRequiredParameterForGeolocation) {
        return false;
      }
    }
    if (check) {
      const fields = this.state.fields;
      if (typeof index !== 'undefined') {
        //it's a group !
        fields[groupIndex]['x-fields'].splice(index + 1, 0, newField);
        fields[groupIndex]['fields'].splice(index + 1, 0, newField);
      } else {
        fields.splice(groupIndex + 1, 0, newField);
      }
      this.setState({
        fields,
      });
      this.save();
    }
  };

  deleteThisField = (fieldIndex, groupIndex) => {
    const response = window.confirm(`Would you want to delete item`);
    if (response) {
      const fields = this.state.fields;
      if (typeof groupIndex !== 'undefined') {
        fields[groupIndex]['x-fields'].splice(fieldIndex, 1);
      } else {
        fields.splice(fieldIndex, 1);
      }
      this.setState({
        fields,
      });
      this.save();
    }
  };

  checkNewFieldProperty = (item, name) => {
    if (!item || item.length === 0) {
      const title = `Error while saving ${name}`;
      Modal.hide();
      Modal.display({
        title,
        content: `Please fix the ${name} of your field. No empty string.`,
      });
      return false;
    }
    return true;
  };

  render() {
    if (this.props.productName === null) return null;
    return (
      <section className="Edit">
        <button
          id="gh-save"
          onClick={(e) => {
            this.save();
          }}
          className={`Edit-notification Edit-notification-${this.state.saved ? 'saved' : 'edit'}`}
        >
          {this.state.saved ? 'Saved !' : 'Save'}
        </button>
        <fieldset className="Edit-tools" id="addField">
          <legend>Tools</legend>
          <div className="columns">
            <div className="Edit-tools-column">
              <label htmlFor="gh-claim">Upload a new configuration</label>
              <input type="file" id="gh-claim" onChange={(e) => this.upload(e)} />
            </div>
            <div className="Edit-tools-column">
              <label htmlFor="gh-downloadClaimaim">Download and save current configuration</label>
              <button id="gh-downloadClaim" onClick={(e) => this.export()}>
                Save
              </button>
            </div>
          </div>
        </fieldset>
        {this.state.addField ? (
          <AddField
            {...this.props}
            newField={this.state.newField}
            fieldIndex={this.state.fieldIndex}
            subIndex={this.state.subIndex}
            fields={this.state.fields}
            saveNewField={this.saveNewField}
            cancelAddField={this.cancelAddField}
            saveNewFieldProperty={this.saveNewFieldProperty}
            saveNewFieldRights={this.saveNewFieldRights}
          />
        ) : null}
        {this.state.fields &&
          this.state.fields.map((field, fieldIndex) => {
            return (
              <section key={fieldIndex} id={`section-${fieldIndex}`}>
                {field['x-heading'] ? <h1 className="section-title">{field['x-heading']}</h1> : null}
                <fieldset>
                  <legend onClick={(e) => this.displaySection(`content-${field['x-name']}`)}>
                    &nbsp;{field['x-name']} - {field['x-type']}&nbsp;
                  </legend>
                  <section className="Edit-moveButton">
                    <button onClick={(e) => this.addNewField(fieldIndex)}>
                      <i className="icon icon-plus" />
                      <i className="icon icon-arrow-down" />
                    </button>
                    <button onClick={(e) => this.deleteThisField(fieldIndex)}>
                      <i className="icon icon-trash" />
                    </button>

                    <button onClick={(e) => this.moveChoiceTo(fieldIndex, -1)}>
                      <i className="icon icon-arrow-up" />
                    </button>
                    <button onClick={(e) => this.moveChoiceTo(fieldIndex, 1)}>
                      <i className="icon icon-arrow-down" />
                    </button>
                  </section>
                  <Items
                    {...this.state}
                    field={field}
                    fieldIndex={fieldIndex}
                    updateValue={this.updateValue}
                    updateRights={this.updateRights}
                  />
                  {field['x-type'] === 'group' ? (
                    <section className={`hidden content-${field['x-name']} App-element`}>
                      <strong>Group items</strong>
                      {field['x-fields'].map((subField, subIndex) => {
                        return (
                          <React.Fragment key={subIndex}>
                            <fieldset>
                              <legend>
                                &nbsp;{subField['x-name']} - {subField['x-type']}&nbsp;
                              </legend>
                              <section className="Edit-moveButton">
                                <button onClick={(e) => this.addNewField(fieldIndex, subIndex)}>
                                  <i className="icon icon-plus" />
                                  <i className="icon icon-arrow-down" />
                                </button>
                                <button onClick={(e) => this.deleteThisField(subIndex, fieldIndex)}>
                                  <i className="icon icon-trash" />
                                </button>
                                {subIndex > 0 ? (
                                  <button onClick={(e) => this.moveChoiceTo(subIndex, -1, fieldIndex)}>
                                    <i className="icon icon-arrow-up" />
                                  </button>
                                ) : null}
                                {subIndex < field['x-fields'].length - 1 ? (
                                  <button onClick={(e) => this.moveChoiceTo(subIndex, 1, fieldIndex)}>
                                    <i className="icon icon-arrow-down" />
                                  </button>
                                ) : null}
                              </section>
                              <Items
                                {...this.state}
                                field={subField}
                                key={subIndex}
                                parentName={field['x-name']}
                                fieldIndex={fieldIndex}
                                subIndex={subIndex}
                                updateValue={this.updateValue}
                                updateRights={this.updateRights}
                              />
                            </fieldset>
                          </React.Fragment>
                        );
                      })}
                    </section>
                  ) : null}
                </fieldset>
              </section>
            );
          })}
      </section>
    );
  }
}

class AddField extends React.Component {
  constructor(props) {
    super();
    this.state = {
      ...props,
    };
  }
  render() {
    let fieldName = '';
    let previousField = window.Config.fields[this.props.fieldIndex];
    fieldName = `${previousField.name}`;
    if (this.props.subIndex > -1) {
      const parentName = previousField.name;
      previousField = previousField.fields[this.props.subIndex];
      fieldName = `${parentName} - ${previousField.name}`;
    }
    return (
      <fieldset>
        <legend>Add a field after {fieldName}</legend>
        <ul>
          <li>
            <label htmlFor={`gh-newField-heading-${this.state.fieldIndex}-${this.state.subIndex}`}>Heading</label>
            <input
              type="text"
              id={`gh-newField-heading-${this.state.fieldIndex}-${this.state.subIndex}`}
              style={{ width: '50%' }}
              onBlur={(e) => this.props.saveNewFieldProperty('heading', this.state.subIndex, e, this.state.fieldIndex)}
            />
          </li>
          <li>
            <label htmlFor={`gh-newField-title-${this.state.fieldIndex}-${this.state.subIndex}`}>Field Title</label>
            <input
              type="text"
              id={`gh-newField-title-${this.state.fieldIndex}-${this.state.subIndex}`}
              style={{ width: '50%' }}
              onBlur={(e) => this.props.saveNewFieldProperty('title', this.state.subIndex, e, this.state.fieldIndex)}
            />
          </li>
          <li>
            <label htmlFor={`gh-newField-name-${this.state.fieldIndex}-${this.state.subIndex}`}>Field Name</label>
            <input
              type="text"
              placeholder="No special chars"
              id={`gh-newField-name-${this.state.fieldIndex}-${this.state.subIndex}`}
              style={{ width: '50%' }}
              onChange={(e) => {
                e.target.value = e.target.value.replace(/[^A-Za-z]/g, '');
              }}
              onBlur={(e) => this.props.saveNewFieldProperty('name', this.state.subIndex, e, this.state.fieldIndex)}
            />
          </li>
          <li>
            <label htmlFor={`gh-newField-type-${this.state.fieldIndex}-${this.state.subIndex}`}>Field Type</label>
            <select
              id={`gh-newField-type-${this.state.fieldIndex}-${this.state.subIndex}`}
              onChange={(e) => this.props.saveNewFieldProperty('type', this.state.subIndex, e, this.state.fieldIndex)}
            >
              <option value="undefined">Please choose a field type</option>
              {/*
              <option value="string">Short text</option>
              <option value="long-string">Long text</option>
              <option value="select">Unique choice</option>
              <option value="checkbox">Multiple choice</option>
              <option value="date">Date</option>
              */}
              <option value="geolocation">Geo-location</option>
            </select>
          </li>
          {this.props.newField.type === 'long-string' ? (
            <li>
              <input
                type="checkbox"
                id={`gh-newField-multiline-${this.state.fieldIndex}-${this.state.subIndex}`}
                value="checked"
                disabled="disabled"
              />
              <label htmlFor={`gh-newField-multiline-${this.state.fieldIndex}-${this.state.subIndex}`}>
                Multiline (YES: textarea / NO :input-text)
              </label>
            </li>
          ) : null}
          {this.props.newField.type === 'geolocation' ? (
            <li>
              <label htmlFor={`gh-newField-latitude-${this.state.fieldIndex}-${this.state.subIndex}`}>Latitude</label>
              <select
                id={`gh-newField-latitude-${this.state.fieldIndex}-${this.state.subIndex}`}
                onChange={(e) =>
                  this.props.saveNewFieldProperty('latitude', this.state.subIndex, e, this.state.fieldIndex)
                }
              >
                <option value={undefined}>Please select a latitude from existing fields</option>
                {this.state.fields.map((field, v) => {
                  if (field['x-fields']) {
                    return (
                      <optgroup key={v} label={field.name}>
                        {field['x-fields'].map((xf, i) => {
                          return (
                            <option key={i} value={`${xf.name};${field.name}`}>
                              {xf.name}
                            </option>
                          );
                        })}
                      </optgroup>
                    );
                  }
                  return (
                    <option key={v} value={field.name}>
                      {field.name}
                    </option>
                  );
                })}
              </select>
              <label htmlFor={`gh-newField-longitude-${this.state.fieldIndex}-${this.state.subIndex}`}>Longitude</label>
              <select
                id={`gh-newField-longitude-${this.state.fieldIndex}-${this.state.subIndex}`}
                onChange={(e) =>
                  this.props.saveNewFieldProperty('longitude', this.state.subIndex, e, this.state.fieldIndex)
                }
              >
                <option value={undefined}>Please select a longitude from existing fields</option>
                {this.state.fields.map((field, fi) => {
                  if (field['x-fields']) {
                    return (
                      <optgroup key={fi} label={field.name}>
                        {field['x-fields'].map((xf, xfi) => {
                          return (
                            <option key={xfi} value={`${xf.name};${field.name}`}>
                              {xf.name}
                            </option>
                          );
                        })}
                      </optgroup>
                    );
                  }
                  return (
                    <option key={fi} value={field.name}>
                      {field.name}
                    </option>
                  );
                })}
              </select>
            </li>
          ) : null}
          <li>
            Access rights for this field:
            <table cellPadding="0" cellSpacing="0" className="table-rights">
              <thead>
                <tr>
                  <th>&nbsp;</th>
                  {window.Config.rights.map((right, rightIndex) => (
                    <th key={rightIndex}>{right}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {window.Config.roles.map((role, roleIndex) => {
                  return (
                    <tr className="Button-circle" key={roleIndex}>
                      <td>{role.name}</td>
                      {window.Config.rights.map((right, rightIndex) => {
                        return (
                          <td key={rightIndex}>
                            <input
                              type="radio"
                              id={`${roleIndex}-${this.props.fieldName}-${rightIndex}`}
                              name={`${roleIndex}-${this.props.fieldName}`}
                              value={rightIndex}
                              defaultChecked={
                                this.props.rights &&
                                this.props.rights.findIndex((i) => i.role === role.name && i.right === right) !== -1
                              }
                              disabled={right === 'write' && role.name === 'Reader' ? true : false}
                              className="Form-radio"
                              onChange={(e) => {
                                this.props.saveNewFieldRights(
                                  { role: role.name, right },
                                  this.state.fieldIndex,
                                  this.state.subIndex
                                );
                              }}
                            />
                            <label
                              htmlFor={`${roleIndex}-${this.props.fieldName}-${rightIndex}`}
                              className="Form-Radio-Label Button-circle"
                            ></label>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </li>
        </ul>
        <button className="Button orange" onClick={this.props.saveNewField}>
          Save
        </button>
        <button className="Button orange" onClick={this.props.cancelAddField}>
          Cancel
        </button>
      </fieldset>
    );
  }
}

class Items extends React.Component {
  render() {
    const { fieldIndex, subIndex } = this.props;
    //console.log('field', this.props.field);
    return (
      <ul
        className={`${this.props.parentName ? `content-${this.props.parentName}` : ''} content-${
          this.props.field['x-name']
        } hidden`}
      >
        <li>
          Title:
          <input
            type="text"
            name={`title-${this.props.field['x-title']}`}
            value={this.props.field['x-title']}
            onChange={(e) => {
              return this.props.updateValue(['title', 'x-title'], e.target.value, fieldIndex, subIndex);
            }}
          />
        </li>
        <li>
          Access rights for this field:
          <RightsTable
            {...this.props}
            onChange={this.props.updateRights}
            fieldName={this.props.field['x-name']}
            rights={this.props.field[`x-rights`]}
          />
        </li>
        {this.props.field['x-choices'] ? (
          <li>
            Values :{' '}
            <ul id={`values-${this.props.field['x-id']}`}>
              {this.props.field['x-choices']
                ? this.props.field['x-choices'].map((choice, key) => {
                    return (
                      <li key={key}>
                        <input
                          type="text"
                          name="x-value"
                          id={`gh-choice-${choice['x-id']}-${choice['x-value']}`}
                          value={choice['x-value']}
                          onChange={(e) => {}}
                        />
                      </li>
                    );
                  })
                : null}
            </ul>
          </li>
        ) : null}
      </ul>
    );
  }
}

class RightsTable extends React.Component {
  render() {
    this.rights = ['hidden', 'read', 'write'];
    return (
      <table cellPadding="0" cellSpacing="0" className="table-rights">
        <thead>
          <tr>
            <th>&nbsp;</th>
            {window.Config.rights.map((right, rightIndex) => (
              <th key={rightIndex}>{right}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {window.Config.roles.map((role, roleIndex) => {
            return (
              <tr className="Button-circle" key={roleIndex}>
                <td>{role.name}</td>
                {window.Config.rights.map((right, rightIndex) => {
                  return (
                    <td key={rightIndex}>
                      <input
                        type="radio"
                        id={`${roleIndex}-${this.props.fieldName}-${rightIndex}`}
                        name={`${roleIndex}-${this.props.fieldName}`}
                        value={rightIndex}
                        defaultChecked={
                          this.props.rights &&
                          this.props.rights.findIndex(
                            (i) => i.role === role.name && i.right === this.rights[rightIndex]
                          ) !== -1
                        }
                        disabled={right === 'write' && role.name === 'Reader' ? true : false}
                        className="Form-radio"
                        onChange={(e) => {
                          this.props.onChange({ role: role.name, right }, this.props.fieldIndex, this.props.subIndex);
                        }}
                      />
                      <label
                        htmlFor={`${roleIndex}-${this.props.fieldName}-${rightIndex}`}
                        className="Form-Radio-Label Button-circle"
                      ></label>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
}

function getFormatedDate() {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1;

  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${yyyy}${mm}${dd}`;
}

export default Edit;
