const Cookie = {
  write(name, value, days) {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toGMTString();
    }
    document.cookie = name + '=' + value + expires + '; path=/';
  },

  read(name) {
    const nameEQ = name + '=';
    const ca = document.cookie.split(';');
    let i = 0;
    for (; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  },

  readAll() {
    const split = document.cookie.split(';');
    const cookie = {};
    for (let index in split) {
      let splitedValue = split[index].split('=');
      if (splitedValue.length > 1) {
        cookie[splitedValue[0].trim()] = splitedValue[1].trim();
      }
    }
    return cookie;
  },

  erase(name) {
    window.Cookie.write(name, '', -1);
  },

  eraseAll() {
    const all = Cookie.readAll();
    Object.entries(all).forEach(([name]) => Cookie.erase(name));
  },
};

window.Cookie = Cookie;

export default Cookie;
