/**
 * @file /src/Store/App.js
 * @description File used to manage the Form store
 */
import moment from "moment-timezone";
import Requests from "../Utils/Requests";

import Navigation from "../Utils/Navigation";
import initialState from "../Utils/App";

moment.tz.setDefault(moment.tz.guess());

const loadLiterals = literals => {
  return {
    type: "loadLiterals",
    literals,
    currentLang: literals.currentLang || "en"
  };
};
/*
const saveCurrentStateIntoSessionStorage = (state) => {
  window.sessionStorage.setItem('state', JSON.stringify(state));
};
*/
/**
 * AppStore
 * @param {Object} state The current state of the store
 * @param {Object} action Literal object used to store objects value from a dispatch call
 */
const AppStore = (state = initialState, action) => {
  state = window.Config.getCurrentStateFromSessionStorage();
  // initialize the state with the current sessionStorage.state value or the initial state if it's not set yet.
  let updatedState = null;
  state.previousStep = state.currentStep;
  switch (action.type) {
    case "loadLiterals":
      updatedState = {
        ...state,
        Literals: {
          ...state.Literals,
          ...action.literals,
          currentLang: action.currentLang
        }
      };
      break;
    // Re-initilization the filesToUpload (prevent empty input[type="file"] and files stored in state)
    case "initApp":
      let { currentStep } = action;
      updatedState = {
        ...state,
        errors: {},
        error: {},
        data: {},
        fields: [],
        claim: {},
        forceFieldToUpdate: [],
        filesToUpload: [],
        navigationStuck: false,
        currentStep,
        errorMessage: null,
        isClaimLoaded: false,
        interval: null,
        loginTime: null
      };
      window.Config.clear();
      break;
    case "hideBanner":
      updatedState = {
        ...state,
        continueClaimNotification: false
      };
      break;
    case "toggleToReadMode":
      updatedState = {
        ...state,
        readMode: action.flag,
        refresh: new Date().getTime()
      };
      break;
    case "toggleToReviewMode":
      updatedState = {
        ...state,
        reviewMode: action.flag
      };
      break;
    case "warningErrors":
      updatedState = {
        ...state,
        warningErrors: action.flag
      };
      break;
    case "checkForErrors":
      updatedState = {
        ...state,
        navigationStuck:
          state.errors[state.currentStep] && Object.keys(state.errors[state.currentStep]).some(item => item.length > 0)
      };
      break;
    case "stuckNavigation":
      updatedState = {
        ...state,
        navigationStuck: true
      };
      break;
    case "unStuckNavigation":
      updatedState = {
        ...state,
        navigationStuck: false,
        loadingNavigation: false
      };
      break;
    case "reloadApp":
      window.Config.clear();
      window.localStorage.clear();
      window.sessionStorage.clear();
      updatedState = {
        ...state,
        currentStep: "LOGIN"
      };
      break;
    // Go to specific step without saving or checking errors
    case "gotoStep":
      updatedState = Navigation.gotoStep({
        state,
        action
      });
      break;
    // Go to next step with validation of the form (errors and save to sessionStorage)
    case "nextStep":
      updatedState = Navigation.validateStep({
        state,
        navigationStuck: false,
        action
      });
      break;
    // Handle add file to the current filesToUpload literal object (used to store files while they are sent to post/claim-complete)
    case "displayThumbnail":
      updatedState = {
        ...state,
        filesToUpload: action.filesToUpload
      };
      break;
    case "loadFile":
      updatedState = {
        ...state,
        loadFile: action.status
      };
      break;
    case "cancelDeleteFile":
      updatedState = {
        ...state,
        refresh: new Date().getTime()
      };
      const cancelDeleteFileIndex = window.Config.filesToUpload[action.fieldName].findIndex(
        element => element.filename === action.filename
      );
      const fileToRevert = window.Config.filesToUpload[action.fieldName][cancelDeleteFileIndex];
      fileToRevert.toDelete = false;
      const domElement = document.getElementById(action.domIndex);
      if (!domElement) {
        console.error("No element founded for ", action.domIndex);
      }
      domElement && domElement.classList.remove("Media-items-toDelete");
      domElement && domElement.classList.remove("edit");
      let value = window.Config.filesToUpload[action.fieldName].filter(file => !file.toDelete);
      window.Config.fieldToUpdate({
        id: action.fieldName,
        value: value.map(file => file.filename)
      });
      break;
    // Handle removing file from the filesToUpload literal object
    case "removeFile":
      updatedState = state;
      const fileToDelete = window.Config.filesToUpload[action.fieldName][action.fileIndex];
      const domIndex = `Media-${action.fieldName}-${action.fileIndex}`;
      const domElementToDelete = document.getElementById(domIndex);
      //console.log('Mark for deletion', domIndex);
      let valueToDelete = [];
      // Already saved file
      if (fileToDelete.mediaItem) {
        fileToDelete.toDelete = true;
        valueToDelete = window.Config.filesToUpload[action.fieldName].filter(file => !file.toDelete);
        domElementToDelete.classList.add("Media-items-toDelete");
        domElementToDelete.classList.add("edit");
        // Mettre un style opacité 50%; Ajouter un text pour signaler que l'élément va être supprimé; ajouter style edit pour validation.
      } else {
        // newly saved file
        window.Config.filesToUpload[action.fieldName].splice(action.fileIndex, 1);
        valueToDelete = window.Config.filesToUpload[action.fieldName];
      }
      window.Config.fieldToUpdate({
        id: action.fieldName,
        value: valueToDelete.map(file => file.filename)
      });
      updatedState = {
        ...state,
        refresh: new Date().getTime(),
        files: window.Config.filesToUpload
      };
      break;
    case "sendError":
      // Set error with its name
      const errors = {};
      errors[action.errorName] = action.errorName;
      updatedState = {
        ...state,
        errors
      };
      break;
    /**
     * display errors message below a specific field :
     * errors: {
     *   [<currentStep>]: {
     *      <field-name>: [{ type: '<i18n-error-name>' }]
     *    }
     * }
     */
    case "sendErrors":
      updatedState = {
        ...state,
        errors: action.errors
      };
      if (action.currentStep) {
        updatedState.currentStep = action.currentStep;
      }
      break;
    case "riseError":
      updatedState = {
        ...state,
        errors: action.errors
      };
      break;
    // Remove Error From the current step (allow navigation for customer)
    case "removeError":
      updatedState = {
        ...state,
        errors: {
          ...state.errors,
          [state.currentStep]: {}
        }
      };
      break;
    case "networkError":
      state.currentStep = "ERROR";
      updatedState = {
        ...state,
        error: action.error,
        navigationStuck: false
      };
      break;
    case "Error":
      updatedState = {
        ...state,
        ...action
      };
      break;
    case "loadingNavigation":
      updatedState = {
        ...state,
        loadingNavigation: true
      };
      break;
    case "upload":
      PostFiles(state);
      updatedState = state;
      break;
    case "validateAccountAndSortCode":
      updatedState = {
        ...state
      };
      if (action.status === false) {
        updatedState.errors = {
          ...updatedState.errors,
          recipientAccountNumber: [{ type: "format" }],
          recipientSortCode: [{ type: "format" }]
        };
      } else {
        updatedState.errors = {
          ...updatedState.errors,
          recipientAccountNumber: [],
          recipientSortCode: []
        };
      }
      break;
    case "plateNumberValidation":
      updatedState = {
        ...state,
        errors: {
          ...state.errors,
          [state.currentStep]: {
            [action.field.name]: [...action.errors]
          }
        },
        navigationStuck: action.errors.length > 0
      };
      break;
    case "ClaimLoaded":
      updatedState = {
        ...state,
        data: {
          ...action.processedSocotraClaim
        },
        errorMessage: null,
        isClaimLoaded: true,
        claim: action.claim,
        fields: action.fields
      };
      window.Config.claim = {
        ...window.Config.claim,
        ...action.claim
      };
      window.Config.fields = action.fields;
      const claimLoadedEvent = new Event("claimLoaded", { bubbles: false });
      document.dispatchEvent(claimLoadedEvent);
      //console.log('Dispatched ClaimLoaded', state.isClaimLoaded);
      break;
    case "reviewAction":
      updatedState = {
        ...state,
        reviewAction: action.id,
        currentAction: action.currentAction
      };
      if (action.id === "edit") {
        updatedState.forceFieldToUpdate = [];
      }
      break;
    case "updateFiles":
      updatedState = {
        ...state,
        files: action.files
      };
      break;
    case "forceFieldToUpdate":
      const fields = state.forceFieldToUpdate;
      if (!fields.includes(action.fieldName)) {
        fields.push(action.fieldName);
      }
      updatedState = {
        ...state,
        forceFieldToUpdate: fields
      };
      break;
    case "changeHasBeenMade":
      updatedState = {
        ...state,
        changeHasBeenMade: new Date().getTime()
      };
      break;
    case "refresh":
      updatedState = {
        ...state,
        refresh: new Date().getTime()
      };
      break;
    default:
      updatedState = {
        ...state
      };
      break;
  }
  //console.log('Update state', action.type, 'updatedState', updatedState);
  window.savedState = updatedState;
  return updatedState;
};

/**
 * async PostFiles
 * @description Post valid files to specific backend API
 * @param {Object} state Current State
 * @return undefined
 */
async function PostFiles(state) {
  const folder = moment().format("YYYYMMDDHHmmss");
  // For each valid(which has an input[type=file] counterpart)
  Object.keys(window.Config.filesToUpload).map(async (key, index) => {
    // Gather all input[type="file"] from the current dom thanks to data-file attribute
    const files = [...document.querySelectorAll(`[data-file="${key}"]`)];
    const filesList = {};
    // Create a dictionnary made of POST files
    const requestFiles = files.map(async item => {
      const file = item.files[0];
      const hashedFileName = await getHash(file.name);
      const options = {
        url: `${window.Config.UploadEndpoint}?key=${hashedFileName}&claimid=${state.claimId}&folder=${folder}`,
        method: "POST",
        headers: new Headers({
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${window.Config.OauthToken}`
        })
      };
      try {
        const fetchRequest = await Requests.fetch(null, options);
        if (!fetchRequest.ok || fetchRequest.status >= 400) {
          const title = `${window.savedState.Literals.errors.uploadMedia}`;
          new window.Config.Error({
            status: fetchRequest.status,
            title
          });
          throw title;
        }
        filesList[hashedFileName] = {
          file,
          index
        };
        return fetchRequest;
      } catch (error) {
        console.error(error);
      }
    });
    const results = await Promise.all(requestFiles);
    const encodedResults = [];
    for (let item of results) {
      encodedResults.push(await item.json());
    }
    const postRequests = encodedResults.map(async item => {
      const hashedFileName = await getHash(item.key);
      const file = filesList[hashedFileName].file;
      const formData = new FormData();
      formData.append("data", file);
      const headers = new Headers();
      headers.append("Content-Type", "multipart/form-data");
      headers.append("Authorization", `Bearer ${window.Config.OauthToken}`);
      const options = {
        url: item.url,
        method: "PUT",
        body: formData,
        headers
      };
      try {
        const fetchRequest = await Requests.fetch(null, options);
        if (!fetchRequest.ok || fetchRequest.status >= 400) {
          new window.Config.Error({
            status: fetchRequest.status
          });
          const error = `Error ${fetchRequest.status}`;
          throw error;
        }
        const response = await fetchRequest.json();
        return response;
      } catch (error) {
        console.error(error);
      }
    });
    const postRequestsResult = await Promise.all(postRequests);
    const fullResults = [];
    for (let item of postRequestsResult) {
      fullResults.push(item);
    }
  });
}

async function getHash(str, algo = "SHA-256") {
  let strBuf = new TextEncoder("utf-8").encode(str);
  const crypto = window.crypto || window.msCrypto;
  return crypto.subtle.digest(algo, strBuf).then(hash => {
    window.hash = hash;
    // here hash is an arrayBuffer,
    // so we'll connvert it to its hex version
    let result = "";
    const view = new DataView(hash);
    for (let i = 0; i < hash.byteLength; i += 4) {
      result += ("00000000" + view.getUint32(i).toString(16)).slice(-8);
    }
    return result;
  });
}

export default AppStore;
export { loadLiterals };
