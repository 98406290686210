import React from 'react';

class Error extends React.Component {
  componentDidMount() {
    const title = document.querySelector(
      '.section-col.section-col-100.section'
    );
    if (title) {
      const errorTitle =
        this.props.Literals.errors[`${this.props.error.code}_title`] || 'Error';
      title.innerHTML = title.innerHTML.replace(
        new RegExp(`%error_code%`, 'g'),
        errorTitle
      );
    }
  }
  render() {
    function setContent(props) {
      const __html =
        props.error.statusCode === 404
          ? `${props.Literals.errors.label} ${props.Literals.errors.resource_not_found}`
          : props.Literals.errors[props.error.code]
          ? props.Literals.errors[props.error.code]
          : `${props.error.type ? props.error.type : ''} : ${
              props.error.message
            }`;
      return {
        __html
      };
    }
    return (
      <section className='Form Form-Summary'>
        <section className='Form-NotEligible'>
          <section className='App-element'>
            <div
              id='errorMessage'
              className='Form-content'
              dangerouslySetInnerHTML={setContent(this.props)}
            />
          </section>
        </section>
        <section className='Navigation'>
          <button
            className='Navigation-LeftButton Button-hollow'
            onClick={e => this.props.gotoStep('PORTAL')}
          >
            {this.props.Literals.navigation.back_to_portal}
          </button>
        </section>
      </section>
    );
  }
}

export default Error;
