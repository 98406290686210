import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import Steps from '../Steps';
import Utils from '../../Utils/Navigation';
import Spinner from '../Common/SpinnerComponent';
import './Navigation.scss';
import BannerComponent from '../Common/BannerComponent';

moment.tz.setDefault(moment.tz.guess());

class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
    };
  }

  render() {
    const stuckedClassName = this.props.navigationStuck || this.props.loadingNavigation ? 'Button-Stuck' : '';
    const step = Steps[this.props.currentStep];
    let nextStep = undefined;
    if (step.next && typeof step.next.step === 'function') {
      nextStep = step.next.step(this.props);
      step.next.step = nextStep;
    } else {
      nextStep = step.next && step.next.step;
    }
    let previousStep = undefined;
    if (step.previous && typeof step.previous.step === 'function') {
      previousStep = step.previous.step(this.props);
      step.previous.step = previousStep;
    } else {
      previousStep = step.previous && step.previous.step;
    }
    return (
      <section className="Navigation section">
        {this.props.loadingNavigation && step.displayLoader ? (
          <span className="informationMessage">
            <Spinner />
            {this.props.Literals.result.loading}
          </span>
        ) : (
          ''
        )}
        {step.next && step.next.label ? (
          <button
            onClick={(event) =>
              !this.props.App.navigationStuck ? this.props[step.next.onClick](nextStep, this.props, 'next') : false
            }
            className={`${step.next.className} ${stuckedClassName}`}
          >
            {this.props.Literals.navigation[step.next.label]}
          </button>
        ) : (
          ''
        )}
        {step.previous && step.previous.label ? (
          <button
            onClick={(event) =>
              !this.props.App.loadingNavigation
                ? this.props[step.previous.onClick](previousStep, this.props, 'previous')
                : false
            }
            className={`${step.previous.className} ${this.props.App.loadingNavigation ? stuckedClassName : ''}`}
          >
            {this.props.Literals.navigation[step.previous.label]}
          </button>
        ) : (
          ''
        )}
        {this.props.claim &&
        this.props.claim.claimId &&
        this.props.continueClaimNotification === true &&
        this.props.steps[this.props.currentStep].stepIndex === 1 ? (
          <BannerComponent {...this.props} />
        ) : (
          ''
        )}
      </section>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ...state,
    ...state.App,
    ...state.Literals,
  };
};

const mapDispatchToProps = (dispatch, getState) => ({
  closeBanner: () => {
    window.sessionStorage.setItem('notNow', 'true');
    dispatch({ type: 'hideBanner' });
  },
  copyPaste: (event) => {
    const item = event.target;
    if (item.getAttribute('tocopy')) {
    }
  },
  handleStuckNavigation: () => {
    dispatch({
      type: 'stuckNavigation',
    });
  },
  handleUnStuckNavigation: () => {
    dispatch({
      type: 'unStuckNavigation',
    });
  },
  upload: () => {
    dispatch({
      type: 'upload',
    });
  },
  gotoStep: (currentStep, way) => {
    dispatch({ type: 'gotoStep', currentStep, way });
  },
  nextStep: (event) => {
    dispatch({
      type: 'nextStep',
    });
  },
  completeForm: (event) => {
    dispatch({
      type: 'completeForm',
    });
  },
  checkForErrors: (event) => {
    const domElement = event.target;
    const errors = Utils.FieldController[`${domElement.tagName}_${domElement.type.replace('-', '')}`](
      'errors',
      domElement
    );
    if (errors) {
      dispatch({
        type: 'riseError',
        errors,
      });
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
