import en from './en.js';
import fr from './fr.js';
import es from './es.js';
import Requests from '../Utils/Requests';
import Modal from '../Components/Common/ModalComponent.js';

const uiTranslations = {
  en,
  fr,
  es,
};

async function loadClaimTranslation(currentLocale = 'en') {
  if (!uiTranslations[currentLocale]) {
    currentLocale = en;
  }
  try {
    const transRequest = await Requests.fetch(`translations/${window.Config.claim.productName}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      signal: window.Config.signal,
    });
    if (!transRequest.ok || transRequest.status >= 400) {
      const title = `${window.savedState.Literals.errors.requestingTranslation}`;
      new window.Config.Error({
        status: transRequest.status,
        title,
      });
      throw title;
    }
    let claimTranslations = await transRequest.json();
    if (typeof claimTranslations === 'string') {
      claimTranslations = JSON.parse(claimTranslations);
    }
    const globalTranslations = {
      ...uiTranslations[currentLocale],
      ...claimTranslations[currentLocale],
    };
    return globalTranslations;
  } catch (error) {
    Modal.hide();
    Modal.display({
      title: 'Error',
      content: JSON.stringify(error),
      event: 'reload',
    });
  }
}

export default function loadUITranslation(currentLocale = 'en') {
  if (!uiTranslations[currentLocale]) {
    currentLocale = 'en';
  }
  return {
    ...uiTranslations[currentLocale],
  };
}

export { loadClaimTranslation };
