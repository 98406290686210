import React from 'react';

class Loading extends React.Component {
  render() {
    const style = {
      fontSize: '48px',
      float: 'right'
    };
    return (
      <section className='Form Form-Summary'>
        <section className='Form-NotEligible'>
          <section className='App-element'>
            <h2>
              <i className='icon icon-search-left' style={style}></i>LOADING PLEASE WAIT.
            </h2>
            <p>
              LOADING
            </p>
          </section>
        </section>
      </section>
    );
  }
}

export default Loading;
