import steps from "../Components/Steps/index.js";
import moment from "moment-timezone";
import Cookie from "../Utils/Cookie";
import loadUITranslation from "../i18n";
moment.tz.setDefault(moment.tz.guess());

const cookies = Cookie.readAll();

const loadLiterals = literals => {
  return {
    type: "loadLiterals",
    literals,
    currentLang: literals.currentLang || "en"
  };
};

// Load the locale system (store Literals
const language = navigator.language.split("-")[0];
const uiTranslations = loadUITranslation(language);
const Literals = loadLiterals(uiTranslations);

const accessToken = cookies.AccessToken;
let initialStep = accessToken ? "CLAIM_SELECTION" : "LOADING";
if (window.sessionStorage.getItem("claimid")) {
  initialStep = "CLAIM_HANDLING";
}
const App = {
  steps,
  isAccident: false,
  previousStep: process.env.REACT_APP_SSO_REDIRECT ? initialStep : "LOGIN",
  currentStep: process.env.REACT_APP_SSO_REDIRECT ? initialStep : "LOGIN",
  data: {},
  fields: [],
  claim: {},
  errors: {},
  forceFieldToUpdate: [],
  navigationStuck: false,
  readMode: true,
  reviewMode: false,
  reviewAction: "edit",
  errorMessage: null,
  isClaimLoaded: false,
  changeHasBeenMade: new Date().getTime(),
  warningErrors: false,
  Literals: {
    ...Literals,
    ...Literals.literals,
    currentLang: Literals.currentLang
  }
};

let state = window.sessionStorage.getItem("state");
let currentState = null;
if (state && process.env.REACT_APP_SSO_REDIRECT) {
  currentState = {
    ...JSON.parse(state),
    previousStep: initialStep,
    currentStep: initialStep
  };
  const stringifiedState = JSON.stringify(currentState);
  window.sessionStorage.setItem("state", stringifiedState);
}

export default App;
