/**
 *
 * @param {String} id Socotra id of the field to update
 * @param {*} value String, Array or Object to set the field with
 * @param {String} type [optional] Type of the field : must be ['media', 'group', 'select', 'text', 'number'...] : IF THERE IS NO TYPE GIVEN, THE VALUE IS AN UPDATE !!!
 * @param {String} subType [optional] Type of the item to update if the item depends of a group. In the case of a group, type is 'group', subType is 'select', 'text', 'media'...
 * @param {String} parentId [optional] Socotra locator id of the parent, in case of group
 * @returns {Boolean} Status of the edit : if default value is different than the value, so the item is in edit mode.
 */
function fieldToUpdate(options) {
  let {
    id,
    defaultValue,
    value,
    type,
    subType,
    parentId,
    originalName,
    cleanDefaultValue,
    inError,
    visible,
    initialized,
    rights,
  } = options;

  if (!id) {
    console.trace('No id for', options);
  }
  let update = window.Config.fieldsToUpdate[id] || {};
  if (!window.Config.fieldsToUpdate[id] && !rights) {
    rights = {
      mandatory: [],
      optional: [],
      informative: [],
    };
  }

  if (typeof initialized !== 'undefined' && typeof update.initialized === 'undefined') {
    update = {
      initialized: true,
      ...rights,
      actionDefaultValue: rights ? rights.defaultValue : [],
      defaultValue: defaultValue,
      value: defaultValue,
    };
  }
  if (typeof update.initialized === 'undefined') {
    return false;
  }
  if (typeof update.inError === 'undefined') {
    update.inError = false;
  }
  update.inError = update.inError !== false ? update.inError : false;
  if (typeof inError !== 'undefined') {
    update.inError = inError;
  }
  update.visible = true;
  if (typeof visible !== 'undefined') {
    //console.trace('Change visible', id, visible);
    update.visible = visible;
  }

  update.cleanedValue = false;
  if (!!cleanDefaultValue) {
    update.cleanedValue = true;
  }

  if (typeof type !== 'undefined') {
    update.type = type;
  }

  if (typeof parentId !== 'undefined' && parentId !== null) {
    //console.log('subtype is undefined, and value is from a group');
    update.type = 'group';
    update.parentId = parentId;
    update.subType = subType;
    update.originalName = originalName;
  }

  if (typeof value !== 'undefined') {
    if (value instanceof Array === false) {
      value = [value];
    }
    /** TEST PURPOSE ONLY  */
    /*
    if (typeof update.type === 'undefined') {
      console.log('Update ?', update);
      throw new Error(`FAIL !!! ${id} has no type !!!`)
    }
    */
    update.value = value;
  }

  if (visible === false) {
    update.value = update.defaultValue || [];
  }

  window.Config.fieldsToUpdate = {
    ...window.Config.fieldsToUpdate,
    [id]: update,
  };
  /*
  console.log(
    'Add a new value',
    '\nid:',
    id,
    '\ndv:',
    defaultValue,
    '\nv:',
    value,
    '\nt:',
    type,
    '\nst:',
    subType,
    '\npid',
    parentId,
    '\nupd',
    update
  );
  */
  const edit = isItAnUpdatedField(id);
  window.Config.editableFields[id] = edit;
  return edit;
}

/**
 * Get claim actions for a proper field
 * @param {string} fieldName
 */
function getClaimActionForField(fieldName, claimActions = null) {
  let informative = [];
  let optional = [];
  let mandatory = [];
  let defaultValue = [];
  let actions = claimActions || window.Config.claim.claimActions;

  if (typeof actions === 'undefined') {
    console.warn('No action given for FieldsToUpdate/getClaimActionForField');
    return {
      optional,
      informative,
      mandatory,
      defaultValue,
    };
  }

  actions.forEach((action) => {
    const currentAction = action.id;
    const mandatoryIndex = action.mandatoryFields.findIndex((f) => f.fieldName === fieldName);
    if (mandatoryIndex !== -1) {
      if (action.mandatoryFields[mandatoryIndex].defaultValue)
        defaultValue.push({ ...action.mandatoryFields[mandatoryIndex], action: action.id });
      mandatory.push(currentAction);
    }

    if (action.informativeFields.includes(fieldName)) {
      informative.push(currentAction);
    }
    if (action.optionalFields.includes(fieldName)) {
      optional.push(currentAction);
    }
  });

  return {
    optional,
    informative,
    mandatory,
    defaultValue,
  };
}

function getClaimActions(fieldName) {
  const field = window.Config.fieldsToUpdate[fieldName];
  if (!field) {
    //console.error('getClaimActions - no field with name', fieldName, 'in fieldsToUpdate');
    return {
      optional: [],
      informative: [],
      mandatory: [],
      defaultValue: [],
    };
  }

  let { optional, informative, mandatory, defaultValue } = field;
  return {
    optional,
    informative,
    mandatory,
    defaultValue,
  };
}

/**
 * Get values (default and updated one) for a proper field on fieldsToUpdate
 * @param {string} fieldName
 */
function getValueFromField(fieldName) {
  const field = window.Config.fieldsToUpdate[fieldName];
  if (!!field) {
    let { defaultValue, value, actionDefaultValue } = window.Config.fieldsToUpdate[fieldName];
    value = value.filter((v) => typeof v !== 'string' || v.length > 0);
    return {
      defaultValue,
      value,
      actionDefaultValue: actionDefaultValue && actionDefaultValue[0] && actionDefaultValue[0].defaultValue,
    };
  }
  //console.log('No value set for field ', fieldName, 'into fieldsToUpdate');
  return {
    defaultValue: [],
    value: [],
    actionDefaultValue: [],
  };
}

/**
 * Get a boolean flag to know if the field you request is updated or not
 * @param {string} fieldName
 */
function isItAnUpdatedField(fieldName) {
  const field = window.Config.fieldsToUpdate[fieldName];
  if (typeof field === 'undefined') return false;
  if (typeof field.value === 'undefined') return false;
  if (field.inError !== false) return false;
  if (field.cleanedValue === true) return true;
  let dv = typeof field.defaultValue !== 'undefined' ? field.defaultValue : '';
  let vl = typeof field.value !== 'undefined' ? field.value : '';
  if (dv instanceof Array) {
    dv = dv.sort();
  }
  if (vl instanceof Array) {
    vl = vl.sort();
  }
  const edit = dv.toString() !== vl.toString();
  return edit;
}

/**
 *
 */
function isFieldHasAProperValue(fieldName) {
  const field = window.Config.fieldsToUpdate[fieldName];
  if (typeof field === 'undefined' || typeof field.value === 'undefined') return false;
  const vl = typeof field.value !== 'undefined' ? field.value : '';
  if (vl.length > 0) {
    return true;
  }
  return false;
}

function getPropertyFromFieldsToUpdate(fieldName, property) {
  const field = window.Config.fieldsToUpdate[fieldName];
  //console.log('getPropertyFromFieldsToUpdate >', field, field[property]);
  if (typeof field !== 'undefined' && typeof field[property] !== 'undefined') {
    return field[property];
  }
  return undefined;
}

export default fieldToUpdate;
export {
  isItAnUpdatedField,
  getClaimActionForField,
  getValueFromField,
  isFieldHasAProperValue,
  getPropertyFromFieldsToUpdate,
  getClaimActions,
};
