import React from 'react';
import Requests from '../../Utils/Requests';
import Moment from 'moment';

import './MediaComponent.scss';

import localefr from './moment.locales/fr.js';
import FileDescriptorComponent from './FileDescriptorComponent';
import AddFileComponent from './AddFileComponent';
import { getClaimActionForField, isItAnUpdatedField } from '../../Utils/FieldsToUpdate';

if (navigator.language === 'fr-FR') {
  Moment.locale(navigator.language, localefr);
}

Moment.tz.setDefault('Europe/Bublin');

class MediaComponent extends React.Component {
  constructor(props) {
    super();
    const context = props[props.context];
    this.state = {
      ...props,
      data: props.data[props.context],
      files: [],
    };
    if (!window.Config.filesToUpload[context['x-id']]) {
      window.Config.filesToUpload[context['x-id']] = [];
    }
    //console.log(this.state);
  }

  async componentDidMount() {
    const context = this.state[this.state.context];
    if (
      (this.props.currentView === 'CLAIM_HANDLING' || this.props.currentView === 'CLAIM_VIEW') &&
      window.Config.filesToUpload[this.state.context]
    ) {
      const files = window.Config.filesToUpload[this.state.context].map((f) => f.filename);
      this.setState({ files });
    }
    if (this.state.data) {
      //console.log('Data Component did mount Media', this.state.data);
      await Promise.all(
        this.state.data.map(async (mediaItem, index) => {
          return this.loadImage(mediaItem, context['x-id'], mediaItem.fileName, index);
        })
      );
      if (window.Config.filesToUpload && window.Config.filesToUpload[context['x-id']]) {
        const files = window.Config.filesToUpload[context['x-id']].map((f) => f.filename);
        this.setState({ files });
        window.Config.fieldToUpdate({
          id: context['x-id'],
          initialized: true,
          defaultValue: files,
          originalName: context.originalName,
          type: context.parentId ? 'group' : 'media',
          subType: context.parentId ? 'media' : null,
          parentId: context.parentId ? context.parentId : null,
          claimActions: this.state.claim.claimActions,
          rights: getClaimActionForField(context.name),
        });
      }
    }
  }

  componentWillUnmount() {
    window.Config.filesToUpload[this.state.context] = [];
  }

  componentDidUpdate() {
    const updatedProps = this.props.componentDidUpdate(this.props, this.state);
    if (updatedProps) {
      this.setState({
        ...updatedProps,
        canAddFile: this.componentCanAddFile(),
      });
    }
  }

  displayFiles = () => {
    const context = this.state[this.state.context];
    let fileDescriptors = [];
    try {
      fileDescriptors = window.Config.filesToUpload[context['x-id']].map((file, fileIndex) => {
        return (
          <FileDescriptorComponent
            {...this.state}
            key={fileIndex}
            file={file}
            name={context['x-id']}
            index={fileIndex}
          />
        );
      });
    } catch (e) {
      console.error(e);
      this.props.refreshCache();
      throw new Error(e);
    }

    return fileDescriptors;
  };

  componentCanAddFile = () => {
    const context = this.state[this.state.context];
    const hasWriteAccess =
      context['x-rights'].findIndex((r) => r.role === window.Config.role && r.right === 'write') !== -1;
    const hideField = this.props.isFieldShouldBeHidden(this.state);
    let canAddFile = false;
    if (hasWriteAccess && !hideField) {
      const isRepeatable = context['repeatable'] === true;
      if (isRepeatable) {
        canAddFile = true;
      } else {
        const hasRegisteredFile = !!window.Config.filesToUpload[context['x-id']];
        const nbFileRegistered = hasRegisteredFile && window.Config.filesToUpload[context['x-id']].length;
        canAddFile = !(nbFileRegistered > 0);
      }
    }
    return canAddFile;
  };

  getLabel = () => {
    const context = this.state[this.state.context];
    let title = this.state.Literals[`${context['x-id']}-title`];
    if (!title) {
      title = this.state.Literals[`${context['x-parentName']}-${context['x-name']}`];
    }
    return title;
  };

  render() {
    const context = this.state[this.state.context];
    const fileIsEdited = isItAnUpdatedField(this.state.context);
    const cantDisplayFileComponent = this.componentCanAddFile() === false || (this.state.reviewMode && !fileIsEdited);
    const title = this.getLabel() || context.title;

    const hideField =
      this.state.reviewAction !== 'edit'
        ? this.props.isFieldShouldBeHiddenFromAction(this.state)
        : this.props.isFieldShouldBeHidden(this.state);

    const disabled = this.props.isComponentDisabled(this.state);

    return hideField ? null : (
      <section className={`App-element Media ${disabled}`}>
        {this.state.reviewMode && !fileIsEdited ? null : (
          <label htmlFor={`gh-download-${context['x-id']}`}>{title}</label>
        )}
        {this.state.files ? this.displayFiles() : null}
        {this.state.readMode || cantDisplayFileComponent ? null : <AddFileComponent {...this.state} field={context} />}
      </section>
    );
  }

  async loadImage(mediaItem, name, fileName) {
    //console.log('Load image', mediaItem, name, fileName);
    const url = `media/${mediaItem.locator}`;
    try {
      const confRequest = await Requests.fetch(url, {
        signal: window.Config.signal,
      });
      if (!confRequest.ok || confRequest.status >= 400) {
        const title = `${this.props.Literals.errors.requestingMedia} => ${mediaItem.locator}`;
        new window.Config.Error({
          status: confRequest.status,
          title,
        });
        throw title;
      }
      const response = await confRequest.json();

      return await this.props.addExistingFileToCue(response, name, fileName, mediaItem, response.exifData);
    } catch (e) {
      console.error(e);
      return {};
    }
  }
}

export default MediaComponent;
