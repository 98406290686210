import Requests from './Utils/Requests';

/**
 * Get a socotra json configuration and make a proper one for our system
 * @param {Object} configuration
 */
class XConfig {
  constructor() {
    this.steps = [];
    this.sectionIndex = -1;
  }
  async init(productName) {
    try {
      const confRequest = await Requests.fetch(`configurations/${productName}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        signal: window.Config.signal,
      });
      if (!confRequest.ok || confRequest.status >= 400) {
        const title = `${window.savedState.Literals.errors.requestingConfiguration} ${productName}`;
        new window.Config.Error({
          status: confRequest.status,
          title,
          message: `${window.savedState.Literals.errors.unableToLoadConfiguration} ${window.savedState.Literals.errors.pleaseTryAgain}`,
          event: 'reload',
        });
        throw title;
      }
      let configuration = await confRequest.json();
      if (typeof configuration === 'string') {
        configuration = JSON.parse(configuration);
      }
      if (configuration.fields) {
        configuration = configuration.fields;
      }
      //console.log(configuration.fields);
      const config = configuration.map((field) => {
        return this.xField(field);
      });
      //console.log('xConfig', config);
      return config;
    } catch (errorOnRequest) {
      console.error(errorOnRequest);
      return false;
    }
  }
  setDefaultRights(rights = []) {
    const fixedRights = [...rights];
    if (!rights.some((right) => right.role === 'Reader')) {
      fixedRights.push({ role: 'Reader', right: 'hidden' });
    }
    if (!rights.some((right) => right.role === 'Operator')) {
      fixedRights.push({ role: 'Operator', right: 'read' });
    }
    if (!rights.some((right) => right.role === 'Admin')) {
      fixedRights.push({ role: 'Admin', right: 'write' });
    }
    return fixedRights;
  }
  xField(field) {
    if (field.heading) {
      this.sectionIndex += 1;
    }
    const rights = field['x-rights'] || field.rights;
    let item = {
      ...field,
      'x-id': field.name,
      'x-label': this._formatLabel(field.title),
      'x-title': field.title,
      'x-name': field.name,
      'x-rights': this.setDefaultRights(rights),
      type: field.type,
      'x-sectionIndex': this.sectionIndex,
    };
    if (field.heading) {
      item['x-heading'] = field.heading;
    }
    switch (field.type) {
      case 'group':
        let fields = field['x-fields'] ? field['x-fields'] : field.fields;
        fields = fields.map((subField) => this.xField(subField));
        item = {
          ...item,
          'x-type': 'group',
          'x-fields': fields,
        };
        break;
      case 'string':
        item = {
          ...item,
          'x-type': 'text',
          type: 'text',
        };
        break;
      case 'email':
        item = {
          ...item,
          'x-type': 'email',
          pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
        };
        break;
      case 'number':
        item = {
          ...item,
          'x-type': 'number',
          step: 0.01,
        };
        break;
      case 'media':
        item = {
          ...item,
          'x-type': 'file',
        };
        break;
      case 'date':
        item = {
          ...item,
          'x-type': 'date',
          'x-pattern': /(?:19|20)[0-9]{2}-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1[0-9]|2[0-9])|(?:(?!02)(?:0[1-9]|1[0-2])-(?:30))|(?:(?:0[13578]|1[02])-31))/,
        };
        break;
      case 'select':
        item = {
          ...item,
          'x-type': 'select',
          'x-choices': field.values.map((element) => ({
            'x-id': this._formatLabel(element),
            'x-label': this._formatLabel(element),
            'x-classname': 'Form-circle',
            'x-value': element,
            'x-type': 'single',
            'x-onBlur': ['handleValidateField'],
          })),
        };
        break;
      case 'geolocation':
        item = {
          ...item,
          'x-type': 'geolocation',
        };
        break;
      default:
        item = {
          ...item,
          //['x-type']: item.type
        };
        break;
    }
    return item;
  }
  _formatLabel(string) {
    return string.toLowerCase().replace(/\s/g, '');
  }
}

export default XConfig;
