import React from 'react';
import { getValueFromField } from '../../../Utils/FieldsToUpdate';
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => <div className="MediaGoogleMapMarker">{text}</div>;

class GeoLocationComponent extends React.Component {
  constructor(props) {
    super();
    const context = props[props.context];
    this.state = {
      ...props,
      lat: null,
      lng: null,
      latitude: props.latitude || context.latitude,
      longitude: props.longitude || context.longitude,
    };
  }

  componentDidMount() {
    let [latitude, parentName] = this.state.latitude.split(';');
    let [longitude] = this.state.longitude.split(';');
    let nameAreString = true;
    if (parentName && parentName.length > 0) {
      const locator = window.Config.claim.fieldValues[parentName];
      latitude = `${locator}-${latitude}`;
      longitude = `${locator}-${longitude}`;
      nameAreString = false;
    }
    const nonParsedLatitude = nameAreString ? latitude : getValueFromField(latitude).value[0];
    const nonParsedLongitude = nameAreString ? longitude : getValueFromField(longitude).value[0];
    const lat = parseFloat(nonParsedLatitude);
    const lng = parseFloat(nonParsedLongitude);

    if (isNaN(lat) || isNaN(lng)) {
      const error = `GEOLOCATION Error while loading : longitude (${this.state.longitude} (${longitude}) : ${
        getValueFromField(this.state.longitude).value[0]
      }) or latitude (${this.state.latitude} (${latitude}) : ${
        getValueFromField(this.state.latitude).value[0]
      }) is not a Number.`;
      console.error(error);
      this.setState({ lat: null });
      //throw error;
    } else {
      window.Config.fieldToUpdate({
        id: this.state.longitude,
        visibility: false,
      });
      window.Config.fieldToUpdate({
        id: this.state.latitude,
        visibility: false,
      });
      this.setState({
        lat,
        lng,
      });
    }
  }

  createMapOptions = (maps) => {
    return {
      zoomControl: false,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
    };
  };

  render() {
    const context = this.props[this.props.context];
    const center = {
      lat: this.state.lat,
      lng: this.state.lng,
    };
    return this.state.lat ? (
      <React.Fragment>
        <label>{this.props.title}</label>
        <section className="MediaGoogleMap" style={{ width: 'auto', height: '200px' }}>
          <GoogleMapReact
            {...this.state}
            bootstrapURLKeys={{
              key: window.Config.googleMapAPIKey,
            }}
            options={this.createMapOptions()}
            defaultCenter={center}
            defaultZoom={16}
          >
            <AnyReactComponent lat={this.state.lat} lng={this.state.lng} text="" />
          </GoogleMapReact>
        </section>
      </React.Fragment>
    ) : null;
  }
}

export default GeoLocationComponent;
