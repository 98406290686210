import React from 'react';

class Field extends React.Component {
  constructor(props) {
    super();
    this.state = {
      ...props,
    };
  }

  componentDidUpdate() {
    if (this.props.currentLocale !== this.state.currentLocale) {
      this.setState({
        ...this.props,
        currentLocale: this.props.currentLocale,
      });
    }
  }

  onChange = (value, locale, name) => {
    this.save(value, locale, name);
  };
  onBlur = (value, locale, name) => {
    this.props.blurOnField();
    this.save(value, locale, name);
  };

  save = (value, locale, name) => {
    window.Config.i18n[locale][name] = value;
    //console.log('save', locale, name, value);
    this.setState({
      ...this.state,
      i18n: {
        ...this.state.i18n,
        [locale]: {
          ...this.state.i18n[locale],
          [name]: value,
        },
      },
    });
  };

  render() {
    const id = `${this.state.currentLocale}-${this.state.name}`;
    if (!window.Config.i18n[this.state.currentLocale][this.state.name]) {
      window.Config.i18n[this.state.currentLocale][this.state.name] = this.state.i18n[this.state.currentLocale][
        this.state.name
      ];
    }
    const defaultValue = this.state.i18n[this.state.currentLocale][this.state.name] || '';
    return (
      <section
        className={`Field${this.state.hasHeading ? ' Field-Heading' : ''}${
          this.state.subValue || this.state.subField ? ' Field-SubValue' : ''
        }`}
      >
        <table>
          <thead>
            <tr>
              <th>{this.state.hasHeading ? <h1>{this.state.hasHeading}</h1> : null}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <label htmlFor={id} className="smallText">
                  {this.state.name}{' '}
                  <span className="smallText">{this.state.subValue ? `(${this.state.subValue})` : ''}</span>
                  <span className="smallText">{this.state.subField ? `(${this.state.subField.title})` : ''}</span>
                </label>
                <input
                  locale={this.state.currentLocale}
                  name={this.state.name}
                  onChange={(e) => this.onChange(e.target.value, this.state.currentLocale, this.state.name)}
                  onBlur={(e) => this.onBlur(e.target.value, this.state.currentLocale, this.state.name)}
                  placeholder={this.state.placeholder}
                  id={`gh-${id}`}
                  value={defaultValue}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    );
  }
}

export default Field;
