import React from 'react';
import moment from 'moment';
moment.tz.setDefault(moment.tz.guess());

class SpanComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      fieldType: 'text',
      fieldValue: '',
    };
  }
  componentDidMount() {
    const context = this.state[this.state.context];
    let fieldValue = this.state.data[this.state.context]
      ? this.state.data[this.state.context]
      : null;
    if (context.type === 'date' && fieldValue) {
      fieldValue =
        context.precisiont === 'day' ? fieldValue : moment(fieldValue).format();
      fieldValue =
        fieldValue.length > 16 ? fieldValue.substr(0, 16) : fieldValue;
      this.setState({
        fieldValue,
        fieldType: context.precision === 'day' ? 'date' : 'datetime-local',
      });
    }
    if (fieldValue && context.type !== 'date') {
      this.setState({
        fieldValue,
      });
    }
  }
  render() {
    const context = this.state[this.state.context];
    return (
      <section className='App-element'>
        <label className='App-element-span'>{context['x-title']} : </label>{' '}
        {context.multiline ? (
          <textarea
            id={`gh-${context['x-id']}`}
            disabled='disabled'
            cols={context.cols}
            rows={context.rows}
            value={this.state.fieldValue}
          />
        ) : (
          <input
            type={this.state.fieldType}
            id={`gh-${context['x-id']}`}
            disabled='disabled'
            value={this.state.fieldValue}
          />
        )}
        <input
          type='hidden'
          value={this.state.fieldValue}
          id={context['x-id']}
        />
      </section>
    );
  }
}

export default SpanComponent;
