import React from 'react';
import moment from 'moment';
import {
  SelectComponent,
  TextareaComponent,
  TextComponent,
  DateComponent,
  RadioComponent,
  CheckboxComponent,
  GeoLocationComponent,
} from './FormComponents';
import { getValueFromField, getClaimActionForField, getPropertyFromFieldsToUpdate } from '../../Utils/FieldsToUpdate';

class FormComponent extends React.Component {
  constructor(props) {
    super();
    this.state = {
      ...props,
      edit: false,
      errorMessage: '',
      componentErrors: [],
      hideFieldFromAction: false,
      reviewAction: '',
    };
    this.checkForComponentsMandatoryErrors = this.checkForComponentsMandatoryErrors.bind(this);
    this.updateAndCheckValue = this.updateAndCheckValue.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (JSON.stringify(nextProps) !== JSON.stringify(prevState)) {
      return nextProps;
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    const { edit } = this.props.getDefaultValueFromState(this.state);
    if (this.state.edit !== edit && !this.state.readMode && !this.state.reviewMode) {
      this.setState({ edit });
    }
    const updatedProps = this.props.componentDidUpdate(prevProps, prevState);
    if (updatedProps) {
      this.setState({
        ...updatedProps,
      });
      if (updatedProps.forceFieldToUpdate.includes(this.state.context)) {
        this.setState({ edit: true });
      }
    }
  }

  updateAndCheckValue = (value, element) => {
    const context = this.state[this.state.context];
    const name = context.parentId ? this.props.getMyParentName(context.parentId) : this.state.context;
    const { mandatory } = getClaimActionForField(name);
    const { maximum, minimum, decimalPlaces } = context;

    let inError = false;
    switch (context['x-type']) {
      case 'select':
        if (value === 'undefined') {
          value = [];
        }
        break;
      case 'email':
        inError =
          value.match(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/) === null && value.length > 0
            ? { type: 'format' }
            : false;
        break;
      case 'text':
      case 'textarea':
        if (maximum && value.length > maximum) {
          inError = { type: 'stringTooLong' };
        }
        if (minimum && value.length < minimum) {
          inError = { type: 'stringTooSmall' };
        }
        break;
      case 'date':
        if (value instanceof moment) {
          value = context.precision === 'day' ? moment(value).format('YYYY-MM-DD') : moment(value).format();
        } else {
          if (value.length > 0) {
            // in case of the value is anything else than an empty string
            inError = { type: 'format' };
          }
          value = [];
        }
        break;
      case 'number':
        value = value.replace(/[^0-9-.]/g, '');
        let [int, dec] = value.split('.');
        if (decimalPlaces && dec) {
          dec = dec.substr(0, decimalPlaces);
          value = `${int}.${dec}`;
        }
        value = decimalPlaces ? parseFloat(value) : parseInt(value);
        if (isNaN(value)) {
          value = [''];
        }
        if (maximum && value > maximum) {
          inError = { type: 'numberTooBig' };
        }
        if (minimum && value < minimum) {
          inError = { type: 'numberTooSmall' };
        }
        break;
      default:
        const error = `No type for this item ! ${context.name} ${context['x-type']}`;
        throw error;
    }

    if (value.length === 0) {
      value = [];
      if (mandatory && mandatory.includes(this.state.reviewAction)) {
        inError = { type: 'mandatory' };
      }
    }

    const options = {
      id: context['x-id'],
      value,
      inError,
    };
    if (!window.Config.fieldsToUpdate[context['x-id']]) {
      if (context.parentId) {
        options.parentId = context.parentId;
        options.originalName = context.originalName;
        options.subType = context.type;
      }
      options.initialized = true;
    }
    const edit = window.Config.fieldToUpdate(options);
    this.setState({ edit });

    if (edit) {
      this.props.elementUpdated(element, this.props);
    }

    this.checkForComponentsMandatoryErrors();
  };

  checkForComponentsMandatoryErrors = () => {
    let componentErrors = [];
    const context = this.state[this.state.context];
    let inError = getPropertyFromFieldsToUpdate(context['x-id'], 'inError') || false;
    const { value } = getValueFromField(this.state.context);
    const { mandatory } = getClaimActionForField(this.state.context);
    if (!inError && this.state.reviewAction !== 'edit' && mandatory.indexOf(this.state.reviewAction) !== -1) {
      componentErrors = value.length === 0 ? [{ type: 'mandatory' }] : [];
    }
    if (inError) {
      componentErrors.push(inError);
    }
    this.setState({ componentErrors });
  };

  isComponentDisabled = () => {
    const context = this.state[this.state.context];
    let { informative, defaultValueFromAction } = this.props.getDefaultValueFromState(this.state);
    if (this.state.reviewAction !== 'edit') {
      if (defaultValueFromAction) return true;
      if (informative.includes(this.state.reviewAction)) {
        return true;
      }
    } else {
      return this.state.reviewMode ? 'disabled' : context['x-disabled'] || '';
    }
  };

  getLabel = () => {
    const context = this.state[this.state.context];
    let title = this.state.Literals[`${context['x-id']}-title`];
    if (!title) {
      title = this.state.Literals[`${context['x-parentName']}-${context['x-name']}`];
    }
    return title;
  };

  getHTMLComponent = (context) => {
    const element = {
      date: DateComponent,
      select: context.repeatable ? CheckboxComponent : SelectComponent,
      single: RadioComponent,
      email: TextComponent,
      tel: TextComponent,
      text: TextComponent,
      string: TextComponent,
      number: TextComponent,
      textarea: TextareaComponent,
      geolocation: GeoLocationComponent,
    };
    return element[context['x-type']];
  };

  render() {
    const context = this.state[this.state.context];
    let errorClassName = '';
    if (this.state.componentErrors.length > 0) {
      errorClassName = 'mandatory';
    }

    const { value, informative, mandatory, optional } = this.props.getDefaultValueFromState(this.state);

    const hideField =
      this.state.reviewAction !== 'edit'
        ? this.props.isFieldShouldBeHiddenFromAction(this.state)
        : this.props.isFieldShouldBeHidden(this.state);

    const disabled = this.props.isComponentDisabled(this.state);
    const title = this.getLabel() || context.title;

    const HTMLComponent = this.getHTMLComponent(context);

    return hideField ? null : (
      <section
        className={`App-element input_${context['x-type']} ${this.state.edit ? 'edit' : ''}
        ${mandatory.includes(this.state.reviewAction) ? ' mandatory' : ''}
        ${informative.includes(this.state.reviewAction) ? ' informative' : ''}
        ${optional.includes(this.state.reviewAction) ? ' optional' : ''}
        ${this.state.componentErrors.length > 0 ? ' inError' : ''}
         ${disabled}`}
      >
        <HTMLComponent
          updateAndCheckValue={this.updateAndCheckValue}
          checkForComponentsMandatoryErrors={this.checkForComponentsMandatoryErrors}
          {...this.props}
          errorClassName={errorClassName}
          disabled={disabled}
          title={title}
          value={value}
        />
        {context['x-hint'] ? (
          <span className="exampleMessage">
            {context['x-hint'] ? context['x-hint'].replace(new RegExp('%size%', 'g'), context.size) : null}
          </span>
        ) : (
          ''
        )}
        {context['x-warning'] ? <span className="warningMessage">{context['x-warning']}</span> : ''}
        {this.state.componentErrors.map((error, index) => {
          return (
            <span key={index} className="errorMessage" size={context.size && context.size()}>
              {this.props.Literals.errors[error.type]
                .replace(/%maximum%/g, context.maximum)
                .replace(/%minimum%/g, context.minimum)}
            </span>
          );
        })}
      </section>
    );
  }
}

export default FormComponent;
