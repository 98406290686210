import React from 'react';

class Step extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props
    };
  }

  render() {
    return <section id={`section_${this.props.id}`}></section>;
  }
}

export default Step;
