import ClaimHandling from './ClaimHandling.js';
import ClaimSelection from './ClaimSelection.js';
import PolicySelection from './PolicySelection.js';
import Login from './Login.js';
import Admin from './Admin.js';
import Edit from './Edit.js';
import i18n from './i18n.js';
import Step from './Step.js';
import ErrorComponent from './Error';
import Error404Component from './Error404';
import Loading from './Loading.js';

const Steps = {
  LOGIN: {
    component: Login,
  },
  POLICY_SELECTION: {
    component: PolicySelection,
  },
  CLAIM_SELECTION: {
    component: ClaimSelection,
  },
  CLAIM_HANDLING: {
    component: ClaimHandling,
  },
  ADMIN: {
    component: Admin,
  },
  EDIT: {
    component: Edit,
  },
  I18N: {
    component: i18n,
  },
  STEP: {
    component: Step,
  },
  ERROR404: {
    component: Error404Component,
  },
  ERROR: {
    component: ErrorComponent,
  },
  LOADING: {
    component: Loading,
  }
};

export default Steps;
