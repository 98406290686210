import React, { Component } from "react";
import { connect, batch } from "react-redux";
import Cookie from "../../Utils/Cookie";
import SessionClock from "./SessionClock";

import "./Menu.scss";

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = { ...props, claimId: null, policy: null };
  }

  componentDidMount() {
    this.setState({
      claimId: null,
      policy: null
    });
    document.addEventListener(
      "claimLoaded",
      () => {
        this.setState({ claimId: window.Config.claim.locator });
      },
      false
    );
    document.addEventListener(
      "policyLoaded",
      () => {
        this.setState({ policy: window.Config.policy });
      },
      false
    );
  }

  componentDidUpdate() {
    const policy = window.sessionStorage.getItem("policyid");
    if (this.state.policy && !policy) {
      this.setState({ policy: null });
    }
    const claim = window.sessionStorage.getItem("claimid");
    if (this.state.claimId && !claim) {
      this.setState({ claimId: null });
    }
  }

  clearConfig = () => {
    if (window.history.pushState) {
      const newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
      window.history.pushState({ path: newurl }, "", newurl);
    }
    window.sessionStorage.clear("policyid");
    window.sessionStorage.clear("claimid");
    this.props.toggleToReadMode(true);
    window.Config.controller.abort();
    window.Config.clear();
  };

  logout = () => {
    const logoutURL = `/logout`;
    window.Config.controller.abort();
    window.Config.clear();
    window.sessionStorage.clear();
    window.localStorage.clear();
    Cookie.eraseAll();
    window.location.href = logoutURL;
  };

  render() {
    if (!this.props.Literals || !this.props.Literals.menu) return false;
    return (
      <nav className="Menu">
        <section className="section-col section-col-100 Menu-background hidden" onClick={this.props.openMenu} />
        <section className="section-col section-col-100 Menu-foreground">
          <section
            className={`section-col section-col-${
              (this.state.claimId && this.props.currentStep === "CLAIM_HANDLING" && window.Config.claim.productName) ||
              (this.state.policy && this.props.currentStep === "POLICY_SELECTION")
                ? "20"
                : "80"
            }`}
          >
            <figure className="Menu-logo">
              <img src="/Images/logo.svg" alt="AXA" className="Menu-logo-image" />
              <img src="/Images/axa_identifier_xl_insurance.png" alt="AXA XL Insurance" className="Menu-logo-XL" />
            </figure>
          </section>
          {this.state.claimId && this.props.currentStep === "CLAIM_HANDLING" && window.Config.claim.productName ? (
            <section className="section-col section-col-60 section-title">
              <h1 className="section-title noMargin center">
                {this.state.Literals.menu.claimTitle} {this.state.claimId} - {window.Config.claim.productName}
              </h1>
            </section>
          ) : null}
          {this.state.policy && this.props.currentStep === "POLICY_SELECTION" ? (
            <section className="section-col section-col-60 section-title">
              <h1 className="section-title noMargin center">
                {this.state.Literals.menu.policyTitle} {this.state.policy.locator} - {this.state.policy.productName}
              </h1>
            </section>
          ) : null}
          {window.Config.role ? (
            <section className="section-col section-col-20">
              <button className="Menu-Button" id="Menu-Button" onClick={this.props.openMenu}>
                <span className="Menu-Burger" id="Menu-Burger" />
              </button>
            </section>
          ) : null}
        </section>
        <ul className="Menu-List" id="Menu-List">
          {window.Config.role ? (
            <React.Fragment>
              <li className="section">
                <button
                  id="gh-menu-link-claim-selection"
                  onClick={e => {
                    this.clearConfig();
                    this.props.updateCurrentStep("CLAIM_SELECTION");
                  }}
                >
                  {this.props.Literals.menu.ClaimSelection}
                </button>
              </li>
              <li className="section">
                <button
                  id="gh-menu-link-policy-selection"
                  onClick={e => {
                    this.clearConfig();
                    this.props.updateCurrentStep("POLICY_SELECTION");
                  }}
                >
                  {this.props.Literals.menu.PolicySelection}
                </button>
              </li>
            </React.Fragment>
          ) : (
            ""
          )}
          {window.Config.role === "Admin" ? (
            <li className="section">
              <button
                id="gh-menu-link-admin"
                onClick={e => {
                  if (window.history.pushState) {
                    var newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
                    window.history.pushState({ path: newurl }, "", newurl);
                  }
                  this.props.updateCurrentStep("ADMIN");
                }}
              >
                {this.props.Literals.menu.Admin}
              </button>
            </li>
          ) : (
            ""
          )}
          {window.Config.role ? (
            <li className="section">
              <button id="gh-menu-link-logout" onClick={this.logout}>
                {this.props.Literals.menu.Logout}
              </button>
            </li>
          ) : (
            ""
          )}
        </ul>
        {this.props.Literals.formSteps[this.props.currentStep] ? (
          <h2 className="section-col section-col-100 section">
            {this.props.Literals.formSteps[this.props.currentStep]}
          </h2>
        ) : (
          ""
        )}
        <SessionClock {...this.props}/>
      </nav>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state.Menu,
    currentStep: typeof state.currentStep !== "undefined" ? state.currentStep : "DISCLAIMER",
    Literals: state.Literals
  };
};

const mapDispatchToProps = dispatch => ({
  refreshCache: () => {
    window.Config.clear();
    window.savedState = null;
    window.sessionStorage.clear();
    window.localStorage.clear();
    //alert('reload 5')
    window.location.reload();
  },
  updateCurrentStep: currentStep => {
    dispatch({ type: "gotoStep", currentStep, way: "previous" });
    const menu = document.getElementById("Menu-List");
    menu && menu.classList.toggle("visible");
    const button = document.getElementById("Menu-Burger");
    button && button.classList.toggle("open");
    const body = document.querySelector(".ClaimHandling");
    body && body.classList.remove("blurry");
    const navigation = document.querySelector(".Navigation");
    navigation && navigation.classList.remove("blurry");
    const background = document.querySelector(".Menu-background");
    background && background.classList.toggle("hidden");
  },
  openMenu: event => {
    const menu = document.getElementById("Menu-List");
    menu && menu.classList.toggle("visible");
    const button = document.getElementById("Menu-Burger");
    button && button.classList.toggle("open");
    const body = document.querySelector(".ClaimHandling");
    body && body.classList.toggle("blurry");
    const navigation = document.querySelector(".Navigation");
    navigation && navigation.classList.toggle("blurry");
    const background = document.querySelector(".Menu-background");
    background && background.classList.toggle("hidden");
  },
  // If true, readMode = true
  toggleToReadMode: flag => {
    dispatch({
      type: "toggleToReadMode",
      flag
    });

    if (flag === true) {
      batch(() => {
        dispatch({
          type: "reviewAction",
          id: "edit",
          currentAction: undefined
        });
        dispatch({
          type: "toggleToReviewMode",
          flag: false
        });
        dispatch({
          type: "changeHasBeenMade",
          flag: new Date().getTime()
        });
      });
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
